import axios from "axios";
import { toast } from "react-toastify";
import { header } from "../../../utils/authheader";
import { getBaseUrl } from "../../../utils/configService";
export const FETCH_DATA_REQUEST = "FETCH_DATA_REQUEST";
export const FETCH_DATA_SUCCESS = "FETCH_DATA_SUCCESS";
export const FETCH_DATA_FAILURE = "FETCH_DATA_FAILURE";
export const SET_ROOT_ID = "SET_ROOT_ID";
export const SET_SELECTED_NODE = "SET_SELECTED_NODE";
export const SET_EXPANDED_NODE = "SET_EXPANDED_NODE";
export const EXPAND_PARENT = "EXPAND_PARENT";
export const ADD_WORKSPACE = "ADD_WORKSPACE";
export const UPDATE_WORKSPACE = "UPDATE_WORKSPACE";
export const DELETE_WORKSPACE = "DELETE_WORKSPACE";
export const SELECTED_SUITE = "SELECTED_SUITE";
export const SITE_DATA = "SITE_DATA";
export const JOURNEY_DETAILS = "JOURNEY_DETAILS";
export const JOURNEY_ID = "JOURNEY_ID";
export const GET_ENVIRONMENT_LIST = "GET_ENVIRONMENT_LIST";
export const GET_ENVIRONMENT_DETAILS = "GET_ENVIRONMENT_DETAILS";
export const FETCH_JOURNEY_SUCCESS = "FETCH_JOURNEY_SUCCESS";
export const FETCH_JOURNEY_ERROR = "FETCH_JOURNEY_ERROR";
export const CLEAR_JOURNEY_LIST = "CLEAR_JOURNEY_LIST";
export const GET_RUNS_LIST = "GET_RUNS_LIST";
export const GET_RUNS_DETAILS = "GET_RUNS_DETAILS";
export const EXECUTE_RUN = "EXECUTE_RUN";
export const GET_ALL_RESULTS = "GET_ALL_RESULTS";
export const GET_RUN_TEST_CASE_DETAILS = "GET_RUN_TEST_CASE_DETAILS";
export const GET_RUN_TEST_CASE_STEPS_DETAILS =
  "GET_RUN_TEST_CASE_STEPS_DETAILS";
export const FETCH_SCHEDULE_DETAILS_REQUEST = "FETCH_SCHEDULE_DETAILS_REQUEST";
export const FETCH_SCHEDULE_DETAILS_SUCCESS = "FETCH_SCHEDULE_DETAILS_SUCCESS";
export const FETCH_SCHEDULE_DETAILS_FAILURE = "FETCH_SCHEDULE_DETAILS_FAILURE";

export const GetEnvironmentAdAu = () => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const response = await axios.get(
        `${BASE_URL}/AdvanceAutomation/GetAllAdvanceAutomationEnvironments`,
        header()
      );
      dispatch({
        type: GET_ENVIRONMENT_LIST,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
     // toast.error("NETWORK ERROR");
    }
  };
};

export const GetEnvironmentDetailsAdAu = (id) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const response = await axios.get(
        `${BASE_URL}/AdvanceAutomation/GetAdvanceAutomationEnvironmentById?id=${id}`,
        header()
      );
      dispatch({
        type: GET_ENVIRONMENT_DETAILS,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
     // toast.error("NETWORK ERROR");
    }
  };
};

export const fetchDataRequest = () => ({
  type: FETCH_DATA_REQUEST,
});

export const fetchDataSuccess = (data) => ({
  type: FETCH_DATA_SUCCESS,
  payload: data,
});

export const fetchDataFailure = (error) => ({
  type: FETCH_DATA_FAILURE,
  payload: error,
});

export const fetchSiteDataSuccess = (data) => ({
  type: SITE_DATA,
  payload: data,
});

export const fetchWorkSpaces = () => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      const BASE_URL = await getBaseUrl();
      const response = await axios.get(
        `${BASE_URL}/AdvanceAutomation/GetAdvanceAutomationRelation`,
        header()
      );
      dispatch(fetchDataSuccess(response.data == "" ? [] : response.data));
    } catch (error) {
      dispatch(fetchDataFailure(error));
    }
  };
};

export const fetchSiteById = (id) => {
  console.log("fetchSiteById", id);
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const response = await axios.get(
        `${BASE_URL}/AdvanceAutomation/GetAutomationSiteById?id=${id}`,
        header()
      );
      dispatch(fetchSiteDataSuccess(response.data == "" ? [] : response.data));
    } catch (error) {
      dispatch(fetchDataFailure(error));
    }
  };
};

export const AddWorkspace = (workspace) => {
  return {
    type: ADD_WORKSPACE,
    payload: workspace,
  };
};

export const AddSite = (payload, onClose, setUpgradeModalVisiblity) => {
  return async (dispatch, getState) => {
    try {
      const BASE_URL = await getBaseUrl();
      const res = await axios.post(
        `${BASE_URL}/AdvanceAutomation/AddUpdateAdvanceAutomationSite`,
        payload,
        header()
      );
      onClose();
      await dispatch(fetchWorkSpaces());
      const state = getState();
      const workspaces = state.journey.listData;
      const nodeId = workspaces.find(
        (workspace) =>
          workspace.parentId === payload.rootId &&
          workspace.name === payload.name
      )?.id;

      if (res.data.status == "fail") {
        if (
          res.data.message ===
          "You’ve reached the maximum number of projects allowed on your current plan! Please upgrade to access more project slots."
        ) {
          setUpgradeModalVisiblity(true);
        } else {
          toast.warn(res.data.message);
        }
      } else {
        dispatch(ExpandParent(payload.rootId));
      }
      return nodeId;
    } catch (error) {
      console.log("error saving ", error);
    }
  };
};

export const UpdateWorkspace = (workspace) => {
  return {
    type: UPDATE_WORKSPACE,
    payload: workspace,
  };
};
export const DeleteWorkspace = (id) => {
  return {
    type: DELETE_WORKSPACE,
    payload: id,
  };
};
export const setRootId = (suitId) => {
  console.log("suit id ", suitId);
  return {
    type: SET_ROOT_ID,
    payload: suitId,
  };
};
export const setSelectedNode = () => {
  return {
    type: SET_SELECTED_NODE,
  };
};
export const setExpandedNodes = (id) => {
  return {
    type: SET_EXPANDED_NODE,
    payload: id,
  };
};

export const ExpandParent = (id) => {
  return {
    type: EXPAND_PARENT,
    payload: id,
  };
};

export const JourneyDetails = (data) => {
  return {
    type: JOURNEY_DETAILS,
    payload: data,
  };
};

export const clearJourneyList = () => {
  return {
    type: CLEAR_JOURNEY_LIST,
    payload: [],
  };
};

export const JourneyData = (data) => {
  return {
    type: FETCH_JOURNEY_SUCCESS,
    payload: data,
  };
};

export const startJourney = (postData) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const response = await axios.post(
        `${BASE_URL}/AdvanceAutomation/CrawlWebsite`,
        postData,
        header()
      );
      dispatch(fetchJourneysByRootId(postData.rootId));
      return response.data.message;
    } catch (error) {
      console.error("Error starting journey:", error);
    }
  };
};

export const fetchJourneysByRootId = (rootId) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const response = await axios.get(
        `${BASE_URL}/AdvanceAutomation/GetAutomationJourneysByRootId?rootId=${rootId}`,
        header()
      );
      if (Array.isArray(response.data)) {
        const filteredData = response.data.map((item) => {
          const formattedDate = new Date(item.createdOn).toLocaleString();
          if (item.status != "Completed") {
            setTimeout(() => fetchJourneysByRootId(rootId)(dispatch), 5000);
          }

          return {
            id: item.id,
            ApplicationName: item.name,
            status: item.status,
            createdOn: formattedDate,
            pages: item.pages,
            action: item.actions,
            testCases: item.workflows,
          };
        });
        // Dispatch action to store the data
        dispatch({
          type: FETCH_JOURNEY_SUCCESS,
          payload: filteredData,
        });
      } else {
        console.error("Expected an array but got:", response.data);
      }
    } catch (error) {
      console.error("Error fetching journey data:", error);
    }
  };
};

export const GetJourneyById = (id) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const response = await axios.get(
        `${BASE_URL}/AdvanceAutomation/GetAutomationJourneyById?id=${id}`,
        header()
      );
      dispatch({
        type: JOURNEY_DETAILS,
        payload: response.data,
      });
      dispatch({
        type: JOURNEY_ID,
        payload: id,
      });
    } catch (error) {
      console.error(error);
     // toast.error("NETWORK ERROR");
    }
  };
};

export const UpdateJourneyName = (payload, rootId) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const response = await axios.post(
        `${BASE_URL}/AdvanceAutomation/AddUpdateAutomationJourney`,
        payload,
        header()
      );
      if (response.status === 200) {
        toast.info("Journey Name Updated", {
          style: {
            background: "rgb(101, 77, 247)",
            color: "rgb(255, 255, 255)",
          },
        });
      }
      dispatch(fetchJourneysByRootId(rootId));
    } catch (error) {
      console.error("Error saving baseline:", error);
    }
  };
};

export const saveBaselineJourney = (payload, id) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const response = await axios.post(
        `${BASE_URL}/AdvanceAutomation/AddUpdateAutomationJourney`,
        payload,
        header()
      );
      if (response.status === 200) {
        toast.info("Saved as BaseLine Journey", {
          style: {
            background: "rgb(101, 77, 247)",
            color: "rgb(255, 255, 255)",
          },
        });
      }
      dispatch(GetJourneyById(id));
    } catch (error) {
      console.error("Error saving baseline:", error);
    }
  };
};

export const DeleteJourney = (id, rootId) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const res = await axios.post(
        `${BASE_URL}/AdvanceAutomation/DeleteAutomationJourneyById`,
        { id: id },
        header()
      );
      if (res.status === 200) {
        toast.info("Successfully deleted", {
          style: {
            background: "rgb(101, 77, 247)",
            color: "rgb(255, 255, 255)",
          },
        });
      }
      dispatch(fetchJourneysByRootId(rootId));
    } catch (error) {
      console.log("error deleting ", error);
      toast("deleting error");
    }
  };
};

export const DeleteJourneyTestCases = (ids, id) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const res = await axios.post(
        `${BASE_URL}/AdvanceAutomation/DeleteAutomationJourneyTestCasesById`,
        { ids: ids },
        header()
      );
      if (res.status === 200) {
        toast.info("Successfully deleted", {
          style: {
            background: "rgb(101, 77, 247)",
            color: "rgb(255, 255, 255)",
          },
        });
      }
      dispatch(GetJourneyById(id));
    } catch (error) {
      console.log("error deleting ", error);
      toast("deleting error");
    }
  };
};

export const GetRunsByJourneyId = (id) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const response = await axios.get(
        `${BASE_URL}/AdvanceAutomation/GetAllAutomationRunsByJourneyId?JourneyId=${id}`,
        header()
      );
      dispatch({
        type: GET_RUNS_LIST,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
     // toast.error("NETWORK ERROR");
    }
  };
};

export const GetDetailsByRunId = (id) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const response = await axios.get(
        `${BASE_URL}/AdvanceAutomation/GetAutomationRunById?id=${id}`,
        header()
      );
      console.log("responsedata:", response.data);
      dispatch({
        type: GET_RUNS_DETAILS,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
     // toast.error("NETWORK ERROR");
    }
  };
};

export const AddUpdateRun = (data, id, execute, userId) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const res = await axios.post(
        `${BASE_URL}/AdvanceAutomation/AddUpdateAutomationRun`,
        data,
        header()
      );
      if (res.data.status === "success") {
        toast.info(res.data.message, {
          style: {
            background: "rgb(101, 77, 247)",
            color: "rgb(255, 255, 255)",
          },
        });
        if (execute === true) {
          dispatch(ExecuteRun(res.data.Data.id, id, userId));
          dispatch(GetRunsByJourneyId(id));
        } else {
          dispatch(GetRunsByJourneyId(id));
        }
      } else if (res.data.status === "fail") {
       // toast.error(res.data.message);
      }
    } catch (error) {
      console.log("error adding ", error);
      toast("Posting error");
    }
  };
};

export const DeleteRun = (id, journeyId) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const res = await axios.post(
        `${BASE_URL}/AdvanceAutomation/DeleteAutomationRun`,
        { id: id },
        header()
      );
      if (res.status === 200) {
        toast.info("Successfully deleted", {
          style: {
            background: "rgb(101, 77, 247)",
            color: "rgb(255, 255, 255)",
          },
        });
      }
      dispatch(GetRunsByJourneyId(journeyId));
    } catch (error) {
      console.log("error deleting ", error);
      toast("deleting error");
    }
  };
};

export const ExecuteRun = (runId, journeyId, userId) => {
  return async (dispatch) => {
    let payload = {
      id: runId,
      journeyId: journeyId,
      userId: userId,
    };
    try {
      const BASE_URL = await getBaseUrl();
      const response = await axios.post(
        `${BASE_URL}/AdvanceAutomation/ExecuteAutomationRun`,
        payload,
        header()
      );
      if (response.status === 200) {
        toast.info("Successfully Executed", {
          style: {
            background: "rgb(101, 77, 247)",
            color: "rgb(255, 255, 255)",
          },
        });
      }
      dispatch({
        type: EXECUTE_RUN,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
     // toast.error("NETWORK ERROR");
    }
  };
};

export const DeleteJourneyTestCasesInRun = (ids) => {
  return async () => {
    try {
      const BASE_URL = await getBaseUrl();
      const res = await axios.post(
        `${BASE_URL}/AdvanceAutomation/DeleteAutomationJourneyTestCasesById`,
        { ids: ids },
        header()
      );
      if (res.status === 200) {
        toast.info("Successfully deleted", {
          style: {
            background: "rgb(101, 77, 247)",
            color: "rgb(255, 255, 255)",
          },
        });
      }
    } catch (error) {
      console.log("error deleting ", error);
      toast("deleting error");
    }
  };
};

export const GetAllResults = (journey_id) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const response = await axios.get(
        `${BASE_URL}/AdvanceAutomation/GetAutomationExecutionRunDetails?journeyId=${journey_id}&ModuleType=GhostAI`,
        header()
      );
      dispatch({
        type: GET_ALL_RESULTS,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
     // toast.error("NETWORK ERROR");
    }
  };
};

export const GetRunTestCaseDetails = (clientReferenceId, browserName) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const response = await axios.get(
        `${BASE_URL}/AdvanceAutomation/GetAutomationRunTestCaseDetails?clientReferenceId=${clientReferenceId}&browserName=${browserName}&moduleType=GhostAI`,
        header()
      );
      dispatch({
        type: GET_RUN_TEST_CASE_DETAILS,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
     // toast.error("NETWORK ERROR");
    }
  };
};

export const GetRunTestCaseStepsDetails = (JourneyId, testCasename) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const response = await axios.get(
        `${BASE_URL}/AdvanceAutomation/GetAutomationRunTestCaseStepsDetails?journeyId=${JourneyId}&testcaseName=${testCasename}`,
        header()
      );
      dispatch({
        type: GET_RUN_TEST_CASE_STEPS_DETAILS,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
     // toast.error("NETWORK ERROR");
    }
  };
};

export const SchedulingTestRun = (scheduleData, onClose, clearFormFields) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const response = await axios.post(
        `${BASE_URL}/Scheduler/ScheduleAITestRun`,
        scheduleData,
        header()
      );
      if (response.status === 200) {
        toast.info(response.data.message, {
          style: {
            background: "rgb(101, 77, 247)",
            color: "rgb(255, 255, 255)",
          },
        });
        if (typeof onClose === "function") onClose();
        if (typeof clearFormFields === "function") clearFormFields();
      } else {
        console.log("Error saving suite schedule");
        if (typeof clearFormFields === "function") clearFormFields();
      }
    } catch (error) {
      console.error("Error saving suite schedule:", error);
      if (typeof clearFormFields === "function") clearFormFields();
    }
  };
};

export const fetchScheduleDetails =
  (rootId, suiteName, clearFormFields) => async (dispatch) => {
    dispatch({ type: FETCH_SCHEDULE_DETAILS_REQUEST });
    try {
      const BASE_URL = await getBaseUrl();
      const response = await axios.get(
        `${BASE_URL}/Scheduler/GetGhostAISchedulingById?rootId=${rootId}&suiteName=${suiteName}&schedulerType=GhostAI`,
        header()
      );
      if (response.data && response.data.length > 0) {
        dispatch({
          type: FETCH_SCHEDULE_DETAILS_SUCCESS,
          payload: response.data[0],
        });
      } else {
        dispatch({
          type: FETCH_SCHEDULE_DETAILS_SUCCESS,
          payload: [],
        });
        clearFormFields();
      }
    } catch (error) {
      dispatch({
        type: FETCH_SCHEDULE_DETAILS_FAILURE,
        payload: error.message,
      });
      clearFormFields();
    }
  };
