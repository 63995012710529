import { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  TableContainer,
  CircularProgress,
  Box,
  Tooltip,
} from "@mui/material";
import { StyledTypography, useStyles } from "./styles";
import { Screenshot } from "../../../../comman/icons";
import Donut from "./DonutChart";
import Stack from "@mui/material/Stack";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { StyledTableCell } from "./styles";
import ScreenshotList from "./ScreenshotList";
import CustomCardContent from "./modals/CardContend";
import {
  getAuditDetailsById,
  getPageViolationsByPageId,
} from "../../../../redux/actions/Accessibility/AuditAction";
import { useDispatch, useSelector } from "react-redux";

export default function AuditResultDetails() {
  const classess = useStyles();
  const { auditResult } = useSelector((state) => state.audit);
  const [selectedRun, setSelectedRun] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { clientReferenceId } = useParams();
  const { violationCounts, pageDetails, siteUrl } = auditResult;

  const handleRowClick = (run, index) => {
    const updatedRun = { ...run, pageLabel: `Page ${index + 1}` };
    setSelectedRun(updatedRun);
    if (run.id) {
      dispatch(getPageViolationsByPageId(run.id));
    }
  };

  useEffect(() => {
    if (clientReferenceId) {
      dispatch(getAuditDetailsById(clientReferenceId));
    }
  }, [clientReferenceId]);

  const calculateDonutHeight = () => {
    const parentContainer = document.getElementById("donut-container");
    const parentContainerHeight = parentContainer
      ? parentContainer.clientHeight
      : window.innerHeight;
    const desiredPercentage = 38;
    return `${(parentContainerHeight * desiredPercentage) / 100}px`;
  };

  const testData = [
    { name: "URL", value: siteUrl || "N/A" },
    { name: "No. of Pages", value: pageDetails?.length || 0 },
  ];

  return (
    <>
      {!loading ? (
        <Grid className={classess.mainContainer}>
          {/* Header */}
          <Grid
            xs={12}
            container
            sx={{ marginBottom: "15px" }}
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Grid item>
              <Stack direction="row" spacing={2}>
                <Button
                  sx={{
                    background: "rgb(101, 77, 247)",
                    padding: "10px 15px",
                    color: "white",
                    textTransform: "none",
                    fontSize: "14px !important",
                    "&:hover": {
                      background: "rgb(101, 77, 247)",
                    },
                  }}
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <ArrowBackIcon fontSize="small" />
                  Back
                </Button>
              </Stack>
            </Grid>
          </Grid>

          {/* Main Component */}
          <Grid container spacing={2}>
            {/* Left Side */}
            <Grid item xs={12} sm={7}>
              <Grid container spacing={2}>
                {/* Donut Chart */}
                <Grid item xs={12} sm={6}>
                  <Card
                    sx={{
                      background: "#f1f1f1",
                      height: "40vh",
                      padding: "10px 15px",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {violationCounts ? (
                      <Donut
                        series={[
                          violationCounts.critical || 0,
                          violationCounts.high || 0,
                          violationCounts.medium || 0,
                          violationCounts.low || 0,
                        ]}
                        labels={["Critical", "High", "Medium", "Low"]}
                        height={calculateDonutHeight()}
                      />
                    ) : (
                      <div>No data available</div>
                    )}
                  </Card>
                </Grid>

                {/* Violation Summary */}
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={2}>
                    {["Critical", "High", "Medium", "Low"].map(
                      (type, index) => (
                        <Grid item xs={12} sm={6} key={type}>
                          <Card
                            sx={{
                              width: "100%",
                              height: "19vh",
                            }}
                          >
                            <CustomCardContent
                              className={classess.hederStyle}
                              type={type}
                              data={violationCounts?.[type.toLowerCase()] || 0}
                            />
                          </Card>
                        </Grid>
                      )
                    )}
                  </Grid>
                </Grid>
              </Grid>

              {/* Page Details Table */}
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Card>
                    <TableContainer
                      sx={{ overflow: "auto", maxHeight: "calc(40vh - 40px)" }}
                    >
                      <Table>
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>Pages</StyledTableCell>
                            <StyledTableCell>URL</StyledTableCell>
                            <StyledTableCell>Start Time</StyledTableCell>
                            {/* <StyledTableCell>Screenshot</StyledTableCell> */}
                          </TableRow>
                        </TableHead>
                        <TableBody
                          sx={{ maxHeight: "100%", overflowY: "auto" }}
                        >
                          {pageDetails && pageDetails.length ? (
                            pageDetails.map((row, index) => (
                              <TableRow
                                key={index}
                                className={`${classess.tableRow} ${
                                  selectedRun.id === row.id
                                    ? classess.activeRow
                                    : ""
                                }`}
                                onClick={() => handleRowClick(row, index)}
                              >
                                <StyledTableCell
                                  sx={{
                                    color:
                                      selectedRun.id === row.id
                                        ? "white"
                                        : "black",
                                  }}
                                >{`Page ${index + 1}`}</StyledTableCell>
                                <StyledTableCell
                                  sx={{
                                    color:
                                      selectedRun.id === row.id
                                        ? "white"
                                        : "black",
                                  }}
                                >
                                  {row.url.length > 40 ? (
                                    <Tooltip title={row.url} arrow>
                                      <span>
                                        {row.url.substring(0, 40) + "..."}
                                      </span>
                                    </Tooltip>
                                  ) : (
                                    row.url
                                  )}
                                </StyledTableCell>

                                <StyledTableCell
                                  sx={{
                                    color:
                                      selectedRun.id === row.id
                                        ? "white"
                                        : "black",
                                  }}
                                >
                                  {new Date(row.timestamp).toLocaleString(
                                    "en-CA",
                                    {
                                      hour12: false,
                                      year: "numeric",
                                      month: "2-digit",
                                      day: "2-digit",
                                      hour: "2-digit",
                                      minute: "2-digit",
                                    }
                                  )}
                                </StyledTableCell>
                                {/* <StyledTableCell
                                  sx={{
                                    color:
                                      selectedRun.id === row.id
                                        ? "white"
                                        : "black",
                                  }}
                                >
                                  <Screenshot
                                    color={
                                      selectedRun.id === row.id
                                        ? "white"
                                        : "#654DF7"
                                    }
                                  />
                                </StyledTableCell> */}
                              </TableRow>
                            ))
                          ) : (
                            <TableRow>
                              <StyledTableCell colSpan={2} align="center">
                                No pages available
                              </StyledTableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Card>
                </Grid>
              </Grid>
            </Grid>

            {/* Right Side */}
            <Grid item xs={12} sm={5}>
              <Grid container spacing={2}>
                {/* Top part of the card */}
                <Grid item xs={12}>
                  <Card
                    sx={{
                      height: "40vh",
                    }}
                  >
                    <CardContent className={classess.headrRightSite}>
                      <Typography className={classess.theadFont}>
                        Info...
                      </Typography>
                    </CardContent>

                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ fontWeight: "400" }}>Name</TableCell>
                          <TableCell sx={{ fontWeight: "400" }}>
                            Value
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {testData.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell className={classess.tbodyFont}>
                              {row.name}
                            </TableCell>
                            <TableCell className={classess.tbodyFont}>
                              {row.value}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Card>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                {/* Left part of the card */}
                {selectedRun.url && (
                  <Grid item xs={12}>
                    <Card>
                      <TableContainer
                        sx={{
                          overflow: "auto",
                          maxHeight: "calc(40vh - 30px)",
                        }}
                      >
                        <Table>
                          <TableHead>
                            <TableRow>
                              <StyledTableCell colSpan={4}>
                                <StyledTypography variant="h6" color="primary">
                                  {`${selectedRun.pageLabel}`}
                                </StyledTypography>
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <ScreenshotList data={selectedRun} />
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Card>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={25} />
        </Box>
      )}
    </>
  );
}
