import React from 'react';
import Chart from 'react-apexcharts'; // Assuming you're using ApexCharts

const AverageHitsChart = ({ data, height, fontSize, isPreview }) => {
  // Prepare the series data for the chart
  const series = data.map(run => ({
    name: `Id: ${run.id}`,
    data: run.responseData.map(item => {
      // Defensive check for hits_Per_Second
      return (item.hits_Per_Second !== undefined && typeof item.hits_Per_Second === 'number') 
        ? parseFloat(item.hits_Per_Second).toFixed(2) // Convert to float and format
        : 0; // Fallback value if undefined
    }),
  }));

  const colors = data.map(run => run.colour);

  // Prepare the options for the chart
  const options = {
    chart: {
      type: 'line',
      height: height,
      toolbar: {
        show: true,
          tools: {
            download: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M216 0h80c13.3 0 24 10.7 24 24v168h87.7c17.8 0 26.7 21.5 14.1 34.1L269.7 378.3c-7.5 7.5-19.8 7.5-27.3 0L90.1 226.1c-12.6-12.6-3.7-34.1 14.1-34.1H192V24c0-13.3 10.7-24 24-24zm296 376v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h146.7l49 49c20.1 20.1 52.5 20.1 72.6 0l49-49H488c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"/></svg>',
          },
      },
      zoom: {
        enabled: false,
      },
    },
    title: {
      text: 'Average Hits',
      align: 'center',
      style: {
        fontFamily: 'Lexend Deca',
        fontSize: fontSize,
        fontWeight: 'bold',
      },
    },
    stroke: {
      curve: 'smooth',
      width: [2], // Adjust line width as needed
    },
    xaxis: {
      categories: data[0]?.responseData.map(item => new Date(item.timeStamp).toLocaleTimeString()) || [],
      title: {
        text: isPreview ? '' : 'Time',
        style: {
          fontFamily: 'Lexend Deca',
          fontSize: fontSize,
          fontWeight: 'bold',
        },
      },
      labels: {
        show: !isPreview,
      },
    },
    yaxis: {
      title: {
        text: isPreview ? '' : 'Hits',
        style: {
          fontFamily: 'Lexend Deca',
          fontSize: fontSize,
          fontWeight: 'bold',
        },
      },
      labels: {
        show: !isPreview,
      },
    },
    colors: colors,
    legend: {
      show: true,
    },
    tooltip: {
      enabled: !isPreview,
    },
  };

  return (
    <div className="line-container">
      <Chart options={options} series={series} type="line" height={height} />
    </div>
  );
};

export default AverageHitsChart;