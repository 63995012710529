import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import { Edit as EditIcon, Delete as DeleteIcon } from "@material-ui/icons";
import { BrowserChrome, BrowserEdge, BrowserFirefox, BrowserSafari } from "../../../../comman/icons";
import { useTableStyles, StyledTableCell } from "./styles"; 
import DeleteModal from "../Modal/DeleteModal";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ExecuteRun, GetRunsByJourneyId } from "../../../../redux/actions/AdvanceAutomation/JourneyAction";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import TestRunSchedular from "../scheduler/TestRunSchedular";

const icons = {
    chrome: <BrowserChrome size={20} />,
    edge: <BrowserEdge size={20} />,
    firefox: <BrowserFirefox size={20} />,
    safari: <BrowserSafari size={20} />,
  };

  const useStyles = () => ({
    iconContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '10px', 
      flexDirection: 'row',
    }
  });  

export function CustomTable({ rows, handleEditRun }) {
  const classes = useTableStyles();
  const iconClasses = useStyles();
  const [isExecuting, setIsExecuting] = useState({});
  const [runExecute, setRunExecute] = useState(false);
  const [openDelModal, setOpenDelModal] = useState(false);
  const [selectedRun, setSelectedRun] = useState(null);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [isSchedulerOpen, setIsSchedulerOpen] = useState(false);
  const { userId } = useSelector((state) => state.auth);
  const { journey_id, journeyDetails } = useSelector((state) => state.journey);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      dispatch(GetRunsByJourneyId(journey_id));
    };

    const anyInProgress = rows.some(row => row.IsInProgress);
    setRunExecute(anyInProgress);

    const interval = setInterval(() => {
      if (rows.some(row => row.IsInProgress)) {
        fetchData(); 
      } else {
        clearInterval(interval); 
      }
    }, 2000); 

    return () => clearInterval(interval); 
  }, [dispatch, journey_id, rows]); 

  const renderBrowserIcons = (browserString) => {
    const browsers = browserString.split(","); 
    return (
      <div className={iconClasses.iconContainer}>
        {browsers.map((browser) => (
          icons[browser.trim()] || null
        ))}
      </div>
    );
  };

  const handleDelete = (row) => {
    setOpenDelModal(true);
    setSelectedRun(row);
  };

  const handleEdit = (row) => {
    handleEditRun(row)
  };

  const handleOpenScheduler = (row) => {
    setSelectedRowData(row);
    setIsSchedulerOpen(true);
  };

  const handleCloseScheduler = () => {
    setIsSchedulerOpen(false);
  };

  const handleExecute = async (row) => {
   setIsExecuting((prev) => ({
    ...prev,
    [row.Id]: true,
  }));

  setRunExecute(true);

  setTimeout(() => {
    setIsExecuting((prev) => {
      const updatedExecuting = {
        ...prev,
        [row.Id]: false,
      };

      const allNotExecuting = Object.values(updatedExecuting).every(executing => !executing);
      
      if (allNotExecuting) {
        setRunExecute(false); 
      }

      return updatedExecuting;
    });
  }, await dispatch(ExecuteRun(row.Id, journey_id, userId)));

  dispatch(GetRunsByJourneyId(journey_id));
  };

  return (
    <>
      <TestRunSchedular isOpen={isSchedulerOpen} onClose={handleCloseScheduler}  rowData={selectedRowData}/>
      <DeleteModal
        open={openDelModal}
        onClose={() => setOpenDelModal(false)}
        run={selectedRun}
      />
      <TableContainer className={classes.main} style={{ paddingRight: '16px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Run Name</StyledTableCell>
              <StyledTableCell>Browser</StyledTableCell>
              <StyledTableCell>Build Version</StyledTableCell>
              <StyledTableCell>Release Version</StyledTableCell>
              <StyledTableCell>Workflow</StyledTableCell>
              <StyledTableCell>Passed</StyledTableCell>
              <StyledTableCell>Failed</StyledTableCell>
              <StyledTableCell>Last Executed On</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.Name} className={classes.tableRow} style={{ height: "10px",cursor:"default"}}>
                <StyledTableCell component="th" scope="row">{row.Name}</StyledTableCell>
                <StyledTableCell component="th" scope="row">{renderBrowserIcons(row.Browser)}</StyledTableCell>
                <StyledTableCell component="th" scope="row">{row.BuildVersion}</StyledTableCell>
                <StyledTableCell component="th" scope="row">{row.ReleaseVersion}</StyledTableCell>
                <StyledTableCell component="th" scope="row">{row.TestCases}</StyledTableCell>
                <StyledTableCell component="th" scope="row">{row.Passed}</StyledTableCell>
                <StyledTableCell component="th" scope="row">{row.Failed}</StyledTableCell>
                <StyledTableCell component="th" scope="row">{row.LastExecutedOn?row.LastExecutedOn:"NA"}</StyledTableCell>
                <StyledTableCell style={{cursor:"default"}}>
                  {journeyDetails?.isBaseline && (
                    !isExecuting[row.Id] && !row.IsInProgress ? (
                      <Tooltip title="Execute">
                      <PlayCircleIcon
                        style={{ 
                          color: runExecute ? "rgba(101, 77, 247, 0.5)" : "rgb(101, 77, 247)", 
                          cursor: runExecute ? "not-allowed" : "pointer", 
                          marginRight: "10px", 
                        }}
                        onClick={runExecute ? null : () => handleExecute(row)}
                      />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Executing">
                      <CircularProgress 
                        style={{ 
                          color: "rgb(101, 77, 247)", 
                          cursor: runExecute ? "not-allowed" : "pointer", 
                          marginRight: "10px", 
                          }} 
                        size={25} 
                      />
                      </Tooltip>
                    )
                  )}
                  <Tooltip title="Schedule" arrow>
                    <CalendarMonthIcon
                      sx={{
                        color: runExecute ? "rgba(101, 77, 247, 0.5)" : "rgb(101, 77, 247)"
                      }}
                      style={{ 
                        cursor: runExecute ? "not-allowed" : "pointer",
                        marginRight: "10px",
                       }}
                      onClick={runExecute ? null : () => handleOpenScheduler(row)}
                    />
                  </Tooltip>
                  <Tooltip title="Edit">
                    <EditIcon
                      onClick={runExecute ? null : () => handleEdit(row)}
                      style={{
                        cursor: runExecute ? "not-allowed" : "pointer",
                        marginRight: "10px",
                        color: runExecute ? "rgba(101, 77, 247, 0.5)" : "rgb(101, 77, 247)",
                      }}
                    />
                  </Tooltip>
                  <Tooltip title="Delete">
                    <DeleteIcon
                      onClick={runExecute ? null : () => handleDelete(row)}
                      style={{
                        cursor: runExecute ? "not-allowed" : "pointer",
                        color: runExecute ? "rgba(246, 78, 78, 0.5)" : "#F64E4E",
                      }}
                    />
                  </Tooltip>
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
