import React from 'react';

const Accessibility = () => {
  return (
    <div> 
      <h1>Accessibility Feature</h1>
      <p>This is the content for the Accessibility feature.</p>
    </div>
  );
};

export default Accessibility;