import React, { Suspense, lazy, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Functional from "./Stacks/Functional";
import Api from "./Stacks/Api";
import { Box } from "@material-ui/core";
import EditTestSuite from "../pages/TestSuite/EditTestSuite";
import CircularProgress from "@mui/material/CircularProgress";
import Invitation from "../pages/Invitaion";
import Account from "../pages/Account";
import CreateTestCase from "../pages/TestLab/CreateTestcase";
import CreateTestRun from "../pages/TestLab/CreateTestCaseRun";
import EditTestCase from "../pages/TestLab/CreateTestcase/editTestLab";
import Results from "./Stacks/Results";
import Summary from "../pages/Performance/Result/ResultDetails";
import Error from "../pages/Performance/Result/ResultDetails/Error";
import RequestState from "../pages/Performance/Result/ResultDetails/RequestState/RequestState";
import CompareGraph from "../pages/Performance/Component/Compare/CompareGraph";
import InitialSetup from "../pages/Performance/Result/ResultDetails/InitialSetup/index";
import TestCase from "../pages/TestCase";
import Location from "../pages/Settings/Component/Performance/Location";
import ViewAgent from "../pages/Settings/Component/Performance/Location/ViewAgent";
import Detail from "../pages/Settings/Component/UserAccount/Detail";
import Organization from "../pages/Settings/Component/UserAccount/Organization";
import Members from "../pages/Settings/Component/Organization/Members";
import TestLabSuitsDetails from "../pages/TestLab/Result/TestSuitsDetails/index";
import TestLabSuitsResults from "../pages/AdvanceAutomation/Journey/Result/TestSuitsResults";
import Integration from "../pages/Settings/Component/Functional-Local-Testing/Integration";
import EmailReport from "../pages/Settings/Component/Functional-Local-Testing/EmailReport";
import AdvanceAutomation from "../pages/AdvanceAutomation";
import EnvironmentVariable from "../pages/Settings/Component/Functional-Local-Testing/Environment Variable";
import EnvironmentVariable_value from "../pages/Settings/Component/Functional-Local-Testing/Environment Variable_value";
import Subscription from "../pages/Subscriptions";
import { getCustomerSubscriptionDetails } from "../redux/actions/authActions";
import { getTenantPlanFeatures } from "../redux/actions/authActions";
import { CustomTable_variable } from "../pages/Settings/Component/Functional-Local-Testing/Environment Variable/CustomTable_variable";
import EnvironmentVariable_TestLab from "../pages/Settings/Component/Functional-Test-Lab/Environment Variable";
import EnvironmentVariable_value_TestLab from "../pages/Settings/Component/Functional-Test-Lab/Environment Variable_value";
import AccessibilityPage from "../pages/Accessibility";
import AuditResultDetails from "../pages/Accessibility/Audit/Result";
const Dashboard = lazy(() => import("../pages/Dashboard"));
const LocalTesting = lazy(() => import("../pages/LocalTesting"))
const Environment = lazy(() => import("../pages/Settings/Component/Functional-Local-Testing/ExecutionEnvironment/index"));
const Application = lazy(() => import( "../pages/Settings/Component/Functional-Local-Testing/Application/index"));
const Browser = lazy(() => import("../pages/Settings/Component/Functional-Local-Testing/Browser/index")
);
const TestUser = lazy(() => import("../pages/Settings/Component/Functional-Local-Testing/TestUser/index"));
const EnvironmentTestLab = lazy(() => import("../pages/Settings/Component/Functional-Test-Lab/ExecutionEnvironment/index"));
const ApplicationTestLab = lazy(() => import("../pages/Settings/Component/Functional-Test-Lab/Application/index"));
const BrowserTestLab = lazy(() => import("../pages/Settings/Component/Functional-Test-Lab/Browser/index"));
const GlobalSelector = lazy(() => import("../pages/Settings/Component/Functional-Test-Lab/GlobalSelector"));
const GlobalTestData = lazy(() => import("../pages/Settings/Component/Functional-Test-Lab/GlobalTestData"));
const TestUserTestLab = lazy(() => import("../pages/Settings/Component/Functional-Test-Lab/TestUser/index"));
const BasicAccordion = lazy(() => import("../comman/Accordion/index"));
const TestSuitsDetails = lazy(() => import("../pages/TestSuitsDetails"));
const AddTestSuite = lazy(() => import("../pages/TestSuite/AddTestSuite"));

// Advance Automation routes
const AdTestUser = lazy(() => import("../pages/Settings/Component/Functional-Advance-Atumation/TestUser"));
const AdEnvironmnt = lazy(() => import("../pages/Settings/Component/Functional-Advance-Atumation/ExecutionEnvironment"));
const BuildVersion = lazy(() => import( "../pages/Settings/Component/Functional-Advance-Atumation/BuildVersion/index"));
const ReleaseVersion = lazy(() => import( "../pages/Settings/Component/Functional-Advance-Atumation/ReleaseVersion/index"));

const NotFound = lazy(() => import("../pages/NotFound"));
const TestLab = lazy(() => import("../pages/TestLab/TestLab"));
const Performance = lazy(() => import("../pages/Performance/Performance"));
const MainSettings = lazy(() => import("../pages/Settings"));


export default function Navigations() {
  useEffect(() => {
    getCustomerSubscriptionDetails();
    getTenantPlanFeatures();
  }, []);

  return (
    <Suspense
      fallback={
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
        >
          <CircularProgress sx={{ color: "#654DF7" }} />
        </Box>
      }
    >
      <Routes>
        <Route path="/" element={<Functional />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/ghost-ready" element={<LocalTesting />} />
          <Route path="/ghost-ready/:SuiteName/:SuiteType" element={<LocalTesting />} />
          {/* <Route path="/" element={<TestLab />} /> */}
          <Route path="/check" element={<h1>check</h1>} />
          <Route path="ghostLab" element={<TestLab />} />
          {/* <Route path="/new_design" element={<LocalTestingNew />} /> */}
          <Route path="ghostLab/:nodeId" element={<TestLab />} />
          {/* <Route
            path="testLab/createTestcase/:rootId"
            element={<CreateTestCase />}
          /> */}
          
          <Route path="testcase" element={<TestCase />} />
          <Route path="GhostAI" element={<AdvanceAutomation />}  />
        </Route>
        <Route
          path="/detail/:testSuiteName/:testRunId/:rootId/:startDate/:testRunNameId"
          element={<TestLabSuitsDetails />}
        />
        <Route
            path="/createTestcase/:rootId"
            element={<CreateTestCase />}
          />
           <Route
            path="/createTestrun/:rootId"
            element={<CreateTestRun />}
          />
          <Route
            path="/editTestrun/:runName"
            element={<CreateTestRun />}
          />
        <Route
            path="/editTestcase/:testId"
            element={<EditTestCase />}
          />
          <Route
          path="/advanceauto/:clientReferenceId/:browserName"
          element={<TestLabSuitsResults />}
        />
        <Route path="performance" element={<Performance />}></Route>
        <Route path="Accessibility/*" element={<AccessibilityPage />} />
        <Route
          path="/audit/detail/:clientReferenceId"
          element={<AuditResultDetails />}
        />
        <Route path="/result" element={<Results />}>
          <Route
            path="/result/:rootId/:tab/summary/:runId?"
            element={<Summary />}
          />
          <Route
            path="/result/:rootId/:tab/error/:runId?"
            element={<Error />}
          />
          <Route
            path="/result/:rootId/:tab/initial-setup/:runId?"
            element={<InitialSetup />}
          />
          <Route
            path="/result/:rootId/:tab/request-state/:runId?"
            element={<RequestState />}
          />
        </Route>
        <Route
          path="performance/compare/:runsId"
          element={<CompareGraph />}
        />
        <Route path="api" element={<Api />}>
          <Route
            path=""
            element={
              <Box m={10} component={"h1"}>
                api1
              </Box>
            }
          />
          <Route
            path="api2"
            element={
              <Box m={10} component={"h1"}>
                api2
              </Box>
            }
          />
        </Route>

        <Route path="/accordian" element={<BasicAccordion />} />
        <Route path="/myaccount" element={<Account />} />
        <Route
          path="/test/:testSuiteName/:testRunName/:RootId"
          element={<TestSuitsDetails />}
        />
        <Route path="/add-suite" element={<AddTestSuite />} />
        <Route path="/edit/:suiteName/:id/:rootid" element={<EditTestSuite />} />
        <Route path="/AcceptInvitation/:toEmail" element={<Invitation />} />
        <Route path="/add-suite/:id" element={<AddTestSuite />} />
        {true && <Route path="subscriptions" element={<Subscription />} />}

        
        <Route path="settings" element={<MainSettings />}>
          <Route path="location" element={<Location />} />
          <Route path="view-agent/:id" element={<ViewAgent />} />

          <Route path="detail" element={<Detail />} />
          <Route path="organization" element={<Organization />} />
          <Route path="members" element={<Members />} />
          <Route path="add-member" element={<Organization />} />

          {/* Funcational Local Testing Routes */}
          <Route path="environment" element={<Environment />} />
          <Route path="browser" element={<Browser />} />
          <Route path="application" element={<Application />} />
          <Route path="test-user" element={<TestUser />} />
          <Route path="integration" element={<Integration />} />
          <Route path="email-report" element={<EmailReport />} />
          <Route path="enviornment-variable" element={<EnvironmentVariable />} />
          <Route path="enviornment-variable/value" element={<EnvironmentVariable_value />} />

          {/* Funcational Test-Lab Routes */}
          <Route path="test-lab-environment" element={<EnvironmentTestLab />} />
          <Route path="test-lab-browser" element={<BrowserTestLab />} />
          <Route path="test-lab-application" element={<ApplicationTestLab />} />
          <Route path="test-lab-test-user" element={<TestUserTestLab />} />
          <Route path="on-prem/global-selector" element={<GlobalSelector />} />
          <Route path="on-prem/global-test-data" element={<GlobalTestData />} />
          <Route path="on-prem/integration" element={<h1>Integration</h1>} />
          <Route path="test-lab-enviornment-variable/" element={<EnvironmentVariable_TestLab />} />
          <Route path="test-lab-enviornment-variable/value" element={<EnvironmentVariable_value_TestLab />} />


          {/* Funcational Advance-Automation Routes */}
          <Route path="automation-test-user" element={<AdTestUser />} />
          <Route path="automation-environment" element={<AdEnvironmnt />} />
          <Route path="automation-build-version" element={<BuildVersion /> } />
          <Route path="automation-release-version" element={<ReleaseVersion/> } />
          <Route path="automation-integration" element={<h1>Integration</h1>} />
          <Route path="automation-email-report" element={<h1>Email report</h1>} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
}