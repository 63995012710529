import { toast } from "react-toastify";
import { getBaseUrl } from "../../../utils/configService";
import axios from "axios";
import { header } from "../../../utils/authheader";
export const SET_EXPANDED_NODE = "SET_EXPANDED_NODE";
export const EXPAND_PARENT = "EXPAND_PARENT";
export const SET_ROOT_ID = "SET_ROOT_ID";
export const SET_SELECTED_NODE = "SET_SELECTED_NODE";
export const SCHEDULE_SUITE = "SCHEDULE_SUITE";
export const SET_PARENT_ID = "SET_PARENT_ID";
export const SET_NODE_COUNT = "SET_NODE_COUNT";
export const EXECUTING_SUITE = "EXECUTING_SUITE";
export const SET_SELECTED_TAB = 'SET_SELECTED_TAB';
export const FETCH_CHART_DATA_REQUEST = "FETCH_CHART_DATA_REQUEST";
export const FETCH_CHART_DATA_SUCCESS = "FETCH_CHART_DATA_SUCCESS";
export const FETCH_CHART_DATA_FAILURE = "FETCH_CHART_DATA_FAILURE";

export const setSelectedTab = (tab) => ({
  type: SET_SELECTED_TAB,
  payload: tab,
});

export const setParentId = (parentId) => ({
  type: SET_PARENT_ID,
  payload: parentId,
});

export const setRootId = (suitId) => {
  return {
    type: SET_ROOT_ID,
    payload: suitId,
  };
};

export const setSelectedNode = (node) => (dispatch) => {
    dispatch({
      type: SET_ROOT_ID,
      payload: node.id,
    });

    dispatch({
      type: SET_SELECTED_NODE,
      payload: node,
    });
};
export const setExpandedNodes = (id) => {
  return {
    type: SET_EXPANDED_NODE,
    payload: id,
  };
};

export const ExpandParent = (id) => {
  return {
    type: EXPAND_PARENT,
    payload: id,
  };
};

export const SetSchedule = (isScheduling) => {
  return {
    type: SCHEDULE_SUITE,
    payload:isScheduling
  };
};


export const setNodeCount = (count) => {
  return {
    type: SET_NODE_COUNT,
    payload: count,
  };
};

export const setExecutingSuite = (suiteName) => {
  return {
    type: EXECUTING_SUITE,
    payload: suiteName,
  };
};
 
export const ExecutingSuiteAndProjectByRootId = (data) => {
  return async (dispatch) => {
    const BASE_URL = await getBaseUrl();
    try {
      const response = await axios.post(
        `${BASE_URL}/AddTestLab/InvokeTestLabTestRunPyhtonAPI`,
        data,
        header(),
      );
      if (response.status === 200) {
        toast.info("Successfully Executed", {
          style: {
            background: "rgb(101, 77, 247)",
            color: "rgb(255, 255, 255)",
          },
        });
      }
      dispatch(setExecutingSuite([]));
    } catch (error) {
      console.log("Network error", error);
      dispatch(setExecutingSuite([]))
     // toast.error(error.response?.data?.message || "NETWORK ERROR")
    }
  };
};

export const fetchChartData = (testSuiteName, rootId, filterType, filterValue) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_CHART_DATA_REQUEST });

    try {
      const BASE_URL = await getBaseUrl();
      const response = await axios.get(
        `${BASE_URL}/AddTestLab/GetTestRunTrendsChart?TestSuiteName=${testSuiteName}&RootId=${rootId}&Filtertype=${filterType}&FilterValue=${filterValue}&moduleType=GhostLab`,
        header()
      );

      const TestRunStartDate = [];
      const TotalFailedTestCase = [];
      const TotalPassedTestCase = [];
      const TotalTestCase = [];

      const extractDate = (dateStr) => {
        if (typeof dateStr !== 'string') {
          return null;
        }
        const [year, month, day] = dateStr?.split("-");

        const monthNames = {
          "01": "Jan",
          "02": "Feb",
          "03": "Mar",
          "04": "Apr",
          "05": "May",
          "06": "Jun",
          "07": "Jul",
          "08": "Aug",
          "09": "Sep",
          "10": "Oct",
          "11": "Nov",
          "12": "Dec",
        };

        return `${monthNames[month]} ${parseInt(day, 10)}, ${year}`;
      }; 
      const data = response.data?.data ? JSON.parse(response.data.data):[];
      if (data.length) {
        data.forEach((item) => {
          const formattedDate = extractDate(item.TestRunStartDate);
          const testRunName = item.SuiteType === "Custom" ? ` | ${item.TestRunName}` : "";
          TestRunStartDate.push(`${formattedDate}${testRunName}`);
          TotalFailedTestCase.push(item.TotalFailedTestCase);
          TotalPassedTestCase.push(item.TotalPassedTestCase);
          TotalTestCase.push(item.TotalTestCase);
        }); 

        dispatch({
          type: FETCH_CHART_DATA_SUCCESS,
          payload: {
            categories: TestRunStartDate,
            series: [
              { name: "Total Test Case", data: TotalTestCase },
              { name: "Passed", data: TotalPassedTestCase },
              { name: "Failed", data: TotalFailedTestCase },
            ],
          },
        });
      } else {
        dispatch({ type: FETCH_CHART_DATA_FAILURE, payload: "No data found" });
      }
    } catch (error) {
      console.error("Error fetching chart details:", error);
     // toast.error(error.response?.data?.message || "NETWORK ERROR")
      dispatch({ type: FETCH_CHART_DATA_FAILURE, payload: error.message });
    }
  };
};