import { GET_TEST_DATA_LIST } from "../../actions/testlab/testdataAction"; 

const initialState = {
  selectorList: [],
};

const testLabDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TEST_DATA_LIST:
      return {
        ...state,
        selectorList: action.payload,
      };
    default:
      return state;
  }
};

export default testLabDataReducer;
