import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

function UpgradeModal({ open, onClose }) {

    const handleUpgrade = ( ) => {
        window.location.href = 'https://www.ghostqa.com/pricing/'
    }

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Upgrade Plan"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
           You currently don't have access to this feature. Would you like to upgrade your plan? Please click here to upgrade.
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button
            onClick={handleUpgrade}
            style={{
              marginRight: "10px",
              backgroundColor: "#654DF7",
              height: "30px",
              width: "100px",
              color: "white",
            }}
          >
            Upgrade
          </Button>
          <Button
            onClick={onClose}
            color="primary"
            style={{
              backgroundColor: "#6c757d",
              width: "100px",
              height: "30px",
              color: "white",
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default UpgradeModal;