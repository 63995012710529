import React from 'react';
import { StyledOutlinedInput, StyledFormControl } from './styleTestCase'; 
import { Button } from '@mui/material'; 
import { styled } from '@mui/material/styles';


const CustomStyledOutlinedInput = styled(StyledOutlinedInput)(({ theme }) => ({
    height: '8px',
    '& .MuiOutlinedInput-input': { 
        height: '8px', 
    },
}));

const CustomFileInput = ({ fileName, onFileChange, disabled }) => {
    return (
        <StyledFormControl style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
           <CustomStyledOutlinedInput
                type="text"
                placeholder="No file chosen"
                value={fileName || ""}
                readOnly 
                onClick={() => document.getElementById('file-input').click()} 
                style={{ 
                    flex: 1, 
                    cursor: 'pointer', 
                    width: '100%',
                }} 
            />
            <input
                id="file-input"
                type="file"
                style={{ display: 'none' }} 
                onChange={onFileChange}
                disabled={disabled}
            />
            <Button
                variant="contained"
                onClick={() => document.getElementById('file-input').click()} 
                disabled={disabled}
                style={{
                    position: 'absolute',
                    right: '15px', 
                    top: '50%',
                    transform: 'translateY(-50%)', 
                    height: '60%', 
                    padding: '0 5px', 
                    zIndex: 1, 
                    backgroundColor: disabled ? null : '#654DF7',
                }}
            >
                Choose File
            </Button>
        </StyledFormControl>
    );
};

export default CustomFileInput; 