import { styled } from "@mui/material/styles";

export const Screenshot = styled(({ className, isActive, color }) => {
  return (
    <div className={className}>
      <svg
        width="28"
        height="28"
        viewBox="0 0 29 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.0373 21.1523L15.4309 18.5678C14.1733 17.3205 13.5444 16.6969 12.8228 16.4689C12.1881 16.2684 11.5059 16.2758 10.8758 16.49C10.1592 16.7336 9.54397 17.3706 8.31356 18.6447L2.06889 24.8127M18.0373 21.1523L18.5708 20.6234C19.83 19.3747 20.4595 18.7503 21.182 18.5223C21.8173 18.3219 22.5 18.3298 23.1306 18.5448C23.8475 18.7892 24.4627 19.428 25.6928 20.7053L27 22.0209M18.0373 21.1523L24.3047 27.432M2.06889 24.8127C2.11737 25.2064 2.19994 25.5177 2.34061 25.7938C2.6402 26.3817 3.11827 26.8598 3.70628 27.1594C4.37475 27.5 5.24983 27.5 7 27.5H22C23.0223 27.5 23.7462 27.5 24.3047 27.432M2.06889 24.8127C2 24.2533 2 23.5273 2 22.5V7.5C2 5.74984 2 4.87475 2.34061 4.20628C2.6402 3.61827 3.11827 3.1402 3.70628 2.84061C4.37475 2.5 5.24983 2.5 7 2.5H22C23.7502 2.5 24.6253 2.5 25.2938 2.84061C25.8817 3.1402 26.3598 3.61827 26.6594 4.20628C27 4.87475 27 5.74984 27 7.5V22.0209M27 22.0209V22.5C27 24.2502 27 25.1253 26.6594 25.7938C26.3598 26.3817 25.8817 26.8598 25.2938 27.1594C25.0158 27.3011 24.702 27.3837 24.3047 27.432M22.3125 10.3123C22.3125 12.0383 20.9134 13.4373 19.1875 13.4373C17.4616 13.4373 16.0625 12.0383 16.0625 10.3123C16.0625 8.58644 17.4616 7.18733 19.1875 7.18733C20.9134 7.18733 22.3125 8.58644 22.3125 10.3123Z"
          stroke={color}
          strokeWidth="3.125"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
})`
  display: flex;
`;
