import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  Switch,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import Select from "react-select";
import { useNavigate, useParams } from "react-router-dom";
import SearchField from "../../../comman/SearchField";
import { styled } from "@mui/material/styles";
import { TestCaseTable_TestRun } from "./TestCaseTable";
import { useSelector, useDispatch } from "react-redux";
import {
  GetBrowser_testlab,
  GetEnvironment_testlab,
} from "../../../redux/actions/seleniumAction";
import { GetTestUserList_testlab } from "../../../redux/actions/settingAction";
import { StyledFormControl, StyledOutlinedInput } from "./styleTestCase";
import useStyle from "./styles";
import { getEnviornment_Variableset_testlab } from "../../../redux/actions/settingAction";
import { AddTestRunDetail } from "../../../redux/actions/testlab/testrunAction";
import UpgradeModal from "../../../comman/Accordion/UpgradePlan/UpgradeModal";

export default function CreateTestRun() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyle();
  const { rootId, runName } = useParams();
  const isEditMode = Boolean(runName);
  const { runDetails } = useSelector((state) => state.testrun);
  const [testUserListOptions, setTestUserListOptions] = useState([]);
  const [browserListOptions, setBrowserListOptions] = useState([]);
  const { featurePlan } = useSelector((state) => state.auth);
  const [openUpgradeModal, setOpenUpgradeModal] = useState(false);
  const [environementListOptions, setEnvironementListOptions] = useState([]);
  const [
    enviornment_variable_set_testlabOptions,
    setEnviornment_variable_set_testlabOptions,
  ] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [Error, setError] = useState({
    testRunName: "",
    testRunDescription: "",
    environmentId: "",
    browserId: "",
    environmentVariableId: "",
    testUserId: "",
    noOfThreadsForParallelExecution: "",
    selectedTestCases: "",
  });

  const [formData, setFormData] = useState({
    rootId: isEditMode ? runDetails?.RootId || rootId : rootId,
    testRunId: isEditMode ? runDetails?.Id || 0 : 0,
    testRunName: isEditMode ? runDetails?.TestRunName || "" : "",
    testRunDescription: isEditMode ? runDetails?.TestRunDescription || "" : "",
    browserId: isEditMode ? runDetails?.Browsers?.[0]?.Id || "" : "",
    environmentId: isEditMode ? runDetails?.Environment?.Id || 0 : 0,
    environmentVariableId: isEditMode
      ? runDetails?.EnvironmentVariableId || 0
      : 0,
    testUserId: isEditMode ? runDetails?.TestUser?.Id || 0 : 0,
    sendEmail: isEditMode ? runDetails?.SendEmail || false : false,
    emailRecipients: isEditMode ? runDetails?.EmailRecipients || 0 : 0,
    isParallelExecution: isEditMode ? runDetails?.IsParellelExecution || 0 : 0,
    noOfThreadsForParallelExecution: isEditMode
      ? runDetails?.NoOfThreadsForParellelExecution || 0
      : 0,
    selectedTestCases: isEditMode
      ? runDetails?.TestCases?.map((tc) => tc.Id).join(",") || ""
      : "",
  });

  const { browserList_testlab, environementList_testlab } = useSelector(
    (state) => state.selenium
  );
  const { enviornment_variable_set_testlab, testUserList_testlab } =
    useSelector((state) => state.settings);
  const { testCases } = useSelector((state) => state.testlabTestCase);
  const isFeatureActive = featurePlan.some(
    (feature) =>
      feature.name === "GhostLab-Immediate Email Notification of Test result" &&
      feature?.limits?.limit?.is_active
  );
  const isBrowserEditable = featurePlan.some(
    (feature) =>
      feature.name === "GhostLab-Cross-Browser-Web-Testing" &&
      feature?.limits?.limit?.is_active
  );

  const handleCloseUpgradeModal = () => { 
    setOpenUpgradeModal(false);
  };

  useEffect(() => {
    dispatch(GetBrowser_testlab());
    dispatch(GetEnvironment_testlab());
    dispatch(GetTestUserList_testlab());
    dispatch(getEnviornment_Variableset_testlab());
  }, [dispatch]);

  useEffect(() => {
    if (browserList_testlab && browserList_testlab.length > 0) {
      const optionsT = browserList_testlab.map((browser) => ({
        value: browser.BrowserId,
        label: browser.BrowserName,
      }));
      setBrowserListOptions(optionsT);
    }

    if (environementList_testlab && environementList_testlab.length > 0) {
      const optionsR = environementList_testlab.map((env) => ({
        value: env.EnvironmentId,
        label: env.EnvironmentName,
      }));
      setEnvironementListOptions(optionsR);
    }

    if (
      enviornment_variable_set_testlab &&
      enviornment_variable_set_testlab.length > 0
    ) {
      const optionsB = enviornment_variable_set_testlab.map((env) => ({
        value: env.id,
        label: env.name,
      }));
      setEnviornment_variable_set_testlabOptions(optionsB);
    }

    if (testUserList_testlab && testUserList_testlab.length > 0) {
      const optionsE = testUserList_testlab.map((testUser) => ({
        value: testUser.UserId,
        label: testUser.UserName,
      }));
      setTestUserListOptions(optionsE);
    }

    if (isEditMode && runDetails) {
      const initialSelectedRows = runDetails.TestCases?.map((tc) => ({
        TestCaseDetailsId: tc.Id,
        TestCaseName: tc.TestCaseName,
      }));
      setSelectedRows(initialSelectedRows || []);

      const areAllSelected = initialSelectedRows?.length === testCases?.length;
      setSelectAll(areAllSelected);
    }
  }, [
    browserList_testlab,
    environementList_testlab,
    enviornment_variable_set_testlab,
    testUserList_testlab,
    runDetails,
    isEditMode,
  ]);

  useEffect(() => {
    const testCaseIds = selectedRows
      ?.map((row) => row?.TestCaseDetailsId)
      ?.join(",");

    setFormData((prevFormData) => ({
      ...prevFormData,
      selectedTestCases: testCaseIds,
    }));
  }, [selectedRows]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setError((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const handleSelectChange = (selectedOption, fieldName) => {
    setFormData((prevState) => ({
      ...prevState,
      [fieldName]: selectedOption ? selectedOption.value : "",
    }));
    setError((prev) => ({
      ...prev,
      [fieldName]: "",
    }));
  };

  const handleEmailToggle = (event) => {
    const checked = event.target.checked;
  
    if (checked && !isFeatureActive) {
      setOpenUpgradeModal(true);
      return;
    }
  
    setFormData((prevState) => ({
      ...prevState,
      sendEmail: checked,
      emailRecipients: checked ? 0 : 0,
    }));
  };

  const handleEmailRecipientChange = (event) => {
    const value = parseInt(event.target.value, 10);
    setFormData((prevState) => ({
      ...prevState,
      emailRecipients: value,
    }));
  };

  const handleSubmit = () => {
    let error = {};
    if (!formData.testRunName.trim()) {
      error.testRunName = "Test run name is required";
    }
    if (!formData.testRunDescription.trim()) {
      error.testRunDescription = "Description is required";
    }

    if (!formData.browserId) {
      error.browserId = "Browser is required";
    }

    if (!formData.environmentId) {
      error.environmentId = "Environment is required";
    }

    if (!formData.environmentVariableId) {
      error.environmentVariableId = "Environment variable is required";
    }

    if (!formData.testUserId) {
      error.testUserId = "Test user is required";
    }

    if (
      formData.isParallelExecution &&
      (!formData.noOfThreadsForParallelExecution ||
        formData.noOfThreadsForParallelExecution <= 0)
    ) {
      error.noOfThreadsForParallelExecution =
        "Valid number of threads is required for parallel execution";
    }

    if (!formData.selectedTestCases.trim()) {
      error.selectedTestCases = "At least one test case must be selected";
    }
    setError(error);

    if (Object.keys(error).length === 0) {
      dispatch(AddTestRunDetail(formData));
      onBack();
    }
  };

  const onBack = () => {
    navigate(-1);
  };

  const filteredTestCases = testCases.filter((testCase) =>
    testCase.TestCaseName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSelectAllChange = (event) => {
    const checked = event.target.checked;
    setSelectAll(checked);
    const newSelectedRows = checked ? testCases : [];
    setSelectedRows(newSelectedRows);

    if (newSelectedRows.length > 0) {
      setError((prevError) => ({
        ...prevError,
        selectedTestCases: "",
      }));
    }
  };

  const handleCheckboxChange = (event, row) => {
    const isSelected = selectedRows.some(
      (selectedRow) => selectedRow.TestCaseDetailsId === row.TestCaseDetailsId
    );

    const newSelectedRows = isSelected
      ? selectedRows.filter(
          (selectedRow) =>
            selectedRow.TestCaseDetailsId !== row.TestCaseDetailsId
        )
      : [...selectedRows, row];

    setSelectedRows(newSelectedRows);
    setSelectAll(newSelectedRows.length === testCases.length);

    if (newSelectedRows.length > 0) {
      setError((prevError) => ({
        ...prevError,
        selectedTestCases: "",
      }));
    }
  };

  

  return (
    <>
     <UpgradeModal
        open={openUpgradeModal}
        onClose={handleCloseUpgradeModal} 
      />
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <Grid container justifyContent="flex-start">
            <Grid item>
              <Typography
                className={classes.header}
                justifyContent="flex-start"
              >
                {isEditMode ? "Edit Test Run" : "Create Test Run"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          {/* Left Side: Form Inputs */}
          <Grid item xs={12} md={6}>
            <Box className={classes.leftContainer}>
              <Grid className={classes.customInputStyle}>
                <StyledFormControl>
                  <Typography className={classes.customFontSize}>
                    Test Run Name
                  </Typography>
                  <StyledOutlinedInput
                    type="text"
                    name="testRunName"
                    placeholder="Enter Test Run Name"
                    value={formData.testRunName}
                    onChange={handleInputChange}
                  />
                  {Error.testRunName && (
                    <Typography className={classes.errorText}>
                      {Error.testRunName}
                    </Typography>
                  )}
                </StyledFormControl>
              </Grid>

              <Grid className={classes.customInputStyle}>
                <StyledFormControl>
                  <Typography className={classes.customFontSize}>
                    Test Run Description
                  </Typography>
                  <StyledOutlinedInput
                    type="text"
                    name="testRunDescription"
                    placeholder="Enter Description"
                    value={formData.testRunDescription}
                    onChange={handleInputChange}
                  />
                  {Error.testRunDescription && (
                    <Typography className={classes.errorText}>
                      {Error.testRunDescription}
                    </Typography>
                  )}
                </StyledFormControl>
              </Grid>

              <Grid className={classes.customInputStyle}>
                <SelectField
                  label="Browser"
                  options={browserListOptions}
                  value={formData.browserId}
                  onChange={(isEditMode ? (isBrowserEditable ? ((option) => handleSelectChange(option, "browserId")) : (() => {setOpenUpgradeModal(true)})) : ((option) => handleSelectChange(option, "browserId")))}
                  placeholder="Select Browser"
                  classes={classes}
                />
                {Error.browserId && (
                  <Typography className={classes.errorText}>
                    {Error.browserId}
                  </Typography>
                )}
              </Grid>

              <Grid className={classes.customInputStyle}>
                <SelectField
                  label="Environment"
                  options={environementListOptions}
                  value={formData.environmentId}
                  onChange={(option) =>
                    handleSelectChange(option, "environmentId")
                  }
                  placeholder="Select Environment"
                  classes={classes}
                />
                {Error.environmentId && (
                  <Typography className={classes.errorText}>
                    {Error.environmentId}
                  </Typography>
                )}
              </Grid>
              <Grid className={classes.customInputStyle}>
                <SelectField
                  label="Environment Variable"
                  options={enviornment_variable_set_testlabOptions}
                  value={formData.environmentVariableId}
                  onChange={(option) =>
                    handleSelectChange(option, "environmentVariableId")
                  }
                  placeholder="Select Environment Variable"
                  classes={classes}
                />
                {Error.environmentVariableId && (
                  <Typography className={classes.errorText}>
                    {Error.environmentVariableId}
                  </Typography>
                )}
              </Grid>
              <Grid className={classes.customInputStyle}>
                <Grid container alignItems="center" direction="row" spacing={2}>
                  <Grid item>
                    <Typography className={classes.customFontSize}>
                      Parallel Execution
                    </Typography>
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <CustomSwitch
                          checked={formData.isParallelExecution === 1}
                          onChange={(e) =>
                            setFormData((prevState) => ({
                              ...prevState,
                              isParallelExecution: e.target.checked ? 1 : 0,
                            }))
                          }
                        />
                      }
                    />
                  </Grid>
                  {formData.isParallelExecution === 1 && (
                    <Grid item>
                      <Select
                        isClearable={true}
                        placeholder="Select Number of Threads"
                        options={[
                          { value: 1, label: "1 Thread" },
                          { value: 2, label: "2 Threads" },
                          { value: 3, label: "3 Threads" },
                        ]}
                        onChange={(option) =>
                          handleSelectChange(
                            option,
                            "noOfThreadsForParallelExecution"
                          )
                        }
                        value={
                          formData.noOfThreadsForParallelExecution
                            ? {
                                value: formData.noOfThreadsForParallelExecution,
                                label: `${
                                  formData.noOfThreadsForParallelExecution
                                } Thread${
                                  formData.noOfThreadsForParallelExecution > 1
                                    ? "s"
                                    : ""
                                }`,
                              }
                            : null
                        }
                        styles={controlStyles}
                      />
                      {Error.noOfThreadsForParallelExecution && (
                        <Typography className={classes.errorText}>
                          {Error.noOfThreadsForParallelExecution}
                        </Typography>
                      )}
                    </Grid>
                  )}
                </Grid>
              </Grid>

              <Grid className={classes.customInputStyle}>
                <SwitchField
                  label="Email Recipient"
                  checked={formData.sendEmail}
                  onChange={handleEmailToggle}
                  classes={classes}
                >
                  {formData.sendEmail && (
                    <RadioGroup
                      row
                      name="emailRecipient"
                      value={formData.emailRecipients?.toString() || ""}
                      onChange={handleEmailRecipientChange}
                      sx={{ paddingTop: "4px" }}
                    >
                      <FormControlLabel
                        value="0"
                        control={<CustomRadio />}
                        label={
                          <Typography
                            variant="body1"
                            className={classes.customFontSize}
                          >
                            Me
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="1"
                        control={<CustomRadio />}
                        label={
                          <Typography
                            variant="body1"
                            className={classes.customFontSize}
                          >
                            All
                          </Typography>
                        }
                      />
                    </RadioGroup>
                  )}
                </SwitchField>
              </Grid>

              <Grid className={classes.customInputStyle}>
                <SelectField
                  label="Test User"
                  options={testUserListOptions}
                  value={formData.testUserId}
                  onChange={(option) =>
                    handleSelectChange(option, "testUserId")
                  }
                  placeholder="Select Test User"
                  classes={classes}
                />
                {Error.testUserId && (
                  <Typography className={classes.errorText}>
                    {Error.testUserId}
                  </Typography>
                )}
              </Grid>
            </Box>
          </Grid>

          {/* Right Side: Test Case Table */}
          <Grid item xs={12} md={6}>
            <>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                className={classes.testCaseStyle}
              >
                <Typography className={classes.customFontSize}>
                  Test Cases
                </Typography>
                <SearchField
                  placeholder="Search Test Cases..."
                  onChange={(value) => setSearchTerm(value)}
                />
              </Grid>

              <Grid className={classes.testCase}>
                <TestCaseTable_TestRun
                  rows={filteredTestCases}
                  selectedRows={selectedRows}
                  handleSelectAllChange={handleSelectAllChange}
                  handleCheckboxChange={handleCheckboxChange}
                  selectAll={selectAll}
                />
                {Error.selectedTestCases && (
                  <Typography className={classes.errorText}>
                    {Error.selectedTestCases}
                  </Typography>
                )}
              </Grid>

              {/* Buttons aligned to the right */}
              <Grid container justifyContent="flex-end" sx={{ marginTop: 2 }}>
                <Button
                  className={classes.cancelButton}
                  onClick={onBack}
                  sx={{ marginRight: 1 }}
                >
                  Cancel
                </Button>
                <Button className={classes.buttonadd} onClick={handleSubmit}>
                  {isEditMode ? "Update" : "Save"}
                </Button>
              </Grid>
            </>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

const controlStyles = {
  control: (provided, state) => ({
    ...provided,
    borderColor: state.isFocused ? "#654DF7" : provided.borderColor,
    boxShadow: state.isFocused ? "0 0 0 1px #654DF7" : provided.boxShadow,
    "&:hover": { borderColor: "#654DF7" },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? "#654DF7"
      : state.isFocused
      ? "#654DF7"
      : provided.backgroundColor,
    color: state.isFocused ? "white" : provided.color,
    "&:hover": { backgroundColor: "#654DF7", color: "white" },
  }),
};

const SelectField = ({
  label,
  options,
  value,
  onChange,
  placeholder,
  classes,
}) => (
  <StyledFormControl>
    <Typography className={classes.customFontSize}>{label}</Typography>
    <Select
      isClearable
      placeholder={placeholder}
      options={options}
      value={options?.find((option) => option.value === value)}
      onChange={onChange}
      styles={controlStyles}
    />
  </StyledFormControl>
);

const SwitchField = ({ label, checked, onChange, children, classes }) => {
  const labelClassName = label === "Email Recipient" ? classes.emailRecipient : "";

  return (
    <StyledFormControl>
      <Grid container alignItems="center" spacing={2}>
        <Grid item >
          <Typography className={classes.customFontSize}>{label}</Typography>
        </Grid>
        <Grid item >
          <FormControlLabel
          className={labelClassName}
            control={<CustomSwitch checked={checked} onChange={onChange} />}
          />
        </Grid>
        {checked && (
          <Grid item>
            {children}
          </Grid>
        )}
      </Grid>
    </StyledFormControl>
  );
};



const CustomSwitch = styled(Switch)(({ theme, checked }) => ({
  "& .MuiSwitch-thumb": {
    backgroundColor: checked ? "#654DF7" : "#ffffff",
  },
  "& .MuiSwitch-track": {
    backgroundColor: checked ? "#654DF7" : "#cccccc",
  },
}));

const CustomRadio = styled(Radio)(({ theme }) => ({
  color: "#654DF7",
  "&.Mui-checked": {
    color: "#654DF7",
  },
  "& .MuiSvgIcon-root": {
    borderColor: "#654DF7",
  },
}));
