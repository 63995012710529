import { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { StyledTypography } from "./styles";
import CustomeImgView from "./modals/CustomeImgView";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Typography,
  Box,
} from "@mui/material";
import AccessibilityViolationsModal from "./modals/AccessibilityViolationsModal";
import { useSelector } from "react-redux";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: value === "details" ? 0 : 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function ScreenshotList({ data }) {
  const [value, setValue] = useState("details");
  const [accessibilityModalOpen, setAccessibilityModalOpen] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const { violationData } = useSelector((state) => state.audit);

  const groupByImpact = (data) => {
    return data.reduce((acc, violation) => {
      const { impact } = violation;
      if (!acc[impact]) {
        acc[impact] = [];
      }
      acc[impact].push(violation);
      return acc;
    }, {});
  };

  const groupedViolations = groupByImpact(violationData);

  const handleAccessibilityClick = (impactCategory) => {
    setFilteredData(groupedViolations[impactCategory]);
    setAccessibilityModalOpen(true);
  };

  const handleCloseAccessibilityModal = () => {
    setAccessibilityModalOpen(false);
  };

  const screenshotURLs =
    data?.ScreenShotURLs && typeof data.ScreenShotURLs === "string"
      ? data.ScreenShotURLs.split(",").map((url) => url.trim())
      : [];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Tabs
        value={value}
        onChange={handleChange}
        sx={{
          "& .MuiTabs-indicator": {
            backgroundColor: "rgb(101, 77, 247)",
          },
          "& .Mui-selected": {
            color: "rgb(101, 77, 247)",
          },
          "& .MuiTab-root": {
            textTransform: "capitalize",
            color: "gray",
          },
          "& .Mui-selected": {
            color: "rgb(101, 77, 247) !important",
            fontWeight: "bold",
          },
        }}
      >
        <Tab
          value="details"
          label={
            <StyledTypography sx={{ textTransform: "capitalize" }}>
              Details
            </StyledTypography>
          }
        />
        {/* <Tab
          value="screenshot"
          label={
            <StyledTypography sx={{ textTransform: "capitalize" }}>
              Screenshots
            </StyledTypography>
          }
        /> */}
      </Tabs>
      <TabPanel
        value={value}
        index="details"
        sx={{ width: "100%", padding: "0px !important" }}
      >
        <TableContainer
          component={Paper}
          sx={{ width: "100%", padding: "0px" }}
        >
          <Table sx={{ width: "100%", padding: "0px" }}>
            <TableBody sx={{ cursor: "pointer" }}>
              {Object.keys(groupedViolations).map((impact, index) => (
                <TableRow
                  key={index}
                  onClick={() => handleAccessibilityClick(impact)}
                >
                  <TableCell>{impact.charAt(0).toUpperCase() + impact.slice(1)}</TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">
                    {groupedViolations[impact].length}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>

      <TabPanel value={value} index={"screenshot"}>
        {screenshotURLs.length > 0 ? (
          screenshotURLs.map((url, index) => (
            <CustomeImgView key={index} ScreenshotUrl={url} />
          ))
        ) : (
          <Typography>No screenshots available</Typography>
        )}
      </TabPanel>

      <AccessibilityViolationsModal
        isOpen={accessibilityModalOpen}
        onClose={handleCloseAccessibilityModal}
        data={filteredData}
      />
    </Box>
  );
}
