import {
  SET_ROOT_ID,
  FETCH_DATA_REQUEST,
  FETCH_DATA_SUCCESS,
  FETCH_DATA_FAILURE,
  SET_EXPANDED_NODE,
  EXPAND_PARENT,
  ADD_WORKSPACE,
  UPDATE_WORKSPACE,
  DELETE_WORKSPACE,
  SET_SELECTED_NODE,
  FETCH_AUDIT_DATA,
  SITE_DATA,
  FETCH_JOURNEY_SUCCESS,
  CLEAR_AUDIT_LIST,
  VIOLATION_DATA,
  AUDIT_RESULT
} from "../../actions/Accessibility/AuditAction";

const initialState = {
  listData: [],
  isLoading: false,
  error: null,
  selectedNodeId: null,
  selectedNode:null,
  expanded: [],
  auditData: [],
  siteData:[],
  violationData:[],
  auditResult:[]
};

const AuditReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_AUDIT_DATA:
      return {
        ...state,
        auditData: action.payload,
      };
    case FETCH_JOURNEY_SUCCESS: {
      return {
        ...state,
        auditData: action.payload
      }
    };
    case CLEAR_AUDIT_LIST: {
      return {
        ...state,
        auditData: action.payload,
      };
    }
    case FETCH_DATA_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case FETCH_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listData: action.payload,
        error: null,
      };
    case FETCH_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case ADD_WORKSPACE: {
      return {
        ...state,
        listData: [...state.listData, action.payload],
      };
    }
    case UPDATE_WORKSPACE: {
      const newNode = action.payload;
      const updatedListData = state.listData.map((node) =>
        node.id === newNode.id ? newNode : node
      );

      return {
        ...state,
        listData: updatedListData,
      };
    }
    case DELETE_WORKSPACE: {
      const id = action.payload;
      const updatedListData = state.listData.filter((node) =>
        node.id !== id
      );

      return {
        ...state,
        listData: updatedListData,
      };
    }
    case SET_ROOT_ID: {
      return {
        ...state,
        selectedNodeId: action.payload,
      };
    }
    case SET_SELECTED_NODE: { 
      return {
        ...state,
        selectedNode: Array.isArray(state?.listData)
          ? state.listData.find((data) => data?.id === state?.selectedNodeId)
          : null,  
      };
    }
    case SITE_DATA: {
          return {
            ...state,
            siteData: action.payload
            }
        }
    case SET_EXPANDED_NODE: {
      const id = action.payload;
      const index = state.expanded?.indexOf(id);
      if (index === -1) {
        return {
          ...state,
          expanded: [...(state.expanded || []), id],
        };
      } else {
        const newExpanded = [
          ...state.expanded.slice(0, index),
          ...state.expanded.slice(index + 1),
        ];
        return {
          ...state,
          expanded: newExpanded,
        };
      }
    }
    case EXPAND_PARENT: {
      const id = action.payload;
      const index = state.expanded?.indexOf(id);
      if (index === -1) {
        return {
          ...state,
          expanded: [...(state.expanded || []), id],
        };
      }
      return state
    }
    case VIOLATION_DATA:
      return {
        ...state,
        violationData: action.payload,
      };
      case AUDIT_RESULT:
        return {
          ...state,
          auditResult: action.payload,
        };
    default:
      return state;
  }
};
export default AuditReducer;