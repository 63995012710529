import axios from "axios";
import { getBaseUrl, getCoreEngineBaseUrl  } from "../../../utils/configService";
import { header, headerCypres  } from "../../../utils/authheader";
import { toast } from "react-toastify";

export const FETCH_TEST_CASES_REQUEST = "FETCH_TEST_CASES_REQUEST";
export const FETCH_TEST_CASES_SUCCESS = "FETCH_TEST_CASES_SUCCESS";
export const FETCH_TEST_CASES_FAILURE = "FETCH_TEST_CASES_FAILURE";
export const DELETE_TEST_CASE_REQUEST = "DELETE_TEST_CASE_REQUEST";
export const DELETE_TEST_CASE_SUCCESS = "DELETE_TEST_CASE_SUCCESS";
export const DELETE_TEST_CASE_FAILURE = "DELETE_TEST_CASE_FAILURE";

export const START_EXECUTION = "START_EXECUTION";
export const COMPLETE_EXECUTION = "COMPLETE_EXECUTION";
export const FAIL_EXECUTION = "FAIL_EXECUTION"

const notify = (type, message) => {
  const options = {
    style: {
      background: "rgb(101, 77, 247)",
      color: "rgb(255, 255, 255)",
    },
  };
  if (type === "success") {
    toast.info(message, options);
  } else {
   // toast.error(message);
  }
};

export const fetchTestCases = (selectedNodeId) => async (dispatch) => {
  dispatch({ type: FETCH_TEST_CASES_REQUEST });

  try {
    const BASE_URL = await getBaseUrl();
    const response = await axios.get(
      `${BASE_URL}/AddTestLab/GetTestCaseDetailsByRootId?RootId=${selectedNodeId}`,
      header()
    );

    if (response.data.status === "fail" || response.data === "") {
      dispatch({ type: FETCH_TEST_CASES_SUCCESS, payload: [] });
      return response
    } else {
      const reversedTestCaseList = response.data?.data ? (JSON.parse(response.data.data)).reverse():[];
      dispatch({
        type: FETCH_TEST_CASES_SUCCESS,
        payload: reversedTestCaseList,
      });
      return response
    }
  } catch (error) {
    notify("error",error.response?.data?.message || "NETWORK ERROR");
    console.error("Error fetching data:", error);
    dispatch({ type: FETCH_TEST_CASES_FAILURE, payload: error });
    return error
  }
};

export const deleteTestCase = (testId, setopenDelModal) => async (dispatch) => {
  dispatch({ type: DELETE_TEST_CASE_REQUEST });
  try {
    const BASE_URL = await getBaseUrl();
    const response = await axios.post(
      `${BASE_URL}/AddTestLab/DeleteTestCaseDetailsByTestCaseDetailsId`,
      { id: testId },
      header()
    );
    if (response.data?.status && response.data.status.trim().toLowerCase() == "success") {
      dispatch({ type: DELETE_TEST_CASE_SUCCESS, payload: testId });
      notify("success", "Successfully deleted");
      setopenDelModal(false);
    }
  } catch (error) {
    console.error("Error deleting test case:", error);
    dispatch({ type: DELETE_TEST_CASE_FAILURE, payload: error });
    notify("error",error.response?.data?.message || "NETWORK ERROR");
    setopenDelModal(false);
  }
};

export const executeTestCase = (row, selectedNodeId) => async (dispatch) => {
  dispatch({ type: START_EXECUTION, payload: row.TestCaseName });
  try {
    const BASE_URL = await getBaseUrl();

    const payload = {
      rootId: row.RootId,
      testCaseName: row?.TestCaseName,
      moduleType: "GhostLab"
    };

    const executedDetail = await axios.post(
      `${BASE_URL}/AddTestLab/ExecuteTestLabDesignTestCase`,
      payload,
      header()
    );

    dispatch({ type: COMPLETE_EXECUTION, payload: row.TestCaseName });
    // notify("success", `${row.TestCaseName} executed successfully`);
    return executedDetail
  } catch (error) {
    console.error("Error executing test case:", error);
    dispatch({ type: FAIL_EXECUTION, payload: row.TestCaseName });
    notify("error", `${row.TestCaseName} execution failed`);
  }
};
