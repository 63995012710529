import React, { useState, useEffect } from "react";
import { Box, CircularProgress, Grid, Modal } from "@mui/material";
import Select from "react-select";
import {
  StyledButton,
  StyledFormControl,
  StyledOutlinedInput,
  StyledTypography,
} from "./style";
import { useSelector, useDispatch } from "react-redux";
import { SchedulingTestRun, fetchScheduleDetails } from "../../../../redux/actions/AdvanceAutomation/JourneyAction";
import { toast } from "react-toastify";

// Format date for <input type="date">
function formatDateForInput(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

export default function TestRunSchedular({ isOpen, onClose, rowData }) {
  const dispatch = useDispatch();
  const { scheduleDetails, loading } = useSelector((state) => state.journey);
  const userId =
    useSelector((state) => state.auth.userId) ||
    JSON.parse(localStorage.getItem("userData"))?.id ||
    "Cannot Get User Id";

  const [interval, setInterval] = useState(null);
  const [time, setTime] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [repeatEvery, setRepeatEvery] = useState(0);
  const [repeatUnit, setRepeatUnit] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (isOpen && rowData) {
      dispatch(
        fetchScheduleDetails(
          rowData.Id,
          rowData.Name,
          clearFormFields
        )
      );
    }
  }, [isOpen, rowData, dispatch]);

  useEffect(() => {
    if (scheduleDetails) {
      setInterval(
        scheduleOption?.find(
          (option) => option.value === scheduleDetails.interval
        )
      );
      setTime(scheduleDetails?.startDateTime?.split("T")[1]);
      setStartDate(scheduleDetails?.startDateTime?.split("T")[0]);
      setEndDate(scheduleDetails?.endDate?.split("T")[0]);
      setRepeatEvery(scheduleDetails?.repeatEvery);
      setIsEditing(true);
    }
  }, [scheduleDetails]);

  function DatesValidation(startDate = null, EndDate = null) {
    if (startDate) {
      const currentDate = new Date();
      const inputDate = new Date(startDate);
      if (inputDate < currentDate || isNaN(inputDate)) {
        setStartDate(formatDateForInput(new Date()));
      
      }
      
    }
    if (EndDate) {
      const currentDate = new Date();
      const inputEndDate = new Date(endDate);
      const inputStartDate = new Date(startDate); // Assuming startDate is a state value
      if (endDate == "") {
        return;
      }
      if (
        inputEndDate == inputStartDate ||
        inputEndDate === currentDate ||
        endDate == startDate
      ) {
        return;
      }
      if (
        inputEndDate < inputStartDate ||
        inputEndDate < currentDate ||
        isNaN(inputEndDate)
      ) {
        toast.error("End date cannot be set before start date.");
        setEndDate("");
       
      }
    }
  }

  const scheduleOption = [
    { label: "Do Not Repeat", value: "Do Not Repeat" },
    { label: "Every Weekday (Mon - Fri)", value: "Every Weekday (Mon - Fri)" },
    { label: "Weekly", value: "Weekly" },
    { label: "Monthly", value: "Monthly" },
  ];

  const customOptions = [
    { label: "Day", value: "Day" },
    { label: "Week", value: "Week" },
    { label: "Month", value: "Month" },
  ];

  const handleSchedule = async () => {
    const userEmail = localStorage.getItem("email");
    let daysOfWeek = [];
    if (interval?.value === "Weekly") {
      daysOfWeek = [2, 4, 6];
    }

    const scheduleData = {
      interval: interval?.value || "",
      suiteName: rowData?.Name,
      journeyId: rowData?.JourneyId,
      userEmail: userEmail,
      startDateTime: `${startDate}T${time}`,
      endDate: `${endDate}T${time}` || null,
      daysOfWeek: daysOfWeek,
      createdBy: userId,
      id: scheduleDetails?.id || 0,
      repeatEvery: repeatEvery,
      schedulerType: "TestRun",
      rootId: rowData?.Id,
    };
    dispatch(SchedulingTestRun(scheduleData, onClose()));
  };

  const clearFormFields = () => {
    setInterval(null);
    setTime("");
    setStartDate("");
    setEndDate("");
    setRepeatEvery("0");
    setRepeatUnit(null);
  };

  const handleModalClose = () => {
    clearFormFields();
    onClose();
  };

  const selectStyle = {
    container: (provided) => ({
      ...provided,
      backgroundColor: "rgb(242, 242, 242)",
      width: "100%",
    }),
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "rgb(242, 242, 242)",
      "&:hover": {
        borderColor: "#654DF7",
      },
      borderColor: state.isFocused ? "#654DF7" : "#DADADA",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#654DF7" : "transparent",
    }),
    clearIndicator: (provided) => ({
      ...provided,
      cursor: "pointer",
      ":hover": {
        color: "#654DF7",
      },
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      cursor: "pointer",
      ":hover": {
        color: "#654DF7",
      },
    }),
  };

  return (
    <Modal open={isOpen}>
      <Box sx={{ ...modalStyles }}>
        <Grid container>
          {loading ? (
            <Grid container justifyContent="center" alignItems="center">
              <CircularProgress />
            </Grid>
          ) : (
            <>
              <Grid item xs={12}>
                <StyledTypography
                  sx={{ fontSize: "24px", marginBottom: "10px" }}
                >
                  {rowData?.Name?.length > 40
                    ? rowData?.Name.slice(0, 40) + "..."
                    : rowData?.Name}
                </StyledTypography>
              </Grid>

              <Grid item xs={12} lg={12}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box>
                      <StyledTypography>Interval</StyledTypography>
                      <Select
                        isClearable={true}
                        fullWidth
                        placeholder="Interval"
                        options={scheduleOption}
                        styles={selectStyle}
                        value={interval}
                        onChange={(option) => setInterval(option)}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box>
                      <StyledTypography>Time</StyledTypography>
                      <StyledFormControl>
                        <StyledOutlinedInput
                          type="time"
                          placeholder="Enter Schedule Time"
                          value={time}
                          onChange={(e) => setTime(e.target.value)}
                        />
                      </StyledFormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box>
                      <StyledTypography>Start Date</StyledTypography>
                      <StyledFormControl>
                        <StyledOutlinedInput
                          type="date"
                          placeholder="Enter Start Date"
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                          disabled={!isEditing}
                          inputProps={{ min: formatDateForInput(new Date()) }}
                          onBlur={() => DatesValidation(startDate,endDate)}
                        />
                      </StyledFormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box>
                      <StyledTypography>End Date</StyledTypography>
                      <StyledFormControl>
                        <StyledOutlinedInput
                          type="date"
                          placeholder="Enter End Date"
                          value={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                          disabled={!isEditing}
                          inputProps={{ min: formatDateForInput(new Date()) }}
                          onBlur={() => DatesValidation(startDate,endDate)}
                        />
                      </StyledFormControl>
                    </Box>
                  </Grid>
                  {interval && interval.value === "Custom" && (
                    <Grid item xs={6}>
                      <Grid
                        container
                        justifyContent="space-between"
                        spacing={1}
                      >
                        <Grid item xs={4} display="flex" alignItems="center">
                          <StyledTypography
                            sx={{ fontSize: "18px", whiteSpace: "nowrap" }}
                          >
                            Repeat Every
                          </StyledTypography>
                        </Grid>
                        <Grid item xs={2}>
                          <StyledFormControl>
                            <StyledOutlinedInput
                              type="number"
                              value={repeatEvery}
                              onChange={(e) =>
                                setRepeatEvery(parseInt(e.target.value, 10))
                              }
                            />
                          </StyledFormControl>
                        </Grid>
                        <Grid item xs={5}>
                          <Select
                            isClearable={true}
                            fullWidth
                            options={customOptions}
                            styles={selectStyle}
                            value={repeatUnit}
                            onChange={(option) => setRepeatUnit(option)}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>

              <Grid
                container
                justifyContent="flex-end"
                sx={{ marginTop: "20px" }}
              >
                <StyledButton onClick={handleModalClose}>Cancel</StyledButton>
                <StyledButton
                  variant="contained"
                  color="primary"
                  sx={{
                    marginLeft: "10px",
                    backgroundColor: "rgb(101, 77, 247)",
                    color: "white",
                  }}
                  onClick={handleSchedule}
                >
                  {scheduleDetails?.id ? "Update" : "Save"}
                </StyledButton>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </Modal>
  );
}

const modalStyles = {
  backgroundColor: "white",
  padding: "20px",
  width: "500px",
  height: "auto",
  borderRadius: "10px",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};
