import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { Document, Packer, Paragraph, TableRow as DocxTableRow, TableCell as DocxTableCell, Table as DocxTable, WidthType } from "docx";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

export const handleExportOption = (type, testRunExecutionHistory, testRunId, setAnchorEl) => {
  if (!testRunExecutionHistory?.TestCaseDetailsList) {
    console.error("No data available to export");
    return;
  }

  const { PassedTestCases, FailedTestCases, UserName, TestCaseDetailsList } = testRunExecutionHistory;
  const data = TestCaseDetailsList.map((row) => ({
    runId: row.TestRunName || "",
    testCaseName: row.TestCaseName || "",
    status: row.TestCaseStatus || "",
    testerName: UserName || "",
    totalPassed: PassedTestCases || 0,
    totalFailed: FailedTestCases || 0,
  }));

  const tableHeaders = ["Run Id", "Test Case Name", "Status", "Tester Name", "Total Passed", "Total Failed"];

  if (type === "PDF") {
    const doc = new jsPDF();
    doc.text(`Test Run ID: ${testRunId}`, 10, 10);

    autoTable(doc, {
      head: [tableHeaders],
      body: data.map((item) => [
        item.runId,
        item.testCaseName,
        item.status,
        item.testerName,
        item.totalPassed,
        item.totalFailed,
      ]),
    });

    doc.save(`TestRun_${testRunId || "details"}.pdf`);
  } else if (type === "Word") {
    const tableRows = data.map((item) =>
      new DocxTableRow({
        children: [
          new DocxTableCell({ children: [new Paragraph(item.runId)] }),
          new DocxTableCell({ children: [new Paragraph(item.testCaseName)] }),
          new DocxTableCell({ children: [new Paragraph(item.status)] }),
          new DocxTableCell({ children: [new Paragraph(item.testerName)] }),
          new DocxTableCell({ children: [new Paragraph(String(item.totalPassed))] }),
          new DocxTableCell({ children: [new Paragraph(String(item.totalFailed))] }),
        ],
      })
    );

    const doc = new Document({
      sections: [
        {
          children: [
            new Paragraph({
              text: `Test Run ID: ${testRunId}`,
              heading: "Heading1",
            }),
            new DocxTable({
              rows: [
                new DocxTableRow({
                  children: tableHeaders.map((header) => new DocxTableCell({ children: [new Paragraph(header)] })),
                }),
                ...tableRows,
              ],
              width: {
                size: 100,
                type: WidthType.PERCENTAGE,
              },
            }),
          ],
        },
      ],
    });

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, `TestRun_${testRunId || "details"}.docx`);
    });
  } else if (type === "XLS") {
    const worksheetData = [tableHeaders, ...data.map((item) => Object.values(item))];
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Test Run Data");
    XLSX.writeFile(workbook, `TestRun_${testRunId || "details"}.xlsx`);
  }

  setAnchorEl(null); 
};
