import React from 'react';
import ReactDOM from 'react-dom';
import './index.css'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux';
import store from './redux/store'
import App from './App';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { PostHogProvider} from 'posthog-js/react'

const REACT_APP_PUBLIC_POSTHOG_KEY="phc_hBcSqNKi4fKhpu3eEa7zVU629JUOcurJdnqLKNc93HB"
const REACT_APP_PUBLIC_POSTHOG_HOST="https://us.i.posthog.com"
 

const options = {
  api_host: REACT_APP_PUBLIC_POSTHOG_HOST,
}
const theme = createTheme({
  typography: {
    fontFamily: [
      "Lexend Deca",
      'sans-serif'
    ].join(','),
  },});

ReactDOM.render(
  <React.StrictMode>
  <PostHogProvider 
      apiKey={REACT_APP_PUBLIC_POSTHOG_KEY}
      options={options}
    >
    <ThemeProvider theme={theme}>
    <BrowserRouter>
      <Provider store={store} >
        <App />
      </Provider>
    </BrowserRouter >
    </ThemeProvider>
    </PostHogProvider>
  </React.StrictMode>,
  document.getElementById('root')
);