import {
  SET_ROOT_ID,
  FETCH_DATA_REQUEST,
  FETCH_DATA_SUCCESS,
  FETCH_DATA_FAILURE,
  SET_EXPANDED_NODE,
  EXPAND_PARENT,
  ADD_WORKSPACE,
  UPDATE_WORKSPACE,
  DELETE_WORKSPACE,
  SET_SELECTED_NODE,
  SITE_DATA,
  JOURNEY_DETAILS,
  GET_ENVIRONMENT_LIST,
  GET_ENVIRONMENT_DETAILS,
  FETCH_JOURNEY_SUCCESS,
  CLEAR_JOURNEY_LIST,
  JOURNEY_ID,
  GET_RUNS_LIST,
  GET_RUNS_DETAILS,
  EXECUTE_RUN,
  GET_ALL_RESULTS,
  GET_RUN_TEST_CASE_DETAILS,
  GET_RUN_TEST_CASE_STEPS_DETAILS,
  FETCH_SCHEDULE_DETAILS_FAILURE,
  FETCH_SCHEDULE_DETAILS_REQUEST,
  FETCH_SCHEDULE_DETAILS_SUCCESS,
} from "../../actions/AdvanceAutomation/JourneyAction";

const initialState = {
  listData: [],
  isLoading: false,
  error: null,
  selectedNodeId: null,
  selectedNode: null,
  expanded: [],
  siteData:[],
  environementList: [],
  journeyList: [],
  journeyDetails:{},
  journeys: [],
  environmentDetails: [],
  journey_id: null,
  runsList: [],
  runDetails: [],
  executedRun: [],
  allResults: [],
  runTestCaseDetails: [],
  runTestCaseStepsDetails: [],
  scheduleDetails: null,
  loading: false,
  error: null,
};

const journeyReduer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DATA_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case FETCH_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listData: action.payload,
        error: null,
      };
    case FETCH_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case ADD_WORKSPACE: {
      return {
        ...state,
        listData: [...state.listData, action.payload],
      };
    }
    case UPDATE_WORKSPACE: {
      const newNode = action.payload;

      // Map over the listData array to update the specific node
      const updatedListData = state.listData.map((node) =>
        node.id === newNode.id ? newNode : node
      );

      return {
        ...state,
        listData: updatedListData,
      };
    }
    case DELETE_WORKSPACE: {
      const id = action.payload;

      // Map over the listData array to update the specific node
      const updatedListData = state.listData.filter((node) => node.id !== id);

      return {
        ...state,
        listData: updatedListData,
      };
    }
    case SET_ROOT_ID: {
      return {
        ...state,
        selectedNodeId: action.payload,
      };
    }
    // case SET_SELECTED_NODE: {
    //   return {
    //     ...state,
    //     selectedNode: state.listData.find(
    //       (data) => data.id === state.selectedNodeId
    //     ),
    //   };
    // }
    case SET_SELECTED_NODE: { 
      return {
        ...state,
        selectedNode: Array.isArray(state?.listData)
          ? state.listData.find((data) => data?.id === state?.selectedNodeId)
          : null,  // Handle case where listData is not an array
      };
    }
    case SET_EXPANDED_NODE: {
      const id = action.payload;
      // following comment will open only one work space
      // const expandedItem = state.listData.find((data)=>data.id === id)
      // if(expandedItem.parentId === 0){
      //   return{
      //     ...state,
      //     expanded:[id]
      //   }
      // }
      const index = state.expanded?.indexOf(id);
      if (index === -1) {
        return {
          ...state,
          expanded: [...(state.expanded || []), id],
        };
      } else {
        const newExpanded = [
          ...state.expanded.slice(0, index),
          ...state.expanded.slice(index + 1),
        ];
        return {
          ...state,
          expanded: newExpanded,
        };
      }
    }
    case EXPAND_PARENT: {
      const id = action.payload;
      if (!state.expanded.includes(id)) {
        return {
          ...state,
          expanded: [...state.expanded, id],
        };
      }
      return state;
    }
    case SITE_DATA: {
      return {
        ...state,
        siteData: action.payload
        }
    }
    case JOURNEY_DETAILS: {
      return {
        ...state,
        journeyDetails: action.payload
      }
    }
    case JOURNEY_ID: {
      return {
        ...state,
        journey_id: action.payload
      }
    }
    case FETCH_JOURNEY_SUCCESS: {
      return {
        ...state,
        journeys: action.payload
      }
    }
    case CLEAR_JOURNEY_LIST: {
      return {
        ...state,
        journeys: action.payload,
      };
    }
    case GET_ENVIRONMENT_LIST: {
      return {
        ...state,
        environementList: action.payload,
      };
    }
    case GET_ENVIRONMENT_DETAILS:
      return {
        ...state,
        environmentDetails: action.payload,
    };
    case GET_RUNS_LIST: {
      return {
        ...state,
        runsList: action.payload,
      };
    }
    case GET_RUNS_DETAILS: {
      return {
        ...state,
        runDetails: action.payload,
      };
    }
    case EXECUTE_RUN: {
      return {
        ...state,
        executedRun: action.payload,
      };
    }
    case GET_ALL_RESULTS: {
      return {
        ...state,
        allResults: action.payload,
      };
    }
    case GET_RUN_TEST_CASE_DETAILS: {
      return {
        ...state,
        runTestCaseDetails: action.payload,
      };
    }
    case GET_RUN_TEST_CASE_STEPS_DETAILS: {
      return {
        ...state,
        runTestCaseStepsDetails: action.payload,
      };
    }
    case FETCH_SCHEDULE_DETAILS_REQUEST:
      return { ...state, loading: true };
    case FETCH_SCHEDULE_DETAILS_SUCCESS:
      return { ...state, loading: false, scheduleDetails: action.payload };
    case FETCH_SCHEDULE_DETAILS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
export default journeyReduer;
