import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { List, ListItem, ListItemIcon, ListItemText, Card } from "@mui/material";
import { useStyles } from "./styles";
import { DashBoardIcon, AuditIcon } from "../comman/icons";

const Paths = [
  {
    title: "Dashboard",
    icon: <DashBoardIcon />,
    path: "/Accessibility",
  },
  {
    title: "Audit",
    icon: <AuditIcon />,
    path: "/Accessibility/audit",
  },
];

export default function AccessibilityNavBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();

  return (
    <Card className={classes.navCard}>
        <List sx={{justifyItems:'center',  padding:'0px', margin:'0px 8px'}}>
          {Paths.map((path, index) => {
            const isActive =
              path.path === "/Accessibility"
                ? location.pathname === "/Accessibility"
                : location.pathname.startsWith(path.path);

            return (
              <ListItem
                className={classes.navListItem}
                sx={{
                  backgroundColor: isActive ? "rgb(101, 77, 247)" : null,
                  cursor: 'pointer',
                  padding:"10px",
                  marginLeft:'8px'
                }}
                key={index}
                onClick={() => {
                  navigate(path.path);
                }}
              >
                <ListItemIcon className={classes.icon} style={{ minWidth: 0 }}>
                  {React.cloneElement(path.icon, {
                    color: isActive ? "white" : "rgb(101, 77, 247)",
                    isActive: isActive,
                  })}
                </ListItemIcon>
                <ListItemText
                  primary={path.title}
                  className={`${classes.navTitle} ${isActive ? classes.activeClass : ""}`}
                />
              </ListItem>
            );
          })}
        </List>
    </Card>
  );
}