import { toast } from "react-toastify";
import { getBaseUrl } from "../../../utils/configService";
import axios from "axios";
import { header } from "../../../utils/authheader";

export const FETCH_REQUEST = "FETCH_REQUEST";
export const FETCH_FAILURE = "FETCH_FAILURE";
export const FETCH_EXECUTION_HISTORY_SUCCESS =
  "FETCH_EXECUTION_HISTORY_SUCCESS";
export const FETCH_STEP_DETAILS_SUCCESS = "FETCH_STEP_DETAILS_SUCCESS";
export const RESET_TEST_RUN_DETAILS_LIST = "RESET_TEST_RUN_DETAILS_LIST";
export const GET_TEST_RUN_DETAILS_LIST = "GET_TEST_RUN_DETAILS_LIST";
export const GET_TEST_RUN_EXECUTION_HISTORY = "GET_TEST_RUN_EXECUTION_HISTORY";

export const getExecutionHistory = (testId) => {
  return async (dispatch, getState) => {
    dispatch({ type: "FETCH_REQUEST" });
    try {
      const BASE_URL = await getBaseUrl();
      const res = await axios.get(
        `${BASE_URL}/AddTestLab/GetTestDetailByTestName?TestId=${testId}`
      );
      if(res.data?.status && res.data.status.trim().toLowerCase() == "success"){
        const data = JSON.parse(res.data.data)
        if (Array.isArray(data)) {
          dispatch({
            type: "FETCH_EXECUTION_HISTORY_SUCCESS",
            payload: data,
          });
        } else {
          dispatch({ type: "FETCH_EXECUTION_HISTORY_SUCCESS", payload: null });
        }
      }
    } catch (error) {
      dispatch({ type: "FETCH_FAILURE", payload: "NETWORK ERROR" });
     // toast.error(error.response?.data?.message || "NETWORK ERROR");
    }
  };
};

export const fetchStepDetails =
  (selectedRunId) => async (dispatch, getState) => {
    try {
      if (!selectedRunId) {
        return; // No need to fetch if selectedRunId is null or undefined
      }
      dispatch({ type: "FETCH_REQUEST" });
      const BASE_URL = await getBaseUrl();
      const res = await axios.get(
        `${BASE_URL}/AddTestLab/GetTestStepsDetailByTestCaseId?TestCaseId=${selectedRunId}`,
        header()
      );

      if(res.data?.status && res.data.status.trim().toLowerCase() == "success"){
        const data = JSON.parse(res.data.data)
        if (Array.isArray(data)) {
          dispatch({ type: "FETCH_STEP_DETAILS_SUCCESS", payload: data });
        } else {
          dispatch({
            type: "FETCH_STEP_DETAILS_FAILURE",
            payload: "Invalid data received",
          });
        }
      }
    } catch (error) {
      dispatch({
        type: "FETCH_STEP_DETAILS_FAILURE",
        payload: error.response?.data?.message || "NETWORK ERROR"
      });
     // toast.error(error.response?.data?.message || "NETWORK ERROR");
    }
  };

export const getTestLabTestCaseRundetailsByTestName = (
  rootId,
  setInProgress
) => {
  return async (dispatch) => {
    setInProgress(true);
    dispatch({
      type: RESET_TEST_RUN_DETAILS_LIST,
    });
    try {
      const BASE_URL = await getBaseUrl();
      const response = await axios.get(
        `${BASE_URL}/AddTestLab/GetTestLabHistory?RootId=${rootId}&moduleType=GhostLab`,
        header()
      );
      const data = response.data?.data ? JSON.parse(response.data.data).reverse():[];
      dispatch({
        type: GET_TEST_RUN_DETAILS_LIST,
        payload: data,
      });
      setInProgress(false);
    } catch (error) {
      setInProgress(false);
      console.error(error);
     // toast.error(error.response?.data?.message || "NETWORK ERROR");
    }
  };
};

export const getTestRunExecutionHistory = (data) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_REQUEST }); // Start loader

    try {
      const BASE_URL = await getBaseUrl();
      const response = await axios.get(
        `${BASE_URL}/AddTestLab/GetTestRunResultDetails?TestSuiteName=${
          data.testSuitName
        }&TestRunId=${data.runId}&RootId=${
          data.RootId !== undefined ? data.RootId : 0
        }&filterDate=${
          data.TestRunStartDate !== undefined ? data.TestRunStartDate : 0
        }&testRunNameId=${
          data.TestRunNameId !== undefined ? data.TestRunNameId : 0
        }&moduleType=${
          data.moduleType
        }`,
        header()
      );
      if(response.data?.status && response.data.status.trim().toLowerCase() === "success"){
        const data = JSON.parse(response.data.data)
        dispatch({
          type: GET_TEST_RUN_EXECUTION_HISTORY,
          payload: data,
        });
      }
    } catch (error) {
      console.error(error);
     // toast.error(error.response?.data?.message || "NETWORK ERROR");
    } finally {
      dispatch({ type: FETCH_FAILURE }); // End loader
    }
  };
}
