import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Card,
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Popover,
  Button,
  CircularProgress
} from "@mui/material";
import clsx from "clsx";
import { useStylesTestCase } from "../styles";
import { useSelector, useDispatch } from "react-redux";
import { fetchSiteById } from "../../../../redux/actions/AdvanceAutomation/JourneyAction";
import { JourneyTable } from "./JourneyTable";
import {
  CompleteIcon,
  Indication,
} from "../../../../comman/icons";
import {
  fetchJourneysByRootId,
  startJourney,
  clearJourneyList,
} from "../../../../redux/actions/AdvanceAutomation/JourneyAction";
import UpgradeModal from "../../../../comman/Accordion/UpgradePlan/UpgradeModal";



export default function JourneyList({ depth }) {
  const classes = useStylesTestCase();
  const dispatch = useDispatch();
  const { selectedNodeId, selectedNode, siteData } = useSelector(
    (state) => state.journey
  );
  const { userId } = useSelector((state) => state.auth);
  const { journeys } = useSelector((state) => state.journey);

  const [loading, setLoading] = useState(false);
  const [loadingCircular, setLoadingCircular] = useState(true);
  const [completed, setCompleted] = useState(false);
  const [showTable, setShowTable] = useState(true);
  const [showJourneyTable, setShowJourneyTable] = useState(false);
  const [shouldFetchJourneys, setShouldFetchJourneys] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoadingCircular(true);
      dispatch(clearJourneyList());
      await dispatch(fetchSiteById(selectedNodeId));
      setShouldFetchJourneys(true);
      setLoadingCircular(false);
    };

    fetchData();
    setShouldFetchJourneys(false);
  }, [selectedNodeId, dispatch]);

  useEffect(() => {
    if (shouldFetchJourneys && siteData?.rootId) {
      setLoadingCircular(true);
      const timer = setTimeout(async () => {
        await dispatch(fetchJourneysByRootId(siteData.id));
        setLoadingCircular(false);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [shouldFetchJourneys, siteData?.rootId, dispatch]);

  useEffect(() => {
        if (journeys.length !== 0) {
          setShowJourneyTable(true);
        }
  }, [journeys]);

  const handleStartJourney = async () => {
    setLoading(true);
    setCompleted(false);
    setShowTable(false);

    const postData = {
      crawlDetails: {
        start_url: siteData?.url,
        max_depth: depth,
      },
      userId: userId,
      rootId: siteData?.id,
    };
    // Post the data to the API
    try {
      const message = await dispatch(startJourney(postData));
      setLoading(false);
      if (JSON.parse(message).message === "You’ve reached the maximum number of journeys for the current month! Please upgrade to access more journey slots.") { 
        setUpgradeModalOpen(true); 
      } else {
        setCompleted(true);
        // After showing completed state for some time, reset
        setTimeout(() => {
          setCompleted(false);
          setShowTable(true);
        }, 5000);
      }
    } catch (error) {
      console.error("Error starting journey:", error);
      setLoading(false);
    }
  };

  const open = Boolean(anchorEl);

  const testData = [
    { name: "URL", value: siteData?.url || "N/A" },
    { name: "Username/Email", value: siteData?.email || "N/A" },
    { name: "Password", value: siteData?.password || "N/A" },
  ];
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
    {loadingCircular ? (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
       <CircularProgress size="3rem" sx={{ color: 'rgb(101, 77, 247)' }}/>
     </Box>
    ) : (
      <>
      <UpgradeModal open={upgradeModalOpen} onClose={() => setUpgradeModalOpen(false)} />
      {!loading && !completed && (
        <Grid
          container
          className={classes.Journeyheader}
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid
            container
            direction="row"
            xs={6}
            className={`${classes.Journeyheader}`}
            sx={{paddingLeft:'15px'}}
          >
            <Typography
              className={clsx(classes.customFontSize, classes.highlight)}
            >
              {selectedNode?.name.length > 40
                ? selectedNode?.name.slice(0, 40) + "..."
                : selectedNode?.name}
            </Typography>
            <Box
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
              sx={{ cursor: "pointer", paddingLeft:'5px' }} 
            >
              <Indication />
              <Popover
                id="mouse-over-popover"
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                onClose={handlePopoverClose} 
                disableRestoreFocus
              >
                <Card>
                  <Table>
                    <TableBody>
                      {testData.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell>{row.name}</TableCell>
                          <TableCell>{row.value}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Card>
              </Popover>
            </Box>
          </Grid>
          <Grid item>
            <Button
              className={classes.button}
              sx={{
                backgroundColor: "rgb(101, 77, 247)",
                "&:hover": {
                  backgroundColor: "rgb(101, 77, 247) !important",
                  borderColor: "#654DF7",
                  color: "#fff",
                  "&:before": {
                    backgroundColor: "rgb(101, 77, 247) !important",
                    color: "#fff",
                  },
                },
                color: "#fff",
              }}
              onClick={handleStartJourney}
            >
              Start Journey
            </Button>
          </Grid>
        </Grid>
      )}
      <Grid container justifyContent="center" alignItems="center" spacing={2}>
        <Grid item xs={12}>
          {loading && (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              {/* <LoaderIcon /> */}
              <Box
                component="img"
                src="/images/loader-main.gif"
                alt="Loading GIF"
                sx={{ width: "200px", height: "200px" }}
              />
              <Box
                mt={1}
                sx={{ color: "#646464", fontSize: "30px", fontWeight: "500" }}
              >
                Your Journey has started
              </Box>
              <Box
                mt={1}
                sx={{ color: "#646464", fontSize: "22px", fontWeight: "500" }}
              >
                Please wait for few minutes
              </Box>
            </Box>
          )}
          {completed && (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <CompleteIcon />
              <Box
                mt={1}
                sx={{ color: "#646464", fontSize: "30px", fontWeight: "500" }}
              >
                Journey is created
              </Box>
              <Box
                mt={1}
                sx={{ color: "#646464", fontSize: "22px", fontWeight: "500" }}
              >
                Now you can check result
              </Box>
            </Box>
          )}
          {!loading && !completed && showTable && (
            <Grid item>
              {showJourneyTable && (journeys.length !== 0) ? (
                <JourneyTable rows={journeys} rootId={siteData.rootId} />
              ) : (
                <>
                  <Grid item xs={6}>
                    <Card style={{ textAlign: "center" }}>
                      <Table>
                        <TableBody>
                          {testData.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell>{row.name}</TableCell>
                              <TableCell>{row.value}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Card>
                  </Grid>
                </>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
      </>
    )}
    </>
  );
}
