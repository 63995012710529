import {
  SET_EXPANDED_NODE,
  EXPAND_PARENT,
  SET_ROOT_ID,
  SET_SELECTED_NODE,
  SCHEDULE_SUITE,
  SET_PARENT_ID,
  SET_NODE_COUNT,
  EXECUTING_SUITE,
  SET_SELECTED_TAB,
  FETCH_CHART_DATA_REQUEST,
  FETCH_CHART_DATA_SUCCESS,
  FETCH_CHART_DATA_FAILURE,
} from "../../actions/testlab/testlabAction";

const initialState = {
  selectedNode:null,
  selectedNodeId: 0,
  expanded: [],
  isScheduling:false,
  parentId: null,
  currentNodeCount:0,
  executingSuite:[],
  selectedTab: 'Design',
  loading: false,
  categories: [],
  series: [],
  error: null,
};

const testlabReduer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PARENT_ID:
      return {
        ...state,
        parentId: action.payload,
      }
    case SET_SELECTED_NODE:{
      return {
        ...state,
        selectedNode:action.payload
      }
    }
    case SET_ROOT_ID: {
      return {
        ...state,
        selectedNodeId: action.payload,
      };
    }
    case SET_EXPANDED_NODE: {
      const id = action.payload;
      // following comment will open only one work space
      // const expandedItem = state.listData.find((data)=>data.id === id)
      // if(expandedItem.parentId === 0){
      //   return{
      //     ...state,
      //     expanded:[id]
      //   }
      // }
      const index = state.expanded?.indexOf(id);
      if (index === -1) {
        return {
          ...state,
          expanded: [...(state.expanded || []), id],
        };
      } else {
        const newExpanded = [
          ...state.expanded.slice(0, index),
          ...state.expanded.slice(index + 1),
        ];
        return {
          ...state,
          expanded: newExpanded,
        };
      }
    }
    case EXPAND_PARENT: {
      const id = action.payload;
      const index = state.expanded?.indexOf(id);
      if (index === -1) {
        return {
          ...state,
          expanded: [...(state.expanded || []), id],
        };
      }
      return state;
    }
    case SCHEDULE_SUITE: {
      return {
        ...state,
        isScheduling: action.payload,
      };
    }
    case SET_NODE_COUNT: {
      return {
        ...state,
        currentNodeCount: action.payload,
      };
    }
    case EXECUTING_SUITE: {
      return {
        ...state,
        executingSuite: action.payload,
      };
    }
    case SET_SELECTED_TAB: {
      return {
        ...state,
        selectedTab: action.payload,
      };
    }
    case FETCH_CHART_DATA_REQUEST: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case FETCH_CHART_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        categories: action.payload.categories,
        series: action.payload.series,
      };
    }
    case FETCH_CHART_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    default:
      return state;
  }
};
export default testlabReduer;