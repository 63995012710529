import React, { useEffect, useState } from "react";
import { StyledTypography, StyledTableCell, useStyles } from "./styleTestCase";
import {
  Box,
  Grid,
  Modal,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import VideocamIcon from "@mui/icons-material/Videocam";
import { useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { getBaseUrl } from "../../../../utils/configService";
import CustomStatusCell from "./CustomStatusCell";
import Rundetail from "./rundetails";
import { header } from "../../../../utils/authheader";
import AccessibilityViolationsModal from "../../Comman/AccessibilityViolationsModal";

export default function ExecutionHistory({ executionDetail }) {
  const classes = useStyles();
  const [selectedRunId, setSelectedRunId] = useState(null);
  const [videoLink, setvideoLink] = useState("");
  const [runIdDetails, setrunIdDetails] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [accessibilityModalOpen, setAccessibilityModalOpen] = useState(false);
  const [accessibilityDetails, setAccessibilityDetails] = useState([]);
  const [activeCard, setActiveCard] = useState(null);
  const getStpeDetail = async () => {
    try {
      if (selectedRunId === null || selectedRunId === undefined) {
        return;
      }
      const BASE_URL = await getBaseUrl();
      const res = await axios.get(
        `${BASE_URL}/AddTestLab/GetTestStepsDetailByTestCaseId?TestCaseId=${selectedRunId}`,
        header()
      );
      if(res.data?.status && res.data.status.trim().toLowerCase() === "success"){
        const data = JSON.parse(res.data.data);
        if (Array.isArray(data)) {
          setrunIdDetails(data);
        } else {
          setrunIdDetails(null);
        }
      }
    } catch (error) {
      //// toast.error(error.response?.data?.message || "NETWORK ERROR");
    }
  };

  useEffect(() => {
    if (selectedRunId) getStpeDetail();
  }, [selectedRunId]);

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleVideoPlay = (vdUrl) => {
    setvideoLink(vdUrl);
    setOpenModal(true);
  };

  const handleAccessibilityClick = (details) => {
    setAccessibilityDetails(details.violations);
    setAccessibilityModalOpen(true);
  };

  const handleCloseAccessibilityModal = () => {
    setAccessibilityModalOpen(false);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "numeric",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="image-modal-title"
        aria-describedby="image-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            borderRadius: "8px",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              maxWidth: "60vw",
              position: "relative",
            }}
          >
            <video
              autoPlay
              muted
              controls
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
            >
              <source src={videoLink} type="video/webm" />
            </video>
            <Box
              onClick={handleCloseModal}
              sx={{
                cursor: "pointer",
                position: "absolute",
                top: "10px",
                right: "10px",
                height: "20px",
                color: "rgb(25, 118, 210)",
              }}
            >
              x
            </Box>
          </Box>
        </div>
      </Modal>
      <Grid item xs={12} mt={2}>
        <StyledTypography sx={{ fontSize: "18px", fontWeight: "400" }}>
          Execution history
        </StyledTypography>
      </Grid>
      <Grid item xs={12} md={7}>
        <Box sx={{ border: "1px solid rgb(219, 217, 217)", height: "calc(100vh - 550px)", overflow: "auto"  }}>
          <TableContainer sx={{ marginBottom: "8vh" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Run Id </StyledTableCell>
                  <StyledTableCell>Start Time</StyledTableCell>
                  <StyledTableCell>End Time</StyledTableCell>
                  <StyledTableCell>Status</StyledTableCell>
                  <StyledTableCell>Video</StyledTableCell>
                  <StyledTableCell>Accessibility Result</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {executionDetail ? (
                  executionDetail.map((row) => (
                    <TableRow
                      key={row.TestCase}
                      className={`${classes.tableRow} ${
                        selectedRunId === row.TestCase ? classes.activeRow : ""
                      }`}
                      onClick={() => setSelectedRunId(row.TestCase)}
                    >
                      <StyledTableCell
                        sx={{
                          color:
                            selectedRunId === row.TestCase
                              ? "white"
                              : "#654DF7",
                        }}
                      >
                        {row.TestCase}
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{
                          color:
                            selectedRunId === row.TestCase ? "white" : "black",
                        }}
                      >
                        {formatDate(row.StartDateTime)}
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{
                          color:
                            selectedRunId === row.TestCase ? "white" : "black",
                        }}
                      >
                        {formatDate(row.EndDateTime)}
                      </StyledTableCell>
                      <CustomStatusCell
                        status={row.Status}
                        selected={selectedRunId === row.TestCase}
                      />
                      <StyledTableCell
                        sx={{
                          color:
                            selectedRunId === row.TestCase
                              ? "white"
                              : "#654DF7",
                        }}
                        onClick={() => {}}
                      >
                        <VideocamIcon
                          onClick={() => handleVideoPlay(row.TestVideoUrl)}
                        />
                      </StyledTableCell>                      
                      <StyledTableCell
                        sx={{
                          color: selectedRunId === row.TestCase ? "white" : "black",
                        }}
                      >
                        <img
                          src={
                            selectedRunId === row.TestCase
                              ? "/reportIconWhite.png"
                              : "/reportIcon.png"
                          }
                          alt="Report Icon"
                          style={{
                            cursor:
                              row?.A11yViolations?.violations &&
                              row.A11yViolations.violations.length > 0
                                ? "pointer"
                                : "not-allowed",
                            width: "24px",
                            height: "24px",
                            opacity:
                              row?.A11yViolations?.violations &&
                              row.A11yViolations.violations.length > 0
                                ? 1
                                : 0.5,
                          }}
                          onClick={
                            row?.A11yViolations?.violations &&
                            row.A11yViolations.violations.length > 0
                              ? () => handleAccessibilityClick(row.A11yViolations)
                              : undefined
                          }
                        />
                      </StyledTableCell>

                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <StyledTableCell colSpan={6} align="center">
                      No execution history
                    </StyledTableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Grid>
      <Grid item xs={12} md={5} justifySelf="start">
        {selectedRunId && (
          <Box sx={{ border: "1px solid rgb(219, 217, 217)" }}>
            <TableContainer sx={{ marginBottom: "8vh" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell colSpan={4}>
                      <StyledTypography variant="h6" color="primary">
                        {selectedRunId}
                      </StyledTypography>
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <Rundetail runIdDetails={runIdDetails} />
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </Grid>

      <AccessibilityViolationsModal
        isOpen={accessibilityModalOpen}
        onClose={handleCloseAccessibilityModal}
        data={accessibilityDetails}
      />
    </>
  );
}
