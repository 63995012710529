import {
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
} from "@mui/material";
import React, { useState } from "react";
import {
    StyledFormControl,
    StyledOutlinedInput,
    StyledTextField,
    useStyles
} from "./styleTestCase";
import { keyList, accessibilityList, users } from "../Comman/DropDownOptions";
import Select from "react-select";
import { testCases } from "../Comman/DropDownOptions";
import CustomFileInput from './CustomFileInput'; // Adjust the import based on your file structure

export default function RenderActionFields({
    action,
    step,
    index,
    Errors,
    handleInputChange,
    isEditable,
}) {
    const classes = useStyles()

    switch (action) {
        case "scroll_to_window":
            const [xValue, yValue] = step?.scrollPixel
            ? step.scrollPixel
                .replace(/[()]/g, "")
                .split(",")
                .map((v) => v.trim())
            : ["", ""];
          return (
            <>
              <Grid item xs={6}>
                <StyledFormControl>
                  <StyledOutlinedInput
                    type="text"
                    placeholder="X Axis"
                    disabled={!isEditable}
                    value={xValue}
                    onChange={(e) => {
                      const newX = e.target.value;
                      const newY = yValue || "0";
                      const formattedValue = `(${newX},${newY})`;
                      handleInputChange(
                        {
                          target: { value: formattedValue },
                        },
                        index,
                        "scrollPixel"
                      );
                    }}
                  />
                  {Errors[index]?.scrollPixelError && (
                    <span className={classes.errorAsterisk}>*</span>
                  )}
                </StyledFormControl>
              </Grid>
              <Grid item xs={6}>
                <StyledFormControl>
                  <StyledOutlinedInput
                    type="text"
                    placeholder="Y Axis"
                    disabled={!isEditable}
                    value={yValue}
                    onChange={(e) => {
                      const newY = e.target.value;
                      const newX = xValue || "0";
                      const formattedValue = `(${newX},${newY})`;
                      handleInputChange(
                        {
                          target: { value: formattedValue },
                        },
                        index,
                        "scrollPixel"
                      );
                    }}
                  />
                  {Errors[index]?.scrollPixelError && (
                    <span className={classes.errorAsterisk}>*</span>
                  )}
                </StyledFormControl>
              </Grid>
            </>
     
            );
         case "have_css_value":
            return (
                <>
                    <Grid item xs={6}>
                        <StyledFormControl>
                            <StyledOutlinedInput
                                type="text"
                                placeholder="Css Property"
                                disabled={!isEditable}
                                value={step?.cssProperty}
                                onChange={(e) => {
                                    handleInputChange(e, index, "cssProperty");
                                }}
                            />
                            {Errors[index]?.cssPropertyError && (
                                <span className={classes.errorAsterisk}>*</span>
                            )}
                        </StyledFormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <StyledFormControl>
                            <StyledOutlinedInput
                                type="text"
                                placeholder="Css Value"
                                disabled={!isEditable}
                                value={step?.cssValue}
                                onChange={(e) => {
                                    handleInputChange(e, index, "cssValue");
                                }}
                            />
                            {Errors[index]?.cssValueError && (
                                <span className={classes.errorAsterisk}>*</span>
                            )}
                        </StyledFormControl>
                    </Grid>
                </>
            );
        case "have_prop":
            return (
                <>
                    <Grid item xs={6}>
                        <StyledFormControl>
                            <StyledOutlinedInput
                                type="text"
                                placeholder="Prop Property"
                                disabled={!isEditable}
                                value={step?.cssProperty}
                                onChange={(e) => {
                                    handleInputChange(e, index, "cssProperty");
                                }}
                            />
                            {Errors[index]?.cssPropertyError && (
                                <span className={classes.errorAsterisk}>*</span>
                            )}
                        </StyledFormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <StyledFormControl>
                            <StyledOutlinedInput
                                type="text"
                                placeholder="Prop Value"
                                disabled={!isEditable}
                                value={step?.cssValue}
                                onChange={(e) => {
                                    handleInputChange(e, index, "cssValue");
                                }}
                            />
                            {Errors[index]?.cssValueError && (
                                <span className={classes.errorAsterisk}>*</span>
                            )}
                        </StyledFormControl>
                    </Grid>
                </>
            );
        case "element_has_attribute_with_value":
            return (
                <>
                    <Grid item xs={6}>
                        <StyledFormControl>
                            <StyledOutlinedInput
                                type="text"
                                placeholder="Element"
                                disabled={!isEditable}
                                value={step?.haveAttributeValue}
                                onChange={(e) => {
                                    handleInputChange(e, index, "haveAttributeValue");
                                }}
                            />
                            {Errors[index]?.haveAttributeValueError && (
                                <span className={classes.errorAsterisk}>*</span>
                            )}
                        </StyledFormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <StyledFormControl>
                            <StyledOutlinedInput
                                type="text"
                                placeholder="Attribute"
                                disabled={!isEditable}
                                value={step?.textValue}
                                onChange={(e) => {
                                    handleInputChange({ value: e.target.value }, index, "textValue");
                                }}
                            />
                            {Errors[index]?.textvalueError && (
                                <span className={classes.errorAsterisk}>*</span>
                            )}
                        </StyledFormControl>
                    </Grid>
                </>
            );
        case "go_to_url":
            return (
                <Grid item xs={6}>
                    <StyledFormControl>
                        <StyledOutlinedInput
                            type="text"
                            placeholder="URL"
                            disabled={!isEditable}
                            // error={Errors[index]?.urlError}
                            value={step?.url}
                            onChange={(e) => {
                                handleInputChange(e, index, "url");
                            }}
                        />
                        {Errors[index]?.urlError && (
                            <span className={classes.errorAsterisk}>*</span>
                        )}
                    </StyledFormControl>
                </Grid>
            );
        case "number_of_element_found":
            return (
                <Grid item xs={6}>
                    <StyledFormControl>
                        <input
                            type="number"
                            placeholder="Enter a number"
                            min="0"
                            disabled={!isEditable}
                            value={step?.numberOfElementFound}
                            onChange={(e) => {
                                handleInputChange(e, index, "number_of_element_found");
                            }}
                            style={{
                                width: '100%',
                                padding: '8px 14px', 
                                border: '1px solid #ccc', 
                                borderRadius: '4px', 
                                backgroundColor: 'white', 
                                outline: 'none', 
                            }}
                        />    
                        {Errors[index]?.number_of_element_foundError && (
                            <span className={classes.errorAsterisk}>*</span>
                        )}
                    </StyledFormControl>
                </Grid>
            );
        case "select_option":
            return (
                <Grid item xs={6}>
                    <StyledFormControl>
                        <StyledOutlinedInput
                            type="text"
                            placeholder="Input field"
                            disabled={!isEditable}
                            // error={Errors[index]?.selectedUserError}
                            value={step?.selectedUser}
                            onChange={(e) => {
                                handleInputChange(e, index, "selectedUser");
                            }}
                        />
                        {Errors[index]?.selectedUserError && (
                            <span className={classes.errorAsterisk}>*</span>
                        )}
                    </StyledFormControl>
                </Grid>
            );
        case "upload_file":
            return (
                <Grid item xs={6}>
                    <CustomFileInput
                        fileName={step?.fileName}
                        onFileChange={async (e) => {
                            const file = e.target.files[0];
                            if (file) {
                                const reader = new FileReader();
                                reader.onloadend = () => {
                                    const base64String = reader.result.split(',')[1]; // Get the Base64 string
                                    handleInputChange({ target: { value: file.name } }, index, "fileName");
                                    handleInputChange({ target: { value: base64String } }, index, "fileBase64");
                                };
                                reader.readAsDataURL(file); // Convert file to Base64
                            }
                        }}
                        disabled={!isEditable}
                    />
                    {Errors[index]?.fileNameError && (
                        <span className={classes.errorAsterisk}>*</span>
                    )}
                </Grid>
            );
        case "element_has_value":
            return (
                <Grid item xs={6}>
                    <StyledFormControl>
                        <StyledOutlinedInput
                            type="text"
                            placeholder="Input value"
                            disabled={!isEditable}
                            value={step?.elementValue}
                            // error={Errors[index]?.elementValueError}
                            onChange={(e) => {
                                handleInputChange(e, index, "elementValue");
                            }}
                        />
                        {Errors[index]?.elementValueError && (
                            <span className={classes.errorAsterisk}>*</span>
                        )}
                    </StyledFormControl>
                </Grid>
            );
        case "element_has_css_property_with_value":
            return (
                <>
                    <Grid item xs={6}>
                        <StyledFormControl>
                            <StyledOutlinedInput
                                type="text"
                                placeholder="css property"
                                disabled={!isEditable}
                                value={step?.cssProperty}
                                // error={Errors[index]?.cssPropertyError}
                                onChange={(e) => {
                                    handleInputChange(e, index, "cssProperty");
                                }}
                            />
                            {Errors[index]?.cssPropertyError && (
                                <span className={classes.errorAsterisk}>*</span>
                            )}
                        </StyledFormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <StyledFormControl>
                            <StyledOutlinedInput
                                type="text"
                                placeholder="css value"
                                disabled={!isEditable}
                                value={step?.cssValue}
                                // error={Errors[index]?.cssValueError}
                                onChange={(e) => {
                                    handleInputChange(e, index, "cssValue");
                                }}
                            />
                            {Errors[index]?.cssValueError && (
                                <span className={classes.errorAsterisk}>*</span>
                            )}
                        </StyledFormControl>
                    </Grid>
                </>
            );
        case "validate_page_title":
            return (
                <Grid item xs={6}>
                    <StyledFormControl>
                        <StyledOutlinedInput
                            type="text"
                            placeholder="Page title"
                            disabled={!isEditable}
                            // error={Errors[index]?.pageTitleError}
                            value={step?.pageTitle}
                            onChange={(e) => {
                                handleInputChange(e, index, "pageTitle");
                            }}
                        />
                        {Errors[index]?.pageTitleError && (
                            <span className={classes.errorAsterisk}>*</span>
                        )}
                    </StyledFormControl>
                </Grid>
            );
        case "validate_current_url":
            return (
                <Grid item xs={6}>
                    <StyledFormControl>
                        <StyledOutlinedInput
                            type="text"
                            placeholder="Current url"
                            value={step?.currentUrl}
                            disabled={!isEditable}
                            // error={Errors[index]?.currentUrlError}
                            onChange={(e) => {
                                handleInputChange(e, index, "currentUrl");
                            }}
                        />
                        {Errors[index]?.currentUrlError && (
                            <span className={classes.errorAsterisk}>*</span>
                        )}
                    </StyledFormControl>
                </Grid>
            );
        case "should_not_equal":
            return (
                <Grid item xs={6}>
                    <StyledFormControl>
                        <StyledOutlinedInput
                            type="text"
                            placeholder="input value"
                            disabled={!isEditable}
                            // error={Errors[index]?.shouldNotEqualError}
                            value={step?.shouldNotEqualValue}
                            onChange={(e) => {
                                handleInputChange(e, index, "shouldNotEqualValue");
                            }}
                        />
                        {Errors[index]?.shouldNotEqualError && (
                            <span className={classes.errorAsterisk}>*</span>
                        )}
                    </StyledFormControl>
                </Grid>
            );
        case "should_include":
            return (
                <Grid item xs={6}>
                    <StyledFormControl>
                        <StyledOutlinedInput
                            type="text"
                            placeholder="input value"
                            disabled={!isEditable}
                            // error={Errors[index]?.shouldIncludeError}
                            value={step?.shouldIncludeValue}
                            onChange={(e) => {
                                handleInputChange(e, index, "shouldIncludeValue");
                            }}
                        />
                        {Errors[index]?.shouldIncludeError && (
                            <span className={classes.errorAsterisk}>*</span>
                        )}
                    </StyledFormControl>
                </Grid>
            );
        case "should_equal":
            return (
                <Grid item xs={6}>
                    <StyledFormControl>
                        <StyledOutlinedInput
                            type="text"
                            placeholder="input value"
                            disabled={!isEditable}
                            // error={Errors[index]?.shouldEqualError}
                            value={step?.shouldEqualValue}
                            onChange={(e) => {
                                handleInputChange(e, index, "shouldEqualValue");
                            }}
                        />
                        {Errors[index]?.shouldEqualError && (
                            <span className={classes.errorAsterisk}>*</span>
                        )}
                    </StyledFormControl>
                </Grid>
            );
        case "should_be_greater_than":
            return (
                <Grid item xs={6}>
                    <StyledFormControl>
                        <StyledOutlinedInput
                            type="text"
                            placeholder="input value"
                            disabled={!isEditable}
                            // error={Errors[index]?.shouldGreaterThanError}
                            value={step?.shouldGreaterThanValue}
                            onChange={(e) => {
                                handleInputChange(e, index, "shouldGreaterThanValue");
                            }}
                        />
                        {Errors[index]?.shouldGreaterThanError && (
                            <span className={classes.errorAsterisk}>*</span>
                        )}
                    </StyledFormControl>
                </Grid>
            );
        case "should_be_less_than":
            return (
                <Grid item xs={6}>
                    <StyledFormControl>
                        <StyledOutlinedInput
                            type="text"
                            placeholder="input value"
                            disabled={!isEditable}
                            // error={Errors[index]?.shouldLessError}
                            value={step?.shouldLessValue}
                            onChange={(e) => {
                                handleInputChange(e, index, "shouldLessValue");
                            }}
                        />
                        {Errors[index]?.shouldLessError && (
                            <span className={classes.errorAsterisk}>*</span>
                        )}
                    </StyledFormControl>
                </Grid>
            );
        case "contain_text":
            return (
                <Grid item xs={6}>
                    <StyledFormControl>
                        <StyledOutlinedInput
                            type="text"
                            placeholder="input value"
                            disabled={!isEditable}
                            // error={Errors[index]?.containTextError}
                            value={step?.containTextValue}
                            onChange={(e) => {
                                handleInputChange(e, index, "containTextValue");
                            }}
                        />
                        {Errors[index]?.containTextError && (
                            <span className={classes.errorAsterisk}>*</span>
                        )}
                    </StyledFormControl>
                </Grid>
            );
        case "have_attribute":
            return (
                <Grid item xs={6}>
                    <StyledFormControl>
                        <StyledOutlinedInput
                            type="text"
                            placeholder="input value"
                            disabled={!isEditable}
                            // error={Errors[index]?.haveAttributeError}
                            value={step?.haveAttributeValue}
                            onChange={(e) => {
                                handleInputChange(e, index, "haveAttributeValue");
                            }}
                        />
                        {Errors[index]?.haveAttributeError && (
                            <span className={classes.errorAsterisk}>*</span>
                        )}
                    </StyledFormControl>
                </Grid>
            );
        case "click element using text":
            return (
                <Grid item xs={6}>
                    <StyledFormControl>
                        <StyledOutlinedInput
                            type="text"
                            placeholder="Text value"
                            disabled={!isEditable}
                            // error={Errors[index]?.haveAttributeError}
                            value={step?.textValue}
                            onChange={(e) => {
                                handleInputChange(e, index, "textValue");
                            }}
                        />
                        {Errors[index]?.haveAttributeError && (
                            <span className={classes.errorAsterisk}>*</span>
                        )}
                    </StyledFormControl>
                </Grid>
            );
        case "wait":
            return (
                <Grid item xs={6}>
                    <StyledFormControl>
                        <StyledOutlinedInput
                            type="number"
                            placeholder="Wait Time(in ms)"
                            disabled={!isEditable}
                            // error={Errors[index]?.haveAttributeError}
                            value={step?.wait}
                            onChange={(e) => {
                                handleInputChange(e, index, "wait");
                            }}
                        />
                        {Errors[index]?.haveAttributeError && (
                            <span className={classes.errorAsterisk}>*</span>
                        )}
                    </StyledFormControl>
                </Grid>
            );
        case "check_accessibility":
            return (
                <Grid item xs={6}>
                    <StyledFormControl>
                        <Select
                            options={accessibilityList}
                            isDisabled={!isEditable}
                            value={accessibilityList.find(option => option.value === step?.check_accessibility)}
                            onChange={(data) => {
                                handleInputChange({ target: { value: data.value } }, index, "check_accessibility");
                            }}
                            placeholder="Select accessibility option"
                            styles={{
                                container: (provided) => ({
                                    ...provided,
                                    backgroundColor: "rgb(242, 242, 242)",
                                    width: "100%",
                                }),
                                control: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: "rgb(242, 242, 242)",
                                    "&:hover": {
                                        borderColor: "#654DF7",
                                    },
                                    borderColor: state.isFocused
                                        ? "#654DF7"
                                        : "rgb(242, 242, 242)",
                                }),
                                option: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: state.isSelected ? "#654DF7" : "transparent",
                                }),
                                clearIndicator: (provided) => ({
                                    ...provided,
                                    cursor: "pointer",
                                    ":hover": {
                                        color: "#654DF7",
                                    },
                                }),
                                dropdownIndicator: (provided) => ({
                                    ...provided,
                                    cursor: "pointer",
                                    ":hover": {
                                        color: "#654DF7",
                                    },
                                }),
                            }}
                            menuPosition="fixed"
                        />
                        {Errors[index]?.accessibilityOptionError && (
                            <span className={classes.errorAsterisk}>*</span>
                        )}
                    </StyledFormControl>
                </Grid>
            );
        default:
            return null;
    }
}