import React, { useEffect, useState, useRef } from "react";
import {
  Typography,
  Grid,
  Box,
  Button,
  CircularProgress,
  Card,
} from "@mui/material";
import clsx from "clsx";
import { useStylesTestCase } from "../styles";
import { useSelector, useDispatch } from "react-redux";
import { SiteTable } from "./SiteTable";
import {
  fetchSiteById,
  startAudit,
  fetchAuditById,
  clearAuditList,
} from "../../../../redux/actions/Accessibility/AuditAction";
import { toast } from "react-toastify";

export default function SitesList({ depth }) {
  const classes = useStylesTestCase();
  const dispatch = useDispatch();
  const { selectedNodeId, selectedNode, siteData, auditData } = useSelector(
    (state) => state.audit
  );
  const { userId } = useSelector((state) => state.auth);
  const [loadingCircular, setLoadingCircular] = useState(true);
  const [showTable, setShowTable] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const pollingIntervalRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoadingCircular(true);
      dispatch(clearAuditList());
      await dispatch(fetchSiteById(selectedNodeId));
      await dispatch(fetchAuditById(selectedNodeId));
      setLoadingCircular(false);
    };
    fetchData();
  }, [selectedNodeId, dispatch]);

  useEffect(() => {
    if (auditData.length !== 0) {
      setShowTable(true);
    }
  }, [auditData]);
  

  const isAnyTestInProgress = (auditData) => {
    return auditData?.some((run) => run.status == "In Progress");
};

useEffect(() => {
  const handleVisibilityChange = () => {
    if (document.visibilityState === "visible") {
      const hasRunningTests = isAnyTestInProgress(auditData);
      if (selectedNodeId && hasRunningTests) {
        startPolling();
      }
    } else {
      stopPolling();
    }
  };
  const hasRunningTests = isAnyTestInProgress(auditData);
  if (selectedNodeId && hasRunningTests && document.visibilityState === "visible") {
    startPolling();
  }
  
  document.addEventListener("visibilitychange", handleVisibilityChange);

  // Cleanup on unmount
  return () => {
    stopPolling();
    document.removeEventListener("visibilitychange", handleVisibilityChange);
  };
}, [selectedNodeId, auditData]);

const startPolling = () => {
  if (pollingIntervalRef.current) return; 

  pollingIntervalRef.current = setInterval(async () => {
    if (selectedNodeId) {
      try {
        const response = await dispatch(fetchAuditById(selectedNodeId));
        const updatedTestCases = response?.data;
        if (!isAnyTestInProgress(updatedTestCases)) {
          stopPolling();
        }
      } catch (error) {
        console.error("Error during polling:", error);
        toast.error("Polling error: Unable to fetch test cases.");
        stopPolling();
      }
    }
  }, 5000);
};


const stopPolling = () => {
  if (pollingIntervalRef.current) {
    clearInterval(pollingIntervalRef.current);
    pollingIntervalRef.current = null;
  }
};

  const handleStartAudit = async () => {
    setLoadingButton(true);
    const postData = {
      relationId: selectedNodeId,
      crawlAduitSiteRequest: {
        start_url: siteData.siteUrl,
        strategy: siteData.strategy,
      },
      userId: userId,
    };
    const response = await dispatch(startAudit(postData));
    setLoadingButton(false);
  };

  return (
    <>
      {loadingCircular ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <CircularProgress size="3rem" sx={{ color: "rgb(101, 77, 247)" }} />
        </Box>
      ) : (
        <>
          <Card sx={{ padding: "0px 10px" }}>
            <Grid
              container
              className={classes.Auditheader}
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <Grid
                container
                direction="row"
                xs={6}
                className={`${classes.Auditheader}`}
                sx={{ paddingLeft: "15px" }}
              >
                <Typography
                  className={clsx(classes.customFontSize, classes.highlight)}
                >
                  {selectedNode?.name.length > 40
                    ? selectedNode?.name.slice(0, 40) + "..."
                    : selectedNode?.name}
                </Typography>
              </Grid>
              <Grid item>
                {loadingButton ? (
                  <CircularProgress
                    size="2rem"
                    sx={{ color: "rgb(101, 77, 247)" }}
                  />
                ) : (
                  <Button
                    className={classes.button}
                    sx={{
                      fontSize:'0.87rem',
                      backgroundColor: "rgb(101, 77, 247)",
                      "&:hover": {
                        backgroundColor: "rgb(101, 77, 247) !important",
                        borderColor: "#654DF7",
                        color: "#fff",
                        "&:before": {
                          backgroundColor: "rgb(101, 77, 247) !important",
                          color: "#fff",
                        },
                      },
                      color: "#fff",
                    }}
                    onClick={handleStartAudit}
                  >
                    Start New Audit
                  </Button>
                )}
              </Grid>
            </Grid>
            {showTable && auditData.length !== 0 ? (
              <SiteTable rows={auditData} />
            ) : (
              <></>
            )}
          </Card>
        </>
      )}
    </>
  );
}
