import React, { useState } from "react";
import { Button, Grid, Typography, Box } from "@mui/material";
import { StyledFormControl, StyledOutlinedInput } from "./styles";

const AddWorkspace = ({ handleChange, handleSubmit, formData }) => {
  const [lengthOfWorkspace, setlengthOfWorkspace] = useState(0);

  return (
    <Box
      component="form"
      onSubmit={(event) => handleSubmit(event)}
      sx={{
        marginTop: 1, 
        display: "flex",
        flexDirection: "row",
        maxWidth: "100%",
        width: "100%",
      }}
    >
      <Grid
        container
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={1}
      >
        <Grid item xs={9}>
          <StyledFormControl>
            <StyledOutlinedInput
              placeholder="Workspace Name"
              value={formData.name}
              onChange={(e) => {
                setlengthOfWorkspace(e.target.value.length);
                handleChange(e);
              }}
              required
              inputProps={{ maxLength: 150 }}
              style={{ border: "1px solid rgb(101, 77, 247)" }}
            />
          </StyledFormControl>
          {lengthOfWorkspace === 150 &&
          <Typography
            sx={{
              color: "red",
              fontSize: "10px",
              fontFamily: "Lexend Deca",
            }}
          >
             Input limit 250 letters
          </Typography>
          }
        </Grid>
        <Grid
          item
          xs={3}
          style={{
            textAlign: "right",
          }}
        >
          <Button
            type="submit"
            variant="contained"
            sx={{
              fontSize: 14,
              backgroundColor: "rgb(101, 77, 247)",
              color: "#ffffff",
              cursor: "pointer",
              textAlign: "right",
              "&:hover": {
                backgroundColor: "rgb(101, 77, 247)", 
              },
            }}
          >
            Add
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddWorkspace;
