import axios from "axios";
import { toast } from "react-toastify";
import { header } from "../../../utils/authheader";
import { getBaseUrl } from "../../../utils/configService";
export const FETCH_DATA_REQUEST = "FETCH_DATA_REQUEST";
export const FETCH_DATA_SUCCESS = "FETCH_DATA_SUCCESS";
export const FETCH_DATA_FAILURE = "FETCH_DATA_FAILURE";
export const SET_ROOT_ID = "SET_ROOT_ID";
export const SET_SELECTED_NODE = "SET_SELECTED_NODE";
export const SET_EXPANDED_NODE = "SET_EXPANDED_NODE";
export const EXPAND_PARENT = "EXPAND_PARENT";
export const ADD_WORKSPACE = "ADD_WORKSPACE";
export const UPDATE_WORKSPACE = "UPDATE_WORKSPACE";
export const DELETE_WORKSPACE = "DELETE_WORKSPACE";
export const SELECTED_SUITE = "SELECTED_SUITE";
export const SITE_DATA = "SITE_DATA";
export const FETCH_AUDIT_DATA = "FETCH_AUDIT_DATA";
export const FETCH_JOURNEY_SUCCESS = "FETCH_JOURNEY_SUCCESS";
export const CLEAR_AUDIT_LIST = "CLEAR_AUDIT_LIST";
export const VIOLATION_DATA = "VIOLATION_DATA";
export const AUDIT_RESULT = "AUDIT_RESULT";

export const fetchDataRequest = () => ({
  type: FETCH_DATA_REQUEST,
});

export const fetchDataSuccess = (data) => ({
  type: FETCH_DATA_SUCCESS,
  payload: data,
});

export const fetchSiteDataSuccess = (data) => ({
  type: SITE_DATA,
  payload: data,
});

export const fetchDataFailure = (error) => ({
  type: FETCH_DATA_FAILURE,
  payload: error,
});

export const fetchWorkSpaces = () => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      const BASE_URL = await getBaseUrl();
      const response = await axios.get(
        `${BASE_URL}/Accessibility/GetAccessibilityRelation`,
        header()
      );
      dispatch(
        fetchDataSuccess(response?.data == "" ? [] : response?.data.Data)
      );
    } catch (error) {
      dispatch(fetchDataFailure(error));
    }
  };
};

export const AddWorkspace = (workspace) => {
  return {
    type: ADD_WORKSPACE,
    payload: workspace,
  };
};
export const UpdateWorkspace = (workspace) => {
  return {
    type: UPDATE_WORKSPACE,
    payload: workspace,
  };
};
export const DeleteWorkspace = (id) => {
  return {
    type: DELETE_WORKSPACE,
    payload: id,
  };
};
export const setRootId = (suitId) => {
  return {
    type: SET_ROOT_ID,
    payload: suitId,
  };
};
export const setSelectedNode = () => {
  return {
    type: SET_SELECTED_NODE,
  };
};
export const setExpandedNodes = (id) => {
  return {
    type: SET_EXPANDED_NODE,
    payload: id,
  };
};

export const ExpandParent = (id) => {
  return {
    type: EXPAND_PARENT,
    payload: id,
  };
};

export const JourneyData = (data) => {
  return {
    type: FETCH_JOURNEY_SUCCESS,
    payload: data,
  };
};

export const clearAuditList = () => {
  return {
    type: CLEAR_AUDIT_LIST,
    payload: [],
  };
};

export const DeleteAuditData = (id) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const response = await axios.delete(
        `${BASE_URL}/Accessibility/DeleteAccessibilityRelation?id=${id}`,
        header()
      );
      if (response.data?.status == "success") {
        toast.info("Successfully deleted", {
          style: {
            background: "rgb(101, 77, 247)",
            color: "rgb(255, 255, 255)",
          },
        });
        dispatch(fetchWorkSpaces());
        return response.data;
      } else {
       // toast.error(response.data.message);
        return null;
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  };
};

export const AddUpdateWorkspace = (payload) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const response = await axios.post(
        `${BASE_URL}/Accessibility/AddUpdateAccessibilityRelation`,
        payload,
        header()
      );
      if (response.data.status === "success") {
        toast.info(response.data.message, {
          style: {
            background: "rgb(101, 77, 247)",
            color: "rgb(255, 255, 255)",
          },
        });
        dispatch(fetchWorkSpaces());
        return response.data;
      } else {
       // toast.error(response.data.message);
        return null;
      }
    } catch (error) {
      console.error(error);
      if (error.response && error.response.status !== 200) {
        console.log("errror");
      } else {
       // toast.error(error.message);
      }
      return null;
    }
  };
};

export const AddSite = (payload, onClose) => {
  return async (dispatch, getState) => {
    try {
      const BASE_URL = await getBaseUrl();
      const res = await axios.post(
        `${BASE_URL}/Accessibility/AddUpdateAccessibilityAuditSite`,
        payload,
        header()
      );
      onClose();
      await dispatch(fetchWorkSpaces());
      const state = getState();
      const workspaces = state.journey.listData;
      const nodeId = workspaces.find(
        (workspace) =>
          workspace.parentId === payload.rootId &&
          workspace.name === payload.name
      )?.id;

      if (res.data.status == "fail") {
        toast.warn(res.data.message);
      } else {
        dispatch(ExpandParent(payload.rootId));
        toast.info(res.data.message, {
          style: {
            background: "rgb(101, 77, 247)",
            color: "rgb(255, 255, 255)",
          },
        });
      }
      return nodeId;
    } catch (error) {
      console.log("error saving ", error);
    }
  };
};

export const fetchSiteById = (id) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const response = await axios.get(
        `${BASE_URL}/Accessibility/GetAccessibilityAuditSiteByRelationId?id=${id}`,
        header()
      );
      dispatch(
        fetchSiteDataSuccess(response.data == "" ? [] : response?.data?.Data)
      );
    } catch (error) {
      dispatch(fetchDataFailure(error));
    }
  };
};

export const startAudit = (postData) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const response = await axios.post(
        `${BASE_URL}/Accessibility/CrawlAuditSite`,
        postData,
        header()
      );
      dispatch(fetchAuditById(postData.relationId));
      if (response.data.status === "success") {
        toast.info(response.data.message, {
          style: {
            background: "rgb(101, 77, 247)",
            color: "rgb(255, 255, 255)",
          },
        });
        return response.data;
      } else {
       // toast.error(response.data.message);
        return null;
      }
    } catch (error) {
     // toast.error("Network Error");
      console.error("Error starting Audit:", error);
      return null;
    }
  };
};

export const fetchAuditById = (Id) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const response = await axios.get(
        `${BASE_URL}/Accessibility/GetAccessibilityAuditListByRelationId?id=${Id}`,
        header()
      );
      if (Array.isArray(response.data?.Data)) {
        const filteredData = response?.data?.Data.map((item) => {
          return {
            id: item.clientReferenceId,
            ApplicationName: item.runName,
            status: item.status,
            createdOn: item.createdOn,
            pages: item.Pages,
            action: item.actions,
            rootId: item.relationId,
          };
        });
        dispatch({
          type: FETCH_JOURNEY_SUCCESS,
          payload: filteredData,
        });
      } else {
        console.error("Expected an array but got:", response.data);
      }
    } catch (error) {
      console.error("Error fetching journey data:", error);
    }
  };
};

export const getAuditDetailsById = (Id) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const response = await axios.get(
        `${BASE_URL}/Accessibility/GetAccessibilityAuditDetailsByRefId?clientRefId=${Id}`,
        header()
      );
     
      dispatch({
        type: AUDIT_RESULT,
        payload: response.data.Data,
      });
    } catch (error) {
      console.error("Error fetching journey data:", error);
    }
  };
};

export const getPageViolationsByPageId = (Id) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const response = await axios.get(
        `${BASE_URL}/Accessibility/GetAccessibilityPageViolationsByPageId?pageId=${Id}`,
        header()
      );
   
      dispatch({
        type: VIOLATION_DATA,
        payload: response.data.Data,
      });
    } catch (error) {
      console.error("Error fetching journey data:", error);
    }
  };
};
