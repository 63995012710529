import axios from "axios";
import { toast } from "react-toastify";
import { header, headerForm } from "../../utils/authheader";
import { getBaseUrl } from "../../utils/configService";
// const BASE_URL = process.env.REACT_APP_BASE_URL;

// for test data
export const FETCH_TEST_DATA_REQUEST = "FETCH_TEST_DATA_REQUEST";
export const FETCH_TEST_DATA_SUCCESS = "FETCH_TEST_DATA_SUCCESS";
export const FETCH_TEST_DATA_FAILURE = "FETCH_TEST_DATA_FAILURE";
export const DELETE_TEST_DATA = "DELETE_TEST_DATA";
export const ADD_TEST_DATA = "ADD_TEST_DATA";

export const GetTestData = (PerformanceFileId) => async (dispatch) => {
  dispatch({ type: FETCH_TEST_DATA_REQUEST });

  try {
    const BASE_URL = await getBaseUrl();
    const response = await axios.get(
      `${BASE_URL}/Performance/GetTestDataByPerformanceFileId?PerformanceFileId=${PerformanceFileId}`,
      header()
    );
    const Response = response?.data?.data ? JSON.parse(response?.data?.data):[];
    if(response.data.status === "Success"){
    if (Array.isArray(Response))
      dispatch({ type: FETCH_TEST_DATA_SUCCESS, payload: Response });
    else dispatch({ type: FETCH_TEST_DATA_SUCCESS, payload: [] });
 
}
  } catch (error) {
    const errorMessage =
      error.response?.data?.message || "Network error or server unreachable";

    // Dispatch failure action for Redux
    dispatch({
      type: FETCH_TEST_DATA_FAILURE,
      payload: errorMessage,
    });

    // Display error message using toast
  }
};

export const submitTestData = (formData) => {
  return async (dispatch, getState) => {
    try {
      const BASE_URL = await getBaseUrl();
      const { scenarioId } = getState().performance; // Get scenarioId from Redux store
      const res = await axios.post(
        `${BASE_URL}/Performance/AddTestData`,
        formData,
        headerForm()
      );

      // Check for success response
      if (res.data.status == "success") {
        toast.info(res.data.message, {
          style: {
            background: "rgb(101, 77, 247)",
            color: "rgb(255, 255, 255)",
          },
        });
        // Dispatch action to fetch updated test data
        dispatch(GetTestData(scenarioId));
      }
      // Check for failure response
      else if (res.data.status === "fail") {
       // toast.error(res.data.message);
        // Dispatch action to fetch updated test data, if needed
        dispatch(GetTestData(scenarioId));
      }
    } catch (error) {
     // toast.error(
      //   error.response?.data?.message || "Network error or server unreachable",
      //   {
      //     style: {
      //       background: "rgb(255, 67, 67)",
      //       color: "rgb(255, 255, 255)",
      //     },
      //   }
      // );
    }
  };
};

export const deleteTestData = (testId) => {
  return async (dispatch, getState) => {
    try {
      const BASE_URL = await getBaseUrl();
      const { scenarioId } = getState().performance; // Get scenarioId from Redux store
      const res = await axios.post(
        `${BASE_URL}/Performance/DeleteTestData?Id=${testId}`,
        header()
      );

      if (res.data.status === "success") {
        toast.info(res?.data?.message, {
          style: {
            background: "rgb(101, 77, 247)",
            color: "rgb(255, 255, 255)",
          },
        });

        // Update propertyList after successful deletion
        // fetchData();
        // dispatch(GetTestData(scenarioId));
        dispatch({ type: DELETE_TEST_DATA, payload: testId });
      }
    } 
      catch (error) {
       // toast.error(error.response?.data?.message || "Network error or server unreachable", {
        //   style: { background: "rgb(255, 67, 67)", color: "rgb(255, 255, 255)" },
        // });
      }
  };
};
