import { makeStyles } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import {FormControl, OutlinedInput, TableCell, tableCellClasses} from "@mui/material";

export const useStyles = makeStyles({
  main: {
    margin: "10px 12px",
  },
  bodyHeader: {
    borderBottom: "1px solid #DCD9D9",
    padding: "20px 14px",
  },
  bodyTree: {
    padding: "20px 16px",
  },
  bodyTreeheight: {
    overflow: "auto",
    maxHeight: "calc(100vh - 240px)",
    paddingBottom: "40px",
    minHeight: "calc(100vh - 240px);"
  },
});

export const StyledOutlinedInput = styled(OutlinedInput)(({ theme }) => ({
  fontFamily: "Lexend Deca",
  fontSize: "14px",
  height: "40px",
}));

export const StyledFormControl = styled(FormControl)(({theme})=>({
  width:'100%',
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#654DF7",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#654DF7",
    },
    "& fieldset": {
      borderColor: "transparent",
    },
  },
}))

export const useStylesTestCase = makeStyles((theme) => ({
  header: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "21px",
    padding: "10px 22px",
  },
  Auditheader: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "21px",
    padding: "14px 0px",
  },
  button: {
    height: "40px",
    minWidth: "110px",
    display: "flex",
    textTransform: "none !important",
    backgroundColor: "rgb(101, 77, 247)",
    color: "#fff",
  },
  inputError: {
    color: "red",
    textAlign: "left",
    fontSize: "14px !important",
    // paddingLeft: '10px',
  },
  input: {
    display: "flex",
    flexDirection: "row",
  },
  customBackgroung: {
    backgroundColor: "rgb(242, 242, 242)",
    border: "none",
  },
  customheight: {
    height: "40px",
  },
  customFontSize: {
    fontSize: "14px !important",
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      borderColor: "transparent !important",
      "&:hover fieldset": {
        borderColor: "#654DF7",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#654DF7",
      },
    },
  },
  label: {
    alignSelf: "center",
    padding: "10px 10px",
  },
  ButtonContainer: {
    display: "flex",
    justifyContent: "right",
    alignItems: "center",
    padding: "10px",
    // borderTop: "1px solid #ccc",
    gap: "8px",
  },
  highlight: {
    fontSize: "24px",
    fontWeight: "400",
    paddLeft:'10px',
    color: "rgb(56, 56, 56)",
  },
}));

export const StyledTableCell = styled(TableCell)(({ theme, isFirstColumn, isLastColumn }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(242, 242, 242)",
    color: "#5c5c5c",
    padding: "10px 20px",
    fontFamily: "Lexend Deca",
    fontSize: "12px",
    borderTop: "1px solid rgb(217, 217, 217)",
    lineHeight: "18px",
    borderLeft: isFirstColumn ? "1px solid rgb(217, 217, 217)" : undefined,
    borderRight: isLastColumn ? "1px solid rgb(217, 217, 217)" : undefined,
  },
  [`&.${tableCellClasses.body}`]: {
    padding: "10px 20px",
    fontSize: "12px",
    lineHeight: "18px",
    letterSpacing: "normal",
    fontFamily: `"Lexend Deca"`,
    borderLeft: isFirstColumn ? "1px solid rgb(217, 217, 217)" : undefined,
    borderRight: isLastColumn ? "1px solid rgb(217, 217, 217)" : undefined,
  },
}));

export const useTableStyles = makeStyles((theme) => ({
  tableRow: {
    cursor: "pointer",
    "&:hover": {
      border: "2px solid #654DF7",
      backgroundColor: theme.palette.action.hover,
      cursor: "pointer",
    },
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: theme.palette.action.selected,
    },
    height: "40px",
  },
  activeRow: {
    border: "2px solid #654DF7",
  },
}));