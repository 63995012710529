import axios from "axios";
import { toast } from "react-toastify";
import { header } from "../../utils/authheader";
import { getBaseUrl, getCoreEngineBaseUrl } from "../../utils/configService";

export const FETCH_DATA_REQUEST = "FETCH_DATA_REQUEST";
export const FETCH_DATA_SUCCESS = "FETCH_DATA_SUCCESS";
export const FETCH_DATA_FAILURE = "FETCH_DATA_FAILURE";
export const ADD_LOCATION = "ADD_LOCATION";
export const UPDATE_LOCATION = "UPDATE_LOCATION";
export const DELETE_LOCATION = "DELETE_LOCATION";
export const GET_LOCATION_LISTS = "GET_LOCATION_LISTS";
export const DELETE_LOCATION_SETTING = "DELETE_LOCATION_SETTING";
export const ADD_LOCATION_SETTING = "ADD_LOCATION_SETTING";
export const GET_AGENTS_LISTS = "GET_AGENTS_LISTS";
export const ADD_AGENTS = "ADD_AGENTS";

// const BASE_URL = process.env.REACT_APP_BASE_URL;

export const GetLocationData = (PerformanceFileId) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_DATA_REQUEST });

    try {
      const BASE_URL = await getBaseUrl();
      let response;
      try {
        response = await axios.get(
          `${BASE_URL}/Performance/GetLocationByPerformanceFileId?PerformanceFileId=${PerformanceFileId}`,
          header()
        ); 
      } catch (error) {
        response = null
      }
      const loadRes = await axios.get(
        `${BASE_URL}/Performance/GetLoadByPerformanceFileId?PerformanceFileId=${PerformanceFileId}`,
        header()
      );
      const loadData = loadRes?.data?.data ? JSON.parse(loadRes.data.data) : [];
      const resData = response?.data?.data ? JSON.parse(response.data.data) : [];

      let totalUsers = 0;
      let totalTraficPercent = 0;
      let locationData = [];

      if (Array.isArray(loadData)) {
        totalUsers = loadData[0].TotalUsers;
        if (Array.isArray(resData) && resData.length) {
          locationData = resData;
          totalTraficPercent = resData.reduce(
            (sum, data) => sum + data?.PercentageTraffic,
            0
          );
        }
      }
      dispatch({
        type: FETCH_DATA_SUCCESS,
        payload: { totalUsers, totalTraficPercent, locationData },
      });
    } catch (error) {
      dispatch({
        type: FETCH_DATA_FAILURE,
        payload: { error: error.message },
      });
    }
  };
};
export const updateLocation = (payload) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const res = await axios.post(
        `${BASE_URL}/Performance/UpdateLoaction`,
        payload,
        header()
      );
      dispatch({ type: UPDATE_LOCATION, payload: payload });
    } catch (error) {
      toast.error(
        error.response?.data?.message ,
        {
          style: {
            background: "rgb(255, 67, 67)",
            color: "rgb(255, 255, 255)",
          },
        }
      );
    }
  };
};
export const submitLocation = (payload) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const res = await axios.post(
        `${BASE_URL}/Performance/AddLocation`,
        payload,
        header()
      );
      const LocaData = res?.data?.data ? JSON.parse(res.data.data) : [];
      if (res.data?.status && res.data.status.trim().toLowerCase() === "success") {
        toast.info(res?.data?.message, {
          style: {
            background: "rgb(101, 77, 247)",
            color: "rgb(255, 255, 255)",
          },
        });
        dispatch({ type: ADD_LOCATION, payload: LocaData });
      } else {
       // toast.error("Submitting error");
      }
    } catch (error) {
    //  toast.error(error.response?.data?.message, {
    //     style: {
    //       background: "rgb(255, 67, 67)",
    //       color: "rgb(255, 255, 255)",
    //     },
    //   });
    }
  };
};
export const deleteLocation = (locationId) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const res = await axios.post(
        `${BASE_URL}/Performance/DeleteLocation?Id=${locationId}`,
        header()
      );

      if (res.data?.status && res.data.status.trim().toLowerCase() === "success") {
        toast.info("Successfully deleted", {
          style: {
            background: "rgb(101, 77, 247)",
            color: "rgb(255, 255, 255)",
          },
        });

        dispatch({ type: DELETE_LOCATION, payload: locationId });
      }
    } catch (error) {
      console.log("error deleting ", error);
     // toast.error("Network error");
    }
  };
};

export const getLocationList = (userId) => {
  return async (dispatch) => {
    try {
      const CORE_BASE_URL = await getCoreEngineBaseUrl();
      const BASE_URL = await getBaseUrl();

      const response = await axios.get(
        `${BASE_URL}/Performance/GetPrivateLocationList?UserId=${userId}`,
        header()
      );

      if (response.data.status === "Success") {
        const Response = response?.data?.data
          ? (response.data.data)
          : [];
        dispatch({
          type: GET_LOCATION_LISTS,
          payload: Response,
        });
      }
    } catch (error) {
      // toast.error(
      //   error.response?.data?.message,
      //   {
      //     style: {
      //       background: "rgb(255, 67, 67)",
      //       color: "rgb(255, 255, 255)",
      //     },
      //   }
      // );
    }
  };
};

export const AddLocationSettings = (
  data,
  onClose,
  resetFormAndState,
  userId
) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const response = await axios.post(
        `${BASE_URL}/Performance/AddUpdatePrivateLocation`,
        data,
        header()
      );
      if (response.data.status === "Success") {
        dispatch(getLocationList(userId));
        toast.info(response?.data?.message, {
          style: {
            background: "rgb(101, 77, 247)",
            color: "rgb(255, 255, 255)",
          },
        });

        onClose();
        resetFormAndState();
      } else {
        console.log("Submitting error");
      }
    } catch (error) {
      // toast.error(
      //   error.response?.data?.message || "Network error or server unreachable",
      //   {
      //     style: {
      //       background: "rgb(255, 67, 67)",
      //       color: "rgb(255, 255, 255)",
      //     },
      //   }
      // );
    }
  };
};

export const deleteLocationOnSettings = (
  id,
  refId,
  setopenDelModal,
  userId
) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const payload = {
        id: id,
        referId: refId,
      };
      const response = await axios.post(
        `${BASE_URL}/Performance/DeletePrivateLocationData`,
        payload,
        header()
      );
      setopenDelModal(false);
      if (response.data.status == "Success") {
        dispatch(getLocationList(userId));
        toast.info(response?.data?.message, {
          style: {
            background: "rgb(101, 77, 247)",
            color: "rgb(255, 255, 255)",
          },
        });
        setopenDelModal(false);
      }
      dispatch({ type: DELETE_LOCATION_SETTING, payload: id });
    } catch (error) {
      // toast.error(
      //   error.response?.data?.message || "Network error or server unreachable",
      //   {
      //     style: {
      //       background: "rgb(255, 67, 67)",
      //       color: "rgb(255, 255, 255)",
      //     },
      //   }
      // );
    }
  };
};

export const AddLocationAgent = (data, setShowDockerCommand, userId) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const response = await axios.post(
        `${BASE_URL}/Performance/AddPrivateAgents`,
        data,
        header()
      );
      const Response = response?.data?.data
        ? JSON.parse(response.data.data)
        : [];
      if (response.data.status == "Success") {
        dispatch(getLocationList(userId));
        dispatch({
          type: ADD_AGENTS,
          payload: Response,
        });
        setShowDockerCommand(true);
        toast.info(response.data.message, {
          style: {
            background: "rgb(101, 77, 247)",
            color: "rgb(255, 255, 255)",
          },
        });
      }
    } catch (error) {
     // toast.error(error.response?.data?.message, {
      //   style: { background: "rgb(255, 67, 67)", color: "rgb(255, 255, 255)" },
      // });
    }
  };
};

export const getAgentById = (id) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const response = await axios.get(
        `${BASE_URL}/Performance/GetAgentDetailByLocationId?Id=${id}`,
        header()
      );

      const Response = response?.data;
      if (response.data.status == "Success") {
        dispatch({
          type: GET_AGENTS_LISTS,
          payload: Response,
        });
      }
    } catch (error) {
     // toast.error(
      //   error.response?.data?.message || "Network error or server unreachable",
      //   {
      //     style: {
      //       background: "rgb(255, 67, 67)",
      //       color: "rgb(255, 255, 255)",
      //     },
      //   }
      // );
    }
  };
};

export const updateLocationAgent = (
  data,
  setShowDockerCommand,
  ref,
  userId
) => {
  return async (dispatch) => {
    try {
      const CORE_BASE_URL = await getCoreEngineBaseUrl();
      const BASE_URL = await getBaseUrl();
      const response = await axios.post(
        `${BASE_URL}/Performance/UpdatePrivateAgents`,
        data,
        header()
      );
      const Response = response?.data?.data ;
       
      if (response.data.status == "Success") {
        toast.info(response.data.message, {
          style: {
            background: "rgb(101, 77, 247)",
            color: "rgb(255, 255, 255)",
          },
        });

        dispatch(getLocationList(userId));
        dispatch({
          type: ADD_AGENTS,
          payload: Response,
        });
        setShowDockerCommand(true);
      }
    } catch (error) {
     // toast.error(
      //   error.response?.data?.message ,
      //   {
      //     style: {
      //       background: "rgb(255, 67, 67)",
      //       color: "rgb(255, 255, 255)",
      //     },
      //   }
      // );
    }
  };
};
