import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

// Styles
const modalOverlay = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1000,
};

const modalContent = {
  backgroundColor: "#fff",
  padding: "20px",
  borderRadius: "8px",
  width: "500px",
  maxHeight: "80vh",
  overflowY: "auto",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
};

const closeButton = {
  position: "absolute",
  top: "10px",
  right: "10px",
  background: "none",
  border: "none",
  fontSize: "24px",
  cursor: "pointer",
};

const header = {
  textAlign: "center",
  marginBottom: "20px",
};

const summaryContainer = {
  display: "flex",
  justifyContent: "center",
  gap: "10px",
  marginBottom: "20px",
};

const badge = {
  color: "#fff",
  padding: "5px 10px",
  borderRadius: "20px",
  fontSize: "14px",
  fontWeight: "bold",
};

const violationContainer = {
  border: "1px solid #ddd",
  borderRadius: "8px",
  marginBottom: "10px",
  overflow: "hidden",
};

const violationHeader = {
  padding: "10px 15px",
  backgroundColor: "#f9f9f9",
  display: "flex",
  justifyContent: "space-between",
  cursor: "pointer",
  alignItems: "center",
  fontWeight: "bold",
};

const violationDetails = {
  padding: "10px 15px",
  backgroundColor: "#fff",
};

const detailsText = {
  margin: "5px 0",
};

const learnMoreLink = {
  color: "#007bff",
  textDecoration: "none",
};

// Helper function to calculate counts based on impact
const calculateImpactCounts = (data) => {
  const impactCounts = {
    critical: 0,
    serious: 0,
    moderate: 0,
  };

  data.forEach((item) => {
    if (impactCounts[item.impact] !== undefined) {
      impactCounts[item.impact]++;
    }
  });

  return impactCounts;
};

const AccessibilityViolationsModal = ({ isOpen, onClose, data }) => {
  if (!isOpen) return null;

  const impactCounts = calculateImpactCounts(data);

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div style={modalOverlay} onClick={handleOverlayClick}>
      <div style={modalContent}>
        <button style={closeButton} onClick={onClose}>
          &times;
        </button>
        <h2 style={header}>Accessibility Violations Report</h2>

        {/* Summary Section */}
        <div style={summaryContainer}>
          {impactCounts.critical > 0 && (
            <div style={{ ...badge, backgroundColor: "#dc3545" }}>
              {impactCounts.critical} Critical
            </div>
          )}
          {impactCounts.serious > 0 && (
            <div style={{ ...badge, backgroundColor: "#ff9800" }}>
              {impactCounts.serious} Serious
            </div>
          )}
          {impactCounts.moderate > 0 && (
            <div style={{ ...badge, backgroundColor: "#ffc107" }}>
              {impactCounts.moderate} Moderate
            </div>
          )}
        </div>

        {/* Violations List */}
        <div>
          {data.map((item, index) => (
            <ViolationItem key={index} item={item} />
          ))}
        </div>
      </div>
    </div>
  );
};

// Individual Violation Item
const ViolationItem = ({ item }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  // Map impact levels to colors
  const impactColors = {
    critical: "#dc3545",
    serious: "#ff9800",
    moderate: "#ffc107",
  };

  return (
    <div style={violationContainer}>
      <div style={violationHeader} onClick={() => setIsExpanded(!isExpanded)}>
        <span>
          {isExpanded ? (
            <ExpandLessIcon style={{ marginRight: "5px" }} />
          ) : (
            <ExpandMoreIcon style={{ marginRight: "5px" }} />
          )}
          {item.id}
        </span>
        <span
          style={{
            ...badge,
            backgroundColor: impactColors[item.impact] || "#007bff",
          }}
        >
          {item.impact}
        </span>
      </div>

      {/* Expanded Content */}
      {isExpanded && (
        <div style={violationDetails}>
          <p style={detailsText}>
            <strong>Description:</strong> {item.description}
          </p>
          <p style={detailsText}>
            <strong>Affected Elements:</strong> {item.nodes}
          </p>
          <p style={detailsText}>
            <strong>Help:</strong> {item.help}
          </p>
          <a
            href={item.helpUrl}
            target="_blank"
            rel="noopener noreferrer"
            style={learnMoreLink}
          >
            Learn More
          </a>
        </div>
      )}
    </div>
  );
};

export default AccessibilityViolationsModal;
