import React from "react";

export const Indication = ({ isActive, color }) => {
  return (
    <div style={{ color: isActive ? color : "inherit" }}>
     <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.9688 15.3581C10.9688 15.222 10.9956 15.0872 11.048 14.9616C11.1004 14.836 11.177 14.722 11.2737 14.6262C11.3704 14.5304 11.4851 14.4547 11.6112 14.4035C11.7373 14.3522 11.8722 14.3265 12.0083 14.3277C12.2091 14.3313 12.4043 14.394 12.5696 14.508C12.7349 14.622 12.863 14.7822 12.9377 14.9685C13.0125 15.1549 13.0306 15.3592 12.9899 15.5558C12.9491 15.7524 12.8513 15.9326 12.7087 16.074C12.5661 16.2153 12.3851 16.3115 12.1881 16.3505C11.9911 16.3895 11.787 16.3695 11.6013 16.2931C11.4156 16.2167 11.2566 16.0873 11.1441 15.9209C11.0316 15.7547 10.9706 15.5589 10.9688 15.3581ZM11.2999 12.8465L11.1711 8.04408C11.1588 7.92864 11.1711 7.81191 11.2069 7.7015C11.2427 7.59107 11.3013 7.4894 11.379 7.4031C11.4566 7.3168 11.5516 7.2478 11.6576 7.20056C11.7636 7.15332 11.8784 7.12891 11.9945 7.12891C12.1106 7.12891 12.2254 7.15332 12.3315 7.20056C12.4374 7.2478 12.5324 7.3168 12.61 7.4031C12.6877 7.4894 12.7463 7.59107 12.7821 7.7015C12.8179 7.81191 12.8302 7.92864 12.8179 8.04408L12.6983 12.8465C12.6983 13.0318 12.6247 13.2098 12.4936 13.3409C12.3625 13.472 12.1845 13.5457 11.9991 13.5457C11.8137 13.5457 11.6358 13.472 11.5047 13.3409C11.3736 13.2098 11.2999 13.0318 11.2999 12.8465Z" fill="#646464"/>
    <path d="M11.9983 20.2798C16.6982 20.2798 20.5083 16.4697 20.5083 11.7698C20.5083 7.06983 16.6982 3.25977 11.9983 3.25977C7.29834 3.25977 3.48828 7.06983 3.48828 11.7698C3.48828 16.4697 7.29834 20.2798 11.9983 20.2798Z" stroke="#646464" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    </div>
  );
};