import {
  FETCH_SCHEDULE_DETAILS_FAILURE,
  FETCH_SCHEDULE_DETAILS_REQUEST,
  FETCH_SCHEDULE_DETAILS_SUCCESS,
  TEST_RUN,
  EDIT_TEST_RUN
} from "../../actions/testlab/testrunAction";

const initialState = {
  testrunlist: [],
  scheduleDetails: null,
  loading: false,
  error: null,
  runDetails: {},
};

const testrunReducer = (state = initialState, action) => {
  switch (action.type) {
    case TEST_RUN:
      return {
        ...state,
        testrunlist: action.payload,
      };
    case FETCH_SCHEDULE_DETAILS_REQUEST:
      return { ...state, loading: true };
    case FETCH_SCHEDULE_DETAILS_SUCCESS:
      return { ...state, loading: false, scheduleDetails: action.payload };
    case FETCH_SCHEDULE_DETAILS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case EDIT_TEST_RUN:
      return {
        ...state,
        runDetails: action.payload,
      };
    default:
      return state;
  }
};

export default testrunReducer;
