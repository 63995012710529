import React from "react";
import { CardContent, Typography, Box } from "@mui/material";

const CustomCardContent = ({ type, className, data }) => {
  const colorMap = {
    Critical: "#DE3444",  
    High: "#654DF7",   
    Medium: "#FF9800", 
    Low: "#00A879",  
  };

  const dotColor = colorMap[type] || "grey";

  return (
    <>
      {/* First CardContent - displays the type and a color dot */}
      <CardContent
        className={className}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "16px",
        }}
      >
        <Typography sx={{ fontSize: "14px" }}>{type}</Typography>
        <Box
          sx={{
            width: 10,
            height: 10,
            backgroundColor: dotColor,
            borderRadius: "50%",
          }}
        />
      </CardContent>

      {/* Second CardContent - displays the data and type result */}
      <CardContent>
        <Typography variant="h1" sx={{ fontSize: "52px" }}>
          {data}
        </Typography>
        <Typography variant="body1" sx={{ fontSize: "12px" }}>
          {type} results
        </Typography>
      </CardContent>
    </>
  );
};

export default CustomCardContent;
