import {
  GET_TEST_SUITS,
  GET_USER_LIST,
  GET_PERFORMANCE_INTEGRATION,
  JIRA_ISSUE_TYPES,
  JIRA_PROJECT_LIST,
  GET_ALL_JIRA_ISSUE,
  SET_ACTIVE_PARENT,
  SET_SELECTED_ITEM,
  GET_EMAIL_SCHEDULE,
  GET_ADVANCE_USER_LIST,
  ENVIORNMENT_VARIABLE_SET,
  ENVIORNMENT_VARIABLE_SET_VALUE,
  ENVIORNMENT_VARIABLE_SET_SELECTED,
  ENVIORNMENT_VARIABLE_SET_TESTLAB,
  GET_BUILD_VERSION_LIST,
  GET_BUILD_VERSION_DETAILS,
  GET_RELEASE_VERSION_LIST,
  GET_RELEASE_VERSION_DETAILS,
  GET_USER_LIST_TESTLAB,
} from "../actions/settingAction";

const initialState = {
  testSuitsList: [],
  virtualUser: 0,
  totalLocation: 0,
  testUserList: [],
  performanceIntegration: [],
  jiraIssueTypes: [],
  jiraProjectList: [],
  jiraIssueList: [],
  activeParent: null,
  selectedItem: null,
  emailSchediler:[],
  advanceUserList:[],
  enviornment_variable_set:[],
  enviornment_variable_set_value:[],
  setselected:null,
  buildVersionList: [],
  buildVersionDetails: [],
  releaseVersionList: [],
  releaseVersionDetails: [],
  testUserList_testlab:[],
};

const settingsReduser = (state = initialState, action) => {
  switch (action.type) {
    case GET_TEST_SUITS: {
      return {
        ...state,
        testSuitsList: action.payload,
      };
    }
    case GET_USER_LIST: {
      return {
        ...state,
        testUserList: action.payload,
      };
    }
    case GET_USER_LIST_TESTLAB: {
      return {
        ...state,
        testUserList_testlab: action.payload,
      };
    }
    case ENVIORNMENT_VARIABLE_SET_SELECTED: {
      return {
        ...state,
        setselected: action.payload,
      };
    }
    case ENVIORNMENT_VARIABLE_SET: {
      return {
        ...state,
        enviornment_variable_set: action.payload,
      };
    }
    case ENVIORNMENT_VARIABLE_SET_VALUE: {
      return {
        ...state,
        enviornment_variable_set_value: action.payload || [],
      };
    }
    case GET_PERFORMANCE_INTEGRATION: {
      return {
        ...state,
        performanceIntegration: action.payload,
      };
    }
    case JIRA_ISSUE_TYPES: {
      const transformedArray = action.payload?.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      return {
        ...state,
        jiraIssueTypes: transformedArray,
      };
    }
    case JIRA_PROJECT_LIST: {
      const transformedArray = action.payload?.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      return {
        ...state,
        jiraProjectList: transformedArray,
      };
    }
    case GET_ALL_JIRA_ISSUE: {
      return {
        ...state,
        jiraIssueList: action.payload,
      };
    }
    case GET_EMAIL_SCHEDULE: {
      return  {
        ...state,
        emailSchediler: action.payload
      }
    }
    case SET_ACTIVE_PARENT:
      return {
        ...state,
        activeParent: action.payload,
      };
    case SET_SELECTED_ITEM:
      return {
        ...state,
        selectedItem: action.payload,
      };
      case ENVIORNMENT_VARIABLE_SET_SELECTED: {
        return {
          ...state,
          setselected: action.payload,
        };
      }
      case ENVIORNMENT_VARIABLE_SET: {
        return {
          ...state,
          enviornment_variable_set: action.payload,
        };
      }
      case ENVIORNMENT_VARIABLE_SET_TESTLAB: {
        return {
          ...state,
          enviornment_variable_set_testlab: action.payload,
        };
      }
      case ENVIORNMENT_VARIABLE_SET_VALUE: {
        return {
          ...state,
          enviornment_variable_set_value: action.payload || [],
        };
      }
      case GET_ADVANCE_USER_LIST: {
        return {
          ...state,
          advanceUserList: action.payload,
        };
      }
      case GET_BUILD_VERSION_LIST: {
        return {
          ...state,
          buildVersionList: action.payload,
        };
      }
      case GET_BUILD_VERSION_DETAILS:
      return {
        ...state,
        buildVersionDetails: action.payload,
      }
      case GET_RELEASE_VERSION_LIST: {
        return {
          ...state,
          releaseVersionList: action.payload,
        };
      }
      case GET_RELEASE_VERSION_DETAILS:
      return {
        ...state,
        releaseVersionDetails: action.payload,
      }
    default:
      return state;
  }
};
export default settingsReduser;