import React, { useState, useEffect } from "react";
import {
  OutlinedInput,
  FormControl,
  Button,
  Typography,
  DialogActions,
  Grid,
  Divider,
  DialogContent,
  DialogTitle,
  Dialog,
  IconButton,
  Box,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { toast } from "react-toastify";
import { CloseIcon } from "../../../../comman/icons";
import {
  AddSite,
  fetchSiteById,
  setRootId,
} from "../../../../redux/actions/Accessibility/AuditAction";
import { useDispatch, useSelector } from "react-redux";

function AddSiteDetails({ open, onClose, parent }) {
  const dispatch = useDispatch();
  const { siteData } = useSelector((state) => state.audit);
  const { userId } = useSelector((state) => state.auth);
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [nameError, setNameError] = useState(false);
  const [urlError, setUrlError] = useState(false);
  const [nameLengthError, setNameLengthError] = useState(false);
  const [urlLengthError, setUrlLengthError] = useState(false);
  const [dropdownValue, setDropdownValue] = useState("");
  const [loading, setLoading] = useState(false);
  const dropdownOptions = ["domain", "subdomain", "path"];
  const [Error, setError] = useState({
    name: "",
    url: "",
    dropdown: "",
  });

  useEffect(() => {
    if (parent?.type === "edit") {
      setLoading(true);
      dispatch(fetchSiteById(parent.id)).finally(() => {
        setLoading(false);
      });
    }
  }, [parent, dispatch]);

  useEffect(() => {
    if (parent?.type === "edit" && siteData) {
      const site = siteData;
      setName(site.name || "");
      setUrl(site.siteUrl || "");
      setDropdownValue(site.strategy || "");
    }
  }, [siteData, parent]);

  useEffect(() => {
    if (!open) {
      resetForm();
    }
  }, [open]);

  const resetForm = () => {
    setName("");
    setUrl("");
    setNameError(false);
    setUrlError(false);
    setNameLengthError(false);
    setUrlLengthError(false);
    setDropdownValue("");
    setError({ name: "", url: "", dropdown: "" });
  };

  const handleNameChange = (event) => {
    const value = event.target.value;
    setName(value);
    if (value.length > 50) {
      setError((prev) => ({
        ...prev,
        name: "Name cannot have more than 50 characters",
      }));
    } else {
      setError((prev) => ({ ...prev, name: "" }));
    }
  };

  const handleUrlChange = (event) => {
    const value = event.target.value;
    setUrl(value);
    const urlPattern = /^(https?:\/\/)?([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(\/.*)?$/;
    if (!urlPattern.test(value)) {
      setError((prev) => ({
        ...prev,
        url: "Please enter a valid URL",
      }));
    } else if (value.length > 50) {
      setError((prev) => ({
        ...prev,
        url: "URL cannot have more than 50 characters",
      }));
    } else {
      setError((prev) => ({ ...prev, url: "" }));
    }
  };

  const handleDropdownChange = (event) => {
    const value = event.target.value;
    setDropdownValue(value);
    setError((prev) => ({
      ...prev,
      dropdown: value ? "" : "Please select a valid option",
    }));
  };

  const handleSubmit = () => {
    const newError = {}; // Temp error object to collect all errors

    // Validate Name
    if (!name.trim()) {
      newError.name = "Name is required";
    } else if (name.length > 50) {
      newError.name = "Name cannot have more than 50 characters";
    } else {
      newError.name = "";
    }

    // Validate URL
    if (!url.trim()) {
      newError.url = "URL is required";
    } else if (
      !/^(https?:\/\/)?([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(\/.*)?$/.test(url)
    ) {
      newError.url = "Please enter a valid URL";
    } else if (url.length > 50) {
      newError.url = "URL cannot have more than 50 characters";
    } else {
      newError.url = "";
    }

    // Validate Dropdown
    if (!dropdownValue) {
      newError.dropdown = "Type selection is required";
    } else {
      newError.dropdown = "";
    }

    // Update Error State
    setError(newError);

    // Check if there are any errors
    if (Object.values(newError).some((err) => err)) {
      toast.warn("Please fill in all required fields correctly");
      return;
    }

    let payload = {
      id: parent?.type === "Add" ? 0 : siteData?.id,
      name: name,
      siteUrl: url,
      rootId: parent?.type === "edit" ? siteData.rootId : parent.id,
      strategy: dropdownValue,
      userId: userId,
    };

    dispatch(AddSite(payload, onClose)).then((nodeId) => {
      if (nodeId) {
        dispatch(setRootId(nodeId));
      }
    });
  };

  return (
    <>
      <div>
        <Dialog
          open={open}
          onClose={onClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: "2px solid #DADADA",
            }}
          >
            <span>
              {parent?.type === "edit" ? "Update Site" : "Add new Site"}
            </span>
            <IconButton
              edge="end"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <Divider />
          <DialogContent>
            {loading ? (
              <Grid container spacing={2} sx={{minWidth: '35.6rem',  minHeight: '16rem',  justifyContent: 'center', alignItems: 'center',}}>
               <Grid item xs={12}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                // height="100px"
              >
                <CircularProgress  size="2rem"
                    sx={{ color: "rgb(101, 77, 247)" }}/>
              </Box>
              </Grid>
              </Grid>
            ) : (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "300",
                        color: "#646464",
                      }}
                    >
                      Name
                    </Typography>
                    <FormControl
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&:hover fieldset": {
                            borderColor: "#654DF7",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#654DF7",
                          },
                          "& fieldset": {
                            borderColor: "transparent ",
                          },
                        },
                        width: "100%",
                        height: "40px",
                      }}
                    >
                      <OutlinedInput
                        id="outlined-adornment-name"
                        type="text"
                        placeholder="Enter site name here"
                        value={name}
                        error={nameError || nameLengthError}
                        onChange={handleNameChange}
                        autoComplete="off"
                        inputProps={{
                          style: { padding: 0, height: "24px" },
                        }}
                        sx={{
                          fontSize: "14px",
                          fontWeight: "300",
                          color: "#646464",
                          height: "40px",
                          border: "1.5px solid #DADADA ",
                          padding: "0px 14px",
                          boxSizing: "border-box",
                        }}
                      />
                    </FormControl>
                    {nameLengthError && (
                      <Typography color="error">
                        Name cannot have more than 50 characters*
                      </Typography>
                    )}
                    {Error.name && (
                      <Typography color="error" sx={{ fontSize: "10px" }}>
                        {Error.name}
                      </Typography>
                    )}
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "300",
                        color: "#646464",
                      }}
                    >
                      Enter the URL you want to automate:
                    </Typography>
                    <FormControl
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&:hover fieldset": {
                            borderColor: "#654DF7",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#654DF7",
                          },
                          "& fieldset": {
                            borderColor: "transparent ",
                          },
                        },
                        width: "100%",
                        height: "40px",
                      }}
                    >
                      <OutlinedInput
                        id="outlined-adornment-url"
                        type="text"
                        placeholder="Enter URL here"
                        value={url}
                        error={urlError || urlLengthError}
                        onChange={handleUrlChange}
                        readOnly={parent?.type === "edit"}
                        disabled={parent?.type === "edit"}
                        autoComplete="off"
                        inputProps={{
                          style: { padding: 0, height: "24px" },
                        }}
                        sx={{
                          fontSize: "14px",
                          fontWeight: "300",
                          color: "#646464",
                          height: "40px",
                          border: "1.5px solid #DADADA",
                          padding: "0px 14px",
                          height: "40px",
                          boxSizing: "border-box",
                        }}
                      />
                    </FormControl>
                    {Error.url && (
                      <Typography color="error" sx={{ fontSize: "10px" }}>
                        {Error.url}
                      </Typography>
                    )}
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "300",
                        color: "#646464",
                      }}
                    >
                      Select Type
                    </Typography>
                    <FormControl
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&:hover fieldset": {
                            borderColor: "#654DF7",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#654DF7",
                          },
                          "& fieldset": {
                            borderColor: "transparent ",
                          },
                        },
                        width: "100%",
                        height: "40px",
                      }}
                    >
                      <Select
                        value={dropdownValue}
                        onChange={handleDropdownChange}
                        disabled={parent?.type === "edit"}
                        displayEmpty
                        input={<OutlinedInput />}
                        sx={{
                          fontSize: "14px",
                          fontWeight: "300",
                          height: "40px",
                          border: "1.5px solid #DADADA ",
                          height: "40px",
                          boxSizing: "border-box",
                          color: "#646464",
                        }}
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              "& .MuiMenuItem-root": {
                                "&:hover": {
                                  backgroundColor: "rgb(101, 77, 247)",
                                  color: "white",
                                },
                                "&.Mui-selected": {
                                  backgroundColor: "rgb(101, 77, 247)",
                                  color: "white",
                                },
                                "&.Mui-selected:hover": {
                                  backgroundColor: "rgb(101, 77, 247)",
                                  color: "white",
                                },
                              },
                            },
                          },
                        }}
                      >
                        <MenuItem value="" disabled>
                          Select Crawl Length
                        </MenuItem>
                        {dropdownOptions.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {Error.dropdown && (
                      <Typography color="error" sx={{ fontSize: "10px" }}>
                        {Error.dropdown}
                      </Typography>
                    )}
                  </Box>
                </Grid>
              </Grid>
            )}
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <Button
              onClick={handleSubmit}
              style={{
                marginRight: "10px",
                backgroundColor: "#654DF7",
                height: "30px",
                textTransform: "none",
                color: "white",
              }}
            >
              {parent?.type === "edit" ? "Update" : "Add"}
            </Button>
            <Button
              onClick={onClose}
              color="primary"
              style={{
                width: "100px",
                height: "30px",
                border: "1.5px solid #654DF7",
                textTransform: "none",
                color: "#654DF7",
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}

export default AddSiteDetails;
