import React, { useEffect, useState } from "react";
import { Grid, Card } from "@material-ui/core";
import { Button } from "@mui/material";
import { useStyles } from "./styles";
import { CustomTable } from "./CustomTable";
import { useDispatch, useSelector } from "react-redux";
import SearchField from "../../../../../comman/SearchField";
import { getEnviornmentVariableLab } from "../../../../../redux/actions/settingAction";
import AddNewEnviornmentVariable from "./AddEnviornmentVariable";
import { useNavigate } from "react-router-dom";


export default function EnvironmentVariable_value_TestLab() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate=useNavigate();
  const { enviornment_variable_set_value,setselected } = useSelector((state) => state.settings);
  const [showAddNewTestUser, setShowAddNewTestUser] = useState(false);
  const [userEdit, setUserEdit] = useState(null);
  const [addOredit, setaddOredit] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const storedRow = localStorage.getItem('selectedRow');
  const parsedRow = storedRow ? JSON.parse(storedRow) : null;

  useEffect(() => {
    dispatch(getEnviornmentVariableLab(parsedRow));
  }, []);

  const handleBack = () => {
    setShowAddNewTestUser(false);
    setUserEdit(null);
  };

  const handleAddUser = () => {
    setShowAddNewTestUser(true);
    setaddOredit("Add");
  };

  const handleBackToRoot = () => {
    navigate("/settings/test-lab-enviornment-variable"); 
  };

  const handleEdit = (row) => {
    setUserEdit(row);
    setaddOredit("Edit");
    setShowAddNewTestUser(true);
  };
  const handleappenviornment_variable = (row) => {
    setUserEdit(row);
    setaddOredit("Add");
    setShowAddNewTestUser(true);
  };

  const filteredData = enviornment_variable_set_value
    ? enviornment_variable_set_value.filter((data) =>
        data?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase())
      )
    : [];

  return (
    <>
      <>
        {showAddNewTestUser ? (
          <AddNewEnviornmentVariable
            onBack={handleBack}
            addOredit={addOredit}
            userEdit={userEdit}
            setUserEdit={setUserEdit}
          />

        ) : (
          <Grid
            container
            className={classes.header}
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={6} className={classes.header}>
              <div className={classes.highlight}>Enviornment Variable </div>
            </Grid>
            <Grid item>
            <Button
                className={classes.button} 
                onClick={handleBackToRoot}
                sx={{
                  textTransform: "none",
                  backgroundColor: "rgb(108, 117, 125)",
                  color: "#f1f1f1",
                  "&:hover": {
                    backgroundColor: "rgb(101, 77, 247)",
                  },
                  marginRight: "10px",
                }}
              >
                Back
              </Button>
              <Button
                className={classes.button}
                onClick={handleAddUser}
                sx={{
                  backgroundColor: "rgb(101, 77, 247)",
                  "&:hover": {
                    backgroundColor: "rgb(101, 77, 247) !important",
                    borderColor: "#654DF7",
                    color: "#fff",
                    "&:before": {
                      backgroundColor: "rgb(101, 77, 247) !important",
                      color: "#fff",
                    },
                  },
                  color: "#fff",
                }}
              >
                Add New Variable
              </Button>
            </Grid>
          </Grid>
        )}

        {/* Body */}

        {!showAddNewTestUser && (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12}>
              <Card style={{ textAlign: "center", margin: "20px" }}>
                <Grid item style={{ margin: "8px 20px" }}>
                  <SearchField
                    placeholder="Search Value Name"
                    onChange={(value) => setSearchTerm(value)}
                  />
                </Grid>
                <Grid item>
                  <CustomTable
                    rows={filteredData}
                    handleEditUser={handleEdit}
                  />
                </Grid>
              </Card>
            </Grid>
          </Grid>
        )}
      </>
    </>
  );
}