import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { useStyles } from "./styles";
import {
  DashBoardIcon,
  LocalTestingIcon,
  TestLabIcon,
  GenieTestingIcon,
} from "../comman/icons";
import { useFeatureFlagEnabled } from 'posthog-js/react'

export default function NavBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const classess = useStyles();
  
  const showGhostLab = useFeatureFlagEnabled('Production-GhostLab')
  const showGhostAI = useFeatureFlagEnabled('Production-GhostAI')
  const showGhostReady = useFeatureFlagEnabled('Production-GhostReady')
  
  const Paths = [
    {
      title: "Dashboard",
      icon: <DashBoardIcon />,
      path: "/",
    },
    {
      title: "GhostReady",
      icon: <LocalTestingIcon />,
      path: "/ghost-ready",
      show: showGhostReady,
    },
    {
      title: "GhostLab",
      icon: <TestLabIcon />,
      path: "/ghostLab",
      show: showGhostLab,
    },
    {
      title: "GhostAI",
      icon: <GenieTestingIcon />,
      path: "/GhostAI",
      show: showGhostAI,
    },
  ].filter(path => path.show !== false);

  return (
    <List className={classess.navMaintop}>
      {Paths.map((path, index) => {
        // Exact match for Dashboard path
        const isActive =
          path.path === "/"
            ? location.pathname === "/"
            : location.pathname.startsWith(path.path);

        return (
          <ListItem
            className={classess.navListItem}
            style={{
              backgroundColor: isActive ? "rgb(101, 77, 247)" : null,
              cursor: 'pointer',
            }}
            key={index}
            onClick={() => {
              navigate(path.path);
            }}
          >
            <ListItemIcon className={classess.icon} style={{ minWidth: 0 }}>
              {React.cloneElement(path.icon, {
                color: isActive ? "white" : "rgb(101, 77, 247)",
                isActive: isActive,
              })}
            </ListItemIcon>
            <ListItemText
              primary={path.title}
              className={`${classess.navTitle} ${
                isActive ? classess.activeClass : ""
              }`}
            />
          </ListItem>
        );
      })}
    </List>
  );
}
