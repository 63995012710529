import React, { useState } from "react";
import { useSelector } from "react-redux";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
import VideocamIcon from "@mui/icons-material/Videocam";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import UpgradeModal from "../../../../../comman/Accordion/UpgradePlan/UpgradeModal";

const CustomVideoChell = ({ imgUrl, isSelected }) => {
  const [openModal, setOpenModal] = useState(false);
  const [openUpgradeModal, setOpenUpgradeModal] = useState(false);
  const { featurePlan } = useSelector((state) => state.auth);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenUpgradeModal = () => {
    setOpenUpgradeModal(true);
  };

  const handleCloseUpgradeModal = () => {
    setOpenUpgradeModal(false);
  };

  const feature = featurePlan.find(
    (feature) => feature.name === "GhostLab-Execution-Recording"
  );

  const isFeatureActive = feature && feature.limits.limit.is_active;

  const handleDownload = async () => {
    try {
      const response = await fetch(imgUrl, { mode: "cors" });
      if (!response.ok)
        throw new Error(`Failed to fetch video: ${response.statusText}`);

      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = "video.webm";
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Error downloading the video:", error);
    }
  };

  return (
    <>
      <VideocamIcon
        style={{
          color: isSelected
            ? "white"
            : isFeatureActive
            ? "rgb(25, 118, 210)"
            : "gray",
          cursor: isFeatureActive ? "pointer" : "not-allowed",
        }}
        onClick={isFeatureActive ? handleOpenModal : handleOpenUpgradeModal}
      />
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="image-modal-title"
        aria-describedby="image-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            borderRadius: "8px",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              maxWidth: "60vw",
              position: "relative",
            }}
          >
            <div style={{ position: "relative" }}>
              <video
                autoPlay
                muted
                controls
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              >
                <source src={imgUrl} type="video/webm" />
              </video>
              {/* Download Icon - Left of Volume Icon */}
              <Box
                sx={{
                  position: "absolute",
                  bottom: "40px", // Adjust to align with volume icon
                  right: "150px", // Position left of the volume button
                  zIndex: 9999, // Higher z-index to keep it above fullscreen video
                  cursor: "pointer",
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "50%",
                  padding: "5px",
                  transition:
                    "box-shadow 0.3s ease, background-color 0.3s ease", // Smooth transition
                  "&:hover": {
                    boxShadow: "0 4px 8px black", // Fully black shadow on hover
                    backgroundColor: "rgba(0, 0, 0, 0.6)", // Blackish background on hover
                  },
                }}
                onClick={handleDownload}
              >
                <DownloadIcon fontSize="small" />
              </Box>
            </div>
            {/* Close Icon */}
            <Box
              onClick={handleCloseModal}
              sx={{
                cursor: "pointer",
                position: "absolute",
                top: "10px",
                right: "10px",
                color: "#654DF7",
              }}
            >
              <ClearOutlinedIcon />
            </Box>
          </Box>
        </div>
      </Modal>
      <UpgradeModal open={openUpgradeModal} onClose={handleCloseUpgradeModal} />
    </>
  );
};

export default CustomVideoChell;
