import React from 'react';
import { Routes, Route } from 'react-router-dom';
import AccessibilityNavBar from '../../Layout/AccessibilityNavBar';
import { Box,Card } from '@mui/material';
import Accessibility from './Accessibility';
import Audit from './Audit';


const AccessibilityPage = () => {
  return (
    <Box display="flex">
      <AccessibilityNavBar />
      <Box flexGrow={1} pt={2}>
        <Card sx={{ height: 'calc(100vh - 100px)',  width: 'calc(100% - 20px)',}}>
        <Routes>
          <Route path="/" element={<Accessibility />} />
          <Route path="audit" element={<Audit />} />
        </Routes>
        </Card>
      </Box>
    </Box>
  );
};

export default AccessibilityPage;
