import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Tooltip } from "@mui/material";
import { useTableStyles, StyledTableCell } from "./styles";
import { Edit as EditIcon, Delete as DeleteIcon } from "@material-ui/icons";
import useAdvanceAutomation from "../../../../hooks/useAdvanceAutomation";
import JourneyModal from "../Modal/JourneyModal";

export function JourneyTable({ rows, rootId }) {
  const classes = useTableStyles();
  const { handleDetails } = useAdvanceAutomation();

  const [modalOpen, setModalOpen] = useState(false);
  const [isDeleteMode, setIsDeleteMode] = useState(true);
  const [selectedJourney, setSelectedJourney] = useState(null);

  const openModal = (journey, mode) => {
    setSelectedJourney(journey);
    setIsDeleteMode(mode === "delete");
    setModalOpen(true);
  };

  const handleDelete = (journey) => {
    openModal(journey, "delete");
  };

  const handleEdit = (journey) => {
    openModal(journey, "edit");
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedJourney(null);
  };

  return (
    <>
      <TableContainer sx={{ marginBottom: "8vh" }}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell isFirstColumn>Name</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Created On</StyledTableCell>
              <StyledTableCell>Pages</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>
              <StyledTableCell>Test Cases</StyledTableCell>
              <StyledTableCell isLastColumn>Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.ApplicationName}
                className={`${classes.tableRow}`}
                style={{ height: "10px" }}
                spacing="3"
              >
                <StyledTableCell
                  isFirstColumn
                  onClick={
                    row.status !== "In Progress"
                      ? () => handleDetails(row.id)
                      : undefined
                  }
                  style={{
                    cursor: row.status !== "In Progress" ? "pointer" : "default",
                  }}
                >
                  {row.status == "In Progress" ? (
                    <Tooltip title="Journey is still in progress" arrow>
                      {row.ApplicationName.length > 20 ? (
                          <span>
                            {row.ApplicationName.substring(0, 20) + "..."}
                          </span>
                      ) : (
                        row.ApplicationName
                      )}
                    </Tooltip>
                  ) : (
                    <>
                    {row.ApplicationName.length > 20 ? (
                      <Tooltip title={row.ApplicationName} arrow>
                        <span>{row.ApplicationName.substring(0, 20) + '...'}</span>
                      </Tooltip>
                    ) : (
                      row.ApplicationName
                    )}
                    </>
                  )}
                </StyledTableCell>
                <StyledTableCell>{row.status}</StyledTableCell>
                <StyledTableCell>{row.createdOn}</StyledTableCell>
                <StyledTableCell>{row.pages}</StyledTableCell>
                <StyledTableCell>{row.action}</StyledTableCell>
                <StyledTableCell>{row.testCases}</StyledTableCell>
                <StyledTableCell isLastColumn>
                  <EditIcon
                    style={{
                      cursor: "pointer",
                      marginRight: "10px",
                      color: "rgb(101, 77, 247)",
                    }}
                    onClick={() => handleEdit(row)}
                  />
                  <DeleteIcon
                    style={{
                      cursor: "pointer",
                      marginRight: "10px",
                      color: "#F64E4E",
                    }}
                    onClick={() => handleDelete(row)}
                  />
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {modalOpen && (
        <JourneyModal
          open={modalOpen}
          onClose={handleModalClose}
          journey={selectedJourney}
          isDeleteMode={isDeleteMode}
        />
      )}
    </>
  );
}
