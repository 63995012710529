import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
// import {
//   // DeleteApplication,
//   DeleteBrowser,
//   DeleteEnvironment,
//   DeleteUser,
// } from "../../../../redux/actions/settingAction";
import { useDispatch } from "react-redux";
import {
  DeleteApplication,
  DeleteApplication_testlab,
  DeleteBrowser,
  DeleteBrowser_testlab,
  DeleteEnviornmentVariableSetstestlab,
  DeleteEnviornmentVariabletestlab,
  DeleteEnvironment,
  DeleteEnvironment_testlab,
  DeleteUser,
  DeleteUser_testlab,
} from "../../../../../redux/actions/settingAction";
import { DeleteSelector } from "../../../../../redux/actions/testlab/selectorAction";
import { DeleteTestData } from "../../../../../redux/actions/testlab/testdataAction";

function DeleteModal({ open, onClose, item, types }) {
  const dispatch = useDispatch();
  const [name, setname] = useState("");
  const [isSecondModalOpen, setIsSecondModalOpen] = useState(false);
  const [linkedTestSuite, setlinkedTestSuite] = useState("");

  useEffect(() => {
    if (item) {
      setname(
        types === "application"
          ? item.ApplicationName
          : types === "environment"
          ? item.EnvironmentName
          : types === "enviornment_variable_testlab"
          ? item.EnvironmentName 
          : types === "application(TestLab)"
          ? item.ApplicationName
          :types === "browser"
          ? item.BrowserName
          : types === "user"
          ? item.UserName
          : types === "user(TestLab)"
          ? item.UserName
          :types === "selector"
          ? item.id
          : types === "testdata"
          ? item.id
          :  types === "browser(TestLab)"
          ? item.id
          : types === "variable"
          ? item.id
          : ""
      );
    }
  }, [item, types]);

  const handleDelete = async () => {
    console.log("types",types);
    try {
      if (types === "application") {
        const response = await dispatch(DeleteApplication(item.ApplicationId));
        setlinkedTestSuite(response.message);
        if (response.status === "fail") {
          setIsSecondModalOpen(true);
          onClose();
        } else {
          onClose();
        }
      } else if (types === "environment") {
        const response = await dispatch(DeleteEnvironment_testlab(item.EnvironmentId));
        // const response = await dispatch(DeleteEnvironment(item.EnvironmentId));
        setlinkedTestSuite(response.message);
        if (response.status === "fail") {
          // setIsSecondModalOpen(true);
          onClose();
        } else {
          onClose();
        }
      }
      else if (types === "enviornment_variable_testlab") {
        console.log("items",item);
        const response = await dispatch(DeleteEnviornmentVariableSetstestlab(item.id))
        // const response = await dispatch(DeleteEnvironment_testlab(item.id));
        setlinkedTestSuite(response.message);
        if (response.status === "fail") {
          // setIsSecondModalOpen(true);
          onClose();
        } else {
          onClose();
        }
      }
      else if (types === "application(TestLab)") {
        const response = await dispatch(DeleteApplication_testlab(item.ApplicationId));
        setlinkedTestSuite(response.message);
        if (response.status === "fail") {
          setIsSecondModalOpen(true);
          onClose();
        } else {
          onClose();
        }
      }else if (types === "user") {
        const response = await dispatch(DeleteUser(item.UserId));
        setlinkedTestSuite(response.message);
        if (response.status === "fail") {
          // setIsSecondModalOpen(true);
          onClose();
        } else {
          onClose();
        }
        onClose();
      }else if (types === "user(TestLab)") {
        console.log("item", item);
        const response = await dispatch(DeleteUser_testlab(item.UserId));
        setlinkedTestSuite(response.message);
        if (response.status === "fail") {
          // setIsSecondModalOpen(true);
          onClose();
        } else {
          onClose();
        }
        onClose();
      }
      else if (types === "selector") {
        const response = await dispatch(DeleteSelector(item.Id));
        setlinkedTestSuite(response.message);
        if (response.status === "fail") {
          setIsSecondModalOpen(true);
          onClose();
        } else {
          onClose();
        }
      } else if (types === "testdata") {
        const response = await dispatch(DeleteTestData(item.Id));
        setlinkedTestSuite(response.message);
        if (response.status === "fail") {
          setIsSecondModalOpen(true);
          onClose();
        } else {
          onClose();
        }
      }  else if (types === "browser(TestLab)") {
        const response = await dispatch(DeleteBrowser_testlab(item.BrowserId));
        setlinkedTestSuite(response.message);
        if (response.status === "fail") {
          setIsSecondModalOpen(true);
          onClose();
        } else {
          onClose();
        }
      } else if (types === "variable") {
        const response = await dispatch(DeleteEnviornmentVariabletestlab(item));
        setlinkedTestSuite(response.message);
        if (response.status === "fail") {
          onClose();
        } else {
          onClose();
        }
      }
       else {
        const response = await dispatch(DeleteBrowser(item.BrowserId));
        setlinkedTestSuite(response.message);
        if (response.status === "fail") {
          setIsSecondModalOpen(true);
          onClose();
        } else {
          onClose();
        }
      }
    } catch (error) {
      console.error("Error handling delete:", error);
    }
  };
  return (
    <div>
      {open && (
        <Dialog
          open={open}
          onClose={onClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Delete Confirmation"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
             Are you sure you want to delete this ?
            </DialogContentText>
          </DialogContent>
          <DialogActions
            style={{ justifyContent: "center", marginBottom: "20px" }}
          >
            <Button
              onClick={handleDelete}
              style={{
                marginRight: "10px",
                backgroundColor: "#654DF7",
                height: "30px",
                width: "100px",
                color: "white",
              }}
            >
              Delete
            </Button>
            <Button
              onClick={onClose}
              color="primary"
              style={{
                backgroundColor: "#6c757d",
                width: "100px",
                height: "30px",
                color: "white",
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {isSecondModalOpen && (
        <Dialog
          open={isSecondModalOpen}
          onClose={() => setIsSecondModalOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Alert"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this {types} <b>{name}</b>? It is
              linked to a <b> {linkedTestSuite} </b> test suite, and you will
              have to update the test suites before deleting it.
            </DialogContentText>
          </DialogContent>
          <DialogActions
            style={{ justifyContent: "center", marginBottom: "20px" }}
          >
            <Button
              onClick={() => {
                setIsSecondModalOpen(false);
                onClose();
              }}
              style={{
                marginRight: "10px",
                backgroundColor: "#654DF7",
                height: "30px",
                width: "100px",
                color: "white",
              }}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}

export default DeleteModal;
