import React, { useState, useEffect } from "react";
import { Grid, Card, Button, Box } from "@mui/material";
import { useStyles } from "./styles";
import { Add } from "@mui/icons-material/";
import { DrawerOpenIcon, DrawerClosedIcon } from "../../../comman/icons";
import clsx from "clsx";
import AddNewWorkspace from "./AddWorkspace";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchWorkSpaces,
  AddUpdateWorkspace,
  setRootId
} from "../../../redux/actions/Accessibility/AuditAction";
import DynamicTreeView from "./DynamicTreeView";
import SitesList from "./Comman/SitesList";

export default function Audit() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { userId } = useSelector((state) => state.auth);
  const [addNewProject, setAddNewProject] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [formData, setFormData] = useState({ name: "" });
  const treeStyle = drawerOpen ? {} : { display: "none" };  
  const [depth, setdepth] = useState(0);

  const handleCancel = () => {
    setAddNewProject(false);
    setFormData({ name: "" });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ name: value, id: Math.random(), parentId: 0 });
  };

  useEffect(() => {
    dispatch(fetchWorkSpaces());
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!formData.name.trim()) {
      toast.error("Whitespace is not allowed.");
      return;
    }
    try {
      const payload = {
        id: 0,
        parent: formData.parentId,
        name: formData.name,
        isWorkspace: true,
        isProject: false,
        userId:userId
      };
      const response = await dispatch(AddUpdateWorkspace(payload));
      setFormData({ name: "" });
      setAddNewProject(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleList = (id, node) => {
      setdepth(node);
      setAddNewProject(false);
      dispatch(setRootId(id))
    };

  return (
    <>
      <Box className={classes.main}>
        <Grid container spacing={2}>
          <Box
            onClick={() => setDrawerOpen(!drawerOpen)}
            sx={{ position: "absolute", cursor: "pointer", marginTop: "10px", marginLeft: "10px" }}
          >
            {!drawerOpen && <DrawerClosedIcon />}
          </Box>

          <Grid item xs={12} md={3} xl={3} style={treeStyle}>
            <Card
              className={classes.card}
              sx={{ paddingBottom: "30px", position: "relative" }}
            >
              <Grid
                container
                alignItems="center"
                className={classes.bodyHeader}
              >
                <Grid container xs={12} sx={{ paddingRight: "8px" }}>
                  <Grid item xs={9} sx={{ alignContent: "center" }}>
                    Workspaces
                  </Grid>
                  <Grid item xs={3} sx={{ textAlign: "right" }}>
                    {addNewProject ? (
                      <Button
                        variant="contained"
                        onClick={handleCancel}
                        sx={{
                          fontSize: "0.85rem",
                          backgroundColor: "rgb(101, 77, 247)",
                          color: "#ffffff",
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: "rgb(101, 77, 247)", 
                          },
                        }}
                      >
                        Cancel
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        onClick={() => setAddNewProject(true)}
                        sx={{
                          fontSize: "0.75rem",
                          backgroundColor: "rgb(101, 77, 247)",
                          color: "#ffffff",
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: "rgb(101, 77, 247)",
                          },
                        }}
                      >
                        <Add />
                      </Button>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  {addNewProject && (
                    <AddNewWorkspace
                      handleChange={handleChange}
                      handleSubmit={handleSubmit}
                      formData={formData}
                    />
                  )}
                </Grid>
              </Grid>
              <Grid className={clsx(classes.bodyTree, classes.bodyTreeheight)}>
                <DynamicTreeView WorkspaceHandle={handleList}/>
              </Grid>
              <Grid item xs={12} sx={{ marginBottom: "10px" }}>
                <Box
                  sx={{
                    width: "100%",
                    height: "2px",
                    backgroundColor: "#DADADA",
                    padding: "0px 0px",
                  }}
                />
              </Grid>
              <Grid
                item
                xs={1}
                sx={{ position: "absolute", right: "28px", bottom: "5px" }}
              >
                <Box
                  onClick={() => setDrawerOpen(!drawerOpen)}
                  sx={{ cursor: "pointer" }}
                >
                  {drawerOpen && <DrawerOpenIcon />}
                </Box>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} md={drawerOpen ? 9 : 12} xl={9}>
             { depth >= 2 && <SitesList depth={depth} />}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
