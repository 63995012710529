import { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { StyledTypography } from "./styleTestCase";
import CustomeTableChell from "./CustomeTableChell";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

export default function Rundetail({ runIdDetails }) {
    const [value, setValue] = useState("screenshot");

    let parsedDetail = null;
    if (runIdDetails && runIdDetails.length > 0) {
        const testScreenShotUrl = runIdDetails[0]?.TestScreenShotUrl;

        try {
            // Attempt to parse as JSON
            parsedDetail = JSON.parse(testScreenShotUrl);
        } catch (error) {
            console.warn("TestScreenShotUrl is not JSON. Treating it as a plain string.");
            // Handle as a plain URL
            if (testScreenShotUrl) {
                parsedDetail = [
                    {
                        type: "screenshot",
                        files: testScreenShotUrl, // Wrap in array format for consistency
                    },
                ];
            }
        }
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box>
            <Tabs
                value={value}
                onChange={handleChange}
                sx={{
                    "& .MuiTabs-indicator": {
                        backgroundColor: "rgb(101, 77, 247)",
                    },
                    "& .Mui-selected": {
                        color: "rgb(101, 77, 247)",
                    },
                }}
            >
                <Tab
                    value="screenshot"
                    label={
                        <StyledTypography style={{ textTransform: "capitalize" }}>
                            Screenshots
                        </StyledTypography>
                    }
                />
                {/* <Tab
                    value="logs"
                    label={
                        <StyledTypography style={{ textTransform: "capitalize" }}>
                            Logs
                        </StyledTypography>
                    }
                /> */}
            </Tabs>

            <TabPanel value={value} index={"screenshot"}>
                {parsedDetail &&
                    Array.isArray(parsedDetail) &&
                    parsedDetail.map((item, index) => {
                        if (item.type === "screenshot") {
                            return (
                                <CustomeTableChell
                                    key={index}
                                    ScreenshotUrl={item.files}
                                />
                            );
                        }
                        return null;
                    })}
            </TabPanel>
            {/* <TabPanel value={value} index={"logs"}>
                <div id="output"></div>
            </TabPanel> */}
        </Box>
    );
}
