import React, { useState, useEffect, useRef } from "react";
import { Card } from "@material-ui/core";
import SearchField from "../../../../../comman/SearchField"; // Ensure this import is present
import { List, ListItem, ListItemText } from "@mui/material"; // Add necessary imports
import {
  Box,
  Button,
  Grid,
  Checkbox,
  Typography,
} from "@mui/material";
import {
  StyledListItem,
  StyledOutlinedInput,
  StyledTableCell,
  StyledTypography,
  useStyles,
} from "./style";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { GetCompareGraphDataById } from "../../../../../redux/actions/performanceAction";
import AverageResponseTimeChart from "./Graph/AverageResponseTimeChart";
import AverageHitsChart from "./Graph/AverageHitsChart";
import AverageLatencyChart from "./Graph/AverageLatencyChart";
import AverageBandwidthChart from "./Graph/AverageBandwidthChart";
import NinetyPercentileChart from "./Graph/90thPercentileChart";
import UsersChart from "./Graph/UsersChart";
import ErrorPercentageChart from "./Graph/ErrorPercentageChart"; 

export default function CompareGraph() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { runsId } = useParams();
  const [selectedCard, setSelectedCard] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [showTestRuns, setShowTestRuns] = useState(false);
  const { graphData } = useSelector((state) => state.performance);
  const [combinedTrendGraphsData, setCombinedTrendGraphsData] = useState([]); 
  const listRef = useRef(null);

  useEffect(() => {
    if (runsId) {
      dispatch(GetCompareGraphDataById(runsId));
    }
  }, [runsId]); 

  useEffect(() => {
    if (graphData && graphData.data) { 
      setCombinedTrendGraphsData(graphData.data); 
    } 
  }, [graphData]);

  const [checkedItems, setCheckedItems] = useState(
    combinedTrendGraphsData.map(() => true) 
  );

  useEffect(() => {
    if (combinedTrendGraphsData.length > 0) {
      setCheckedItems(Array(combinedTrendGraphsData.length).fill(true));
    }
  }, [combinedTrendGraphsData]); 
  
  const testRuns = combinedTrendGraphsData.map(run => run.runId);
  const FilteredData = testRuns.filter((run) =>
    run.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      const searchField = document.getElementById("search-field");
      if (
        searchField && !searchField.contains(event.target) && 
        listRef.current && !listRef.current.contains(event.target)
      ) {
        setShowTestRuns(false); 
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleCardClick = (cardValue) => {
    setSelectedCard(cardValue);
  };

  const calculateChartHeight = () => {
    const parentContainer = document.getElementById("line-container");
    const parentContainerHeight = parentContainer
      ? parentContainer.clientHeight
      : window.innerHeight;
    const desiredPercentage = 50;
    const calculatedHeight = `${
      (parentContainerHeight * desiredPercentage) / 100
    }px`;
    return calculatedHeight;
  };

  const handleListItemClick = (index) => {
    const newCheckedItems = [...checkedItems];
    newCheckedItems[index] = !newCheckedItems[index];
    setCheckedItems(newCheckedItems);
  };

  const renderChart = () => {
    const chartData = combinedTrendGraphsData
      .filter((run, index) => checkedItems[index]) 
      .map(run => ({
        runId: run.runId,
        id: run.id,
        colour: run.colour,
        totalUser: run.totalUser,
        responseData: run.responseData.map(item => ({
          timeStamp: item.timeStamp,
          elapsed: item.elapsed,
          label: item.label,
          responseCode: item.responseCode,
          responseMessage: item.responseMessage,
          threadName: item.threadName,
          dataType: item.dataType,
          success: item.success,
          failureMessage: item.failureMessage,
          bytes: item.bytes,
          sentBytes: item.sentBytes,
          grpThreads: item.grpThreads,
          allThreads: item.allThreads,
          url: item.url,
          latency: item.latency,
          idleTime: item.idleTime,
          connect: item.connect,
          hits_Per_Second: item.hits_Per_Second,
          percentile_50: item.percentile_50,
          percentile_90: item.percentile_90,
          percentile_95: item.percentile_95,
          percentile_99: item.percentile_99,
          errors_Per_Second: item.errors_Per_Second,
          bandwidth: item.bandwidth,
        })),
      }));
  
    switch (selectedCard) {
      case 1:
        return (
          <AverageResponseTimeChart
            height={calculateChartHeight()}
            fontSize="16px"
            data={chartData} 
            isPreview={false}
          />
        );
      case 2:
        return (
          <AverageHitsChart
            height={calculateChartHeight()}
            fontSize="16px"
            data={chartData} 
            isPreview={false}
          />
        );
      case 3:
        return (
          <AverageLatencyChart
            height={calculateChartHeight()}
            fontSize="16px"
            data={chartData} 
            isPreview={false}
          />
        );
      case 4:
        return (
          <AverageBandwidthChart
            height={calculateChartHeight()}
            fontSize="16px"
            data={chartData} 
            isPreview={false}
          />
        );
      case 5:
        return (
          <NinetyPercentileChart
            height={calculateChartHeight()}
            fontSize="16px"
            data={chartData} 
            isPreview={false}
          />
        );
      case 6:
        return (
          <UsersChart
            height={calculateChartHeight()}
            fontSize="16px"
            data={chartData} 
            isPreview={false}
          />
        );
      case 7:
        return (
          <ErrorPercentageChart
            height={calculateChartHeight()}
            fontSize="16px"
            data={chartData} 
            isPreview={false}
          />
        );
      default:
        return null;
    }
  };

  const graphTitles = {
    1: "Response Time",
    2: "Hits /s",
    3: "Latency",
    4: "Bandwidth",
    5: "90th Percentile",
    6: "Users",
    7: "Errors /s",
  };

  return (
    <>
      <Grid>
        {/* main compoent */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              mb: 2,
              mt: 2,
              ml: 2,
              fontFamily: "Lexend Deca",
              fontWeight: 400,
              color: "#2e2e2e",
            }}
          >
            Test Run Comparison
          </Typography>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button
              style={{
                background: "rgb(101, 77, 247)",
                padding: "10px 15px",
                marginLeft: "20px",
                marginRight: "20px",
                color: "white",
                textTransform: "none",
                fontSize: "14px !important",
                "&:hover": {
                  background: "rgb(101, 77, 247)",
                },
              }}
              onClick={() => {
                navigate(-1);
              }}
            >
              <ArrowBackIcon fontSize="small" />
              Back
            </Button>
          </div>
        </div>
        <Card
          style={{
            width: "100%",
            marginBottom: "20px",
            overflow: "hidden",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Typography
              variant="h4"
              sx={{
                mb: 2,
                mt: 2,
                ml: 9,
                fontFamily: "Lexend Deca",
                fontWeight: 400,
                color: "#2e2e2e",
              }}
            >
              {graphTitles[selectedCard]}
            </Typography>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", position: 'relative' }}>
              <SearchField
                id="search-field"
                placeholder=" Select Test Runs"
                onFocus={() => setShowTestRuns(true)}
                onChange={(value) => setSearchTerm(value)}
                style={{ width: '300px', height: '40px', boxSizing: 'border-box', marginRight: '20px' }}
              />
              {showTestRuns && (
                <Box 
                  ref={listRef}
                  style={{ 
                    maxHeight: "200px", 
                    overflow: "hidden", 
                    width: '300px', 
                    position: 'absolute', 
                    top: '40px', 
                    zIndex: 1000, 
                    backgroundColor: 'white', 
                    border: '1px solid #ccc', 
                  }}
                >
                  <List>
                    {FilteredData.map((run, index) => (
                      <StyledListItem key={index} onClick={() => handleListItemClick(index)}>
                        <Checkbox
                          checked={checkedItems[index]}
                          style={{ color: "#654DF7" }}
                          size="small"
                        />
                        <ListItemText>
                          <StyledTypography>{run}</StyledTypography>
                        </ListItemText>
                      </StyledListItem>
                    ))}
                  </List>
                </Box>
              )}
            </div>
          </div>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
              <Card
                style={{
                  width: "calc(100% - 60px)",
                  marginTop: "20px",
                  marginBottom: "20px",
                  marginLeft: "30px", 
                  marginRight: "30px", 
                  overflow: "hidden",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", 
                }}
              >
                <div style={{ width: "100%", height: "100%" }}>
                  {renderChart()}
                </div>
              </Card>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1}
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              gap: "2px",
              marginTop: "3vh",
              marginLeft: "20px",
              marginRight: "30px",
              paddingBottom: "30px",
            }}
          >
            {[1, 2, 3, 4, 5, 6, 7].map((cardValue) => (
              <Grid item xs={12} sm={2} key={cardValue}>
                <Card
                  style={{
                    width: "calc(100% - 50px)",
                    height: "20vh",
                    overflow: "clip",
                    padding: "0px",
                    cursor: "pointer",
                    border: `2px solid ${
                      selectedCard === cardValue ? "#654DF7" : "#FFFFFF"
                    }`,
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", 
                  }}
                  onClick={() => handleCardClick(cardValue)}
                >
                  <div>
                    {cardValue === 1 && (
                      <AverageResponseTimeChart
                        height={135}
                        fontSize="8px"
                        data={combinedTrendGraphsData}
                        isPreview={true}
                      />
                    )}
                    {cardValue === 2 && (
                      <AverageHitsChart
                        height={135}
                        fontSize="8px"
                        data={combinedTrendGraphsData}
                        isPreview={true}
                      />
                    )}
                    {cardValue === 3 && (
                      <AverageLatencyChart
                        height={135}
                        fontSize="8px"
                        data={combinedTrendGraphsData}
                        isPreview={true}
                      />
                    )}
                    {cardValue === 4 && (
                      <AverageBandwidthChart
                        height={135}
                        fontSize="8px"
                        data={combinedTrendGraphsData}
                        isPreview={true}
                      />
                    )}
                    {cardValue === 5 && (
                      <NinetyPercentileChart
                        height={135}
                        fontSize="8px"
                        data={combinedTrendGraphsData}
                        isPreview={true}
                      />
                    )}
                    {cardValue === 6 && (
                      <UsersChart
                        height={135}
                        fontSize="8px"
                        data={combinedTrendGraphsData}
                        isPreview={true}
                      />
                    )}
                    {cardValue === 7 && (
                      <ErrorPercentageChart
                        height={135}
                        fontSize="8px"
                        data={combinedTrendGraphsData}
                        isPreview={true}
                      />
                    )}
                  </div>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Card>
      </Grid>
    </>
  );
}
