import axios from "axios";
import { toast } from "react-toastify";
import { header } from "../../../utils/authheader";
import { getBaseUrl } from "../../../utils/configService";

export const TEST_RUN = "TEST_RUN";
export const FETCH_SCHEDULE_DETAILS_REQUEST = "FETCH_SCHEDULE_DETAILS_REQUEST";
export const FETCH_SCHEDULE_DETAILS_SUCCESS = "FETCH_SCHEDULE_DETAILS_SUCCESS";
export const FETCH_SCHEDULE_DETAILS_FAILURE = "FETCH_SCHEDULE_DETAILS_FAILURE";
export const EDIT_TEST_RUN = "EDIT_TEST_RUN";
export const EXECUTE_TEST_START = "EXECUTE_TEST_START";
export const EXECUTE_TEST_SUCCESS = "EXECUTE_TEST_SUCCESS";
export const EXECUTE_TEST_FAILURE = "EXECUTE_TEST_FAILURE";

const notify = (type, message) => {
  const options = {
    style: {
      background: "rgb(101, 77, 247)",
      color: "rgb(255, 255, 255)",
    },
  };
  if (type === "success") {
    toast.info(message, options);
  } else {
   // toast.error(message);
  }
};

export const AddTestRunDetail = (payload) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const res = await axios.post(
        `${BASE_URL}/AddTestLab/SaveUpdateTestLabTestRun`,
        payload,
        header()
      );
      const Data = res.data.Data;
      if (res.data?.status && res.data.status.trim().toLowerCase() === "success") {
          toast.info(res.data.message, {
            style: {
              background: "rgb(101, 77, 247)",
              color: "rgb(255, 255, 255)",
            },
          });
      } else {
       // toast.error(res.data.message);
      }
    } catch (error) {
      console.log("error saving ", error);
     // toast.error(error.response?.data?.message || "NETWORK ERROR")
    }
  };
};

export const fetchTestRun = (selectedNodeId) => async (dispatch) => {
  try {
    const BASE_URL = await getBaseUrl();
    const response = await axios.get(
      `${BASE_URL}/AddTestLab/GetTestRunListByRootId?RootId=${selectedNodeId}`,
      header()
    );

    if (response.data.status === "fail" || response.data === "") {
      dispatch({ type: TEST_RUN, payload: [] });
    } else {
      const reversedTestCaseList = response.data?.data ? JSON.parse(response.data.data).reverse():[];
      dispatch({
        type: TEST_RUN,
        payload: reversedTestCaseList,
      });
    }
  } catch (error) {
    console.error("Error fetching data:", error);
   // toast.error(error.response?.data?.message || "NETWORK ERROR")

  }
};

export const deleteTestRun =
  (testId, nodeid, setopenDelModal) => async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const response = await axios.post(
        `${BASE_URL}/AddTestLab/DeleteTestLabTestRunById`,
        { id: testId },
        header()
      );

      if (response.data?.status && response.data.status.trim().toLowerCase() === "success") {
        notify("success", "Successfully deleted");
        dispatch(fetchTestRun(nodeid));
        setopenDelModal(false);
      }
    } catch (error) {
      console.error("Error deleting test case:", error);
     // toast.error(error.response?.data?.message || "NETWORK ERROR")
      setopenDelModal(false);
    }
  };

export const SchedulingTestRun = (scheduleData, onClose, clearFormFields) => {
  return async (dispatch) => {
    try {
      const BASE_URL = await getBaseUrl();
      const response = await axios.post(
        `${BASE_URL}/Scheduler/TestLabAllTypeScheduling`,
        scheduleData,
        header()
      );
      if (response.status === 200) {
        toast.info(response.data.message, {
          style: {
            background: "rgb(101, 77, 247)",
            color: "rgb(255, 255, 255)",
          },
        });
        if (typeof onClose === 'function') onClose();
        if (typeof clearFormFields === 'function') clearFormFields();
      } else {
        console.log("Error saving suite schedule");
        if (typeof clearFormFields === 'function') clearFormFields();
      }
    } catch (error) {
      console.error("Error saving suite schedule:", error);
      if (typeof clearFormFields === 'function') clearFormFields();
     // toast.error(error.response?.data?.message || "NETWORK ERROR")
    }
  };
};


export const fetchScheduleDetails =
  (rootId, suiteName, clearFormFields) => async (dispatch) => {
    dispatch({ type: FETCH_SCHEDULE_DETAILS_REQUEST });
    try {
      const BASE_URL = await getBaseUrl();
      const response = await axios.get(
        `${BASE_URL}/Scheduler/GetSuiteScheduleDetailsByRootId?rootId=${rootId}&suiteName=${suiteName}&schedulerType=TestRun`,
        header()
      );
      if (response.data && response.data.length > 0) {
        dispatch({
          type: FETCH_SCHEDULE_DETAILS_SUCCESS,
          payload: response.data[0],
        });
      } else {
        dispatch({
          type: FETCH_SCHEDULE_DETAILS_SUCCESS,
          payload: [],
        });
        clearFormFields();
      }
    } catch (error) {
      dispatch({
        type: FETCH_SCHEDULE_DETAILS_FAILURE,
        payload: error.message,
      });
      clearFormFields();
     // toast.error(error.response?.data?.message || "NETWORK ERROR")
    }
  };

  export const GetDetailsByRunId = (rootId, id) => {
    return async (dispatch) => {
      try {
        const BASE_URL = await getBaseUrl();
        const response = await axios.get(
          `${BASE_URL}/AddTestLab/GetTestRunDetailsByRunIdRootId?RootId=${rootId}&TestRunId=${id}`,
          header()
        );
        dispatch({
          type: EDIT_TEST_RUN,
          payload: response.data?.data ? JSON.parse(response.data.data) : {},
        });
      } catch (error) {
        console.error(error);
       // toast.error(error.response?.data?.message || "NETWORK ERROR")
      }
    };
  };

  export const executeTestRun = (row, selectedNodeId) => {
    return async (dispatch) => {
      try {
        const BASE_URL = await getBaseUrl();
        // API call
        const response = await axios.post(
          `${BASE_URL}/AddTestLab/InvokeTestLabTestRunPyhtonAPI`,
          {
            id: selectedNodeId,
            testRunId: row.Id,
            idType: "TestRun",
            ModuleType: "GhostLab",
          },
          header()
        );
        return response; 
      } catch (error) {
       // toast.error(error.response?.data?.message || `Error during test execution: ${error.message}`)
        return null; 
      }
    };
  };
  