import React, { useState, useCallback, useEffect } from "react";
import { useStylesTree } from "./styleTree";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Cancel";
import { Box, CircularProgress, Tooltip } from "@mui/material";
import { toast } from "react-toastify";
import DeleteModal from "./Comman/DeleteModal";
import {
  AddWorkspace,
  ExpandParent,
  UpdateWorkspace,
  setExpandedNodes,
  setRootId,
  setSelectedNode,
  AddUpdateWorkspace,
  DeleteAuditData,
} from "../../../redux/actions/Accessibility/AuditAction";
import { useDispatch, useSelector } from "react-redux";
import AddSiteDetails from "./Comman/AddSite";

const Card = ({
  newElementName,
  setNewElementName,
  toggleExpand,
  handleCRUDCancel,
  handleKeyPress,
  handleDelete,
  handleCRUD,
  handleEdit,
  editMode,
  editData,
  setEditData,
  setEditMode,
  handleEditChange,
  handleKeyPressEdit,
  data,
  nodeData = 0,
  handleCRUDAtParent,
  nodeCount = 1,
  handleNodeCount,
  expandedInputId,
  setExpandedInputId,
  handleTask,
  keyData = 0,
  handleSiteEdit,
  userId
}) => {
  const styleClass = useStylesTree();
  const dispatch = useDispatch();
  const { selectedNodeId, expanded } = useSelector((state) => state.audit);

  useEffect(() => {
    function updateNodeDepth(data, parentId, depth) {
      const children = data.filter((node) => node.parentId === parentId);
      for (const child of children) {
        child.node = depth;
        updateNodeDepth(data, child.id, depth + 1);
      }
    }

    function calculateDepth(data) {
      const roots = data.filter((node) => node.parentId === 0);
      for (const root of roots) {
        updateNodeDepth(data, root.id, 1);
      }
    }
    nodeCount = calculateDepth(data);
  }, [data]);

  return (
    <>
      <ul
        style={{
          display: !expanded.includes(keyData)
            ? keyData == 0
              ? "block"
              : "none"
            : expanded.includes(keyData)
            ? "block"
            : "none",
        }}
        className={nodeData == 0 ? styleClass.rootNodeFolder : styleClass.child}
      >
        {data?.map((item) => {
          if (item.parentId === nodeData) {
            return (
              <li key={item.id} className={styleClass.cardListHolder}>
                <div
                  className={styleClass.cardListHolderList}
                  style={
                    selectedNodeId === item.id
                      ? {
                          backgroundColor: "#654df7",
                          border: "2px solid #654df7",
                          color: "white",
                        }
                      : {}
                  }
                >
                  <div style={{ display: "flex" }}>
                    {data.some((child) => child.parentId === item.id) && (
                      <>
                        {!expanded.includes(item.id) ? (
                          <ExpandMoreIcon
                            onClick={() => toggleExpand(item.id)}
                          />
                        ) : (
                          <ExpandLessIcon
                            onClick={() => toggleExpand(item.id)}
                          />
                        )}
                      </>
                    )}
                    {editMode === item.id && (
                      <div className={styleClass.updateEdit}>
                        <input
                          type="text"
                          value={editData}
                          className={styleClass.editTheFolder}
                          onChange={(e) =>
                            handleEditChange(item.id, e.currentTarget.value)
                          }
                          onKeyPress={(event) =>
                            handleKeyPressEdit(event, item.id, nodeCount)
                          }
                          maxLength={250}
                        />
                      </div>
                    )}
                    {editMode !== item.id && (
                      <span
                        onClick={() => {
                          handleTask(item.id, nodeCount);
                          dispatch(setRootId(item.id));
                          toggleExpand(item.id)
                        }}
                        style={{
                          cursor: "pointer",
                          fontSize: "18px",
                        }}
                      >
                        <Tooltip title={item.name.length > 30 && item.name}>
                          <Typography
                            style={{
                              fontFamily: "Lexend Deca",
                              fontSize: "14px",
                            }}
                          >
                            {" "}
                            {item.name.length > 30
                              ? item.name.slice(0, 30) + "..."
                              : item.name}
                          </Typography>
                        </Tooltip>
                      </span>
                    )}
                  </div>
                  <div className={styleClass.crud}>
                  {nodeCount == 2 && (
                      <Tooltip title="Edit" arrow>
                        <EditIcon
                          sx={{
                            color:
                              selectedNodeId === item.id ? "white" : "#654df7",
                          }}
                          onClick={() => handleSiteEdit(item)}
                          style={{ cursor: "pointer" }}
                        />
                      </Tooltip>
                    )}
                    {editMode == 0 && nodeCount < 2 && (
                      <EditIcon
                        sx={{
                          color:
                            selectedNodeId === item.id ? "white" : "#654df7",
                        }}
                        onClick={() => handleEdit(item.id, item.name)}
                        style={{ cursor: "pointer", marginLeft: "10px" }}
                      />
                    )}
                    {editMode === item.id && (
                      <CancelIcon
                        sx={{ color: "#f74d4d" }}
                        onClick={() => handleEdit(item.id, item.name, "cancel")}
                      />
                    )}
                    <DeleteIcon
                      sx={{
                        color: selectedNodeId === item.id ? "white" : "#f74d4d",
                        cursor: "pointer"
                      }}
                      onClick={() => handleDelete(item)}
                    />
                   {nodeCount < 2 && (
                      <Tooltip title="Add" arrow>
                        <AddIcon
                          sx={{
                            color:
                              selectedNodeId === item.id ? "white" : "#654df7",
                            marginLeft: "auto",
                            cursor: "pointer",
                          }}
                          onClick={(event) =>
                            handleCRUD(event, item.id, item)
                          }
                        />
                      </Tooltip>
                    )}
                  </div>
                </div>
                {expandedInputId === item.id && expandedInputId != null && (
                  <div
                    className={styleClass.updateEdit}
                    style={{
                      display: expandedInputId === item.id ? "block" : "block",
                      marginLeft: "20px",
                    }}
                  >
                    <input
                      type="text"
                      style={{
                        fontFamily: "Lexend Deca",
                        fontSize: "14px",
                      }}
                      placeholder={nodeCount == 0 ? "Add Project" : ""}
                      className={styleClass.addNewFolder}
                      value={newElementName}
                      key={item.id}
                      onChange={(e) => setNewElementName(e.target.value)}
                      onKeyPress={(event) =>
                        handleKeyPress(event, item.id, nodeCount)
                      }
                    />
                    <CancelIcon
                      sx={{ color: "#f74d4d" }}
                      onClick={() => handleCRUDCancel()}
                    />
                  </div>
                )}

                {data.some((child) => child.parentId === item.id) && (
                  <Card
                    data={data}
                    handleEdit={handleEdit}
                    keyData={item.id}
                    nodeData={item.id}
                    handleCRUDAtParent={handleCRUDAtParent}
                    getparentId={item.parentId}
                    nodeCount={nodeCount + 1}
                    handleNodeCount={handleNodeCount}
                    expandedInputId={expandedInputId}
                    setExpandedInputId={setExpandedInputId}
                    handleTask={handleTask}
                    handleKeyPressEdit={handleKeyPressEdit}
                    handleEditChange={handleEditChange}
                    editData={editData}
                    setEditData={setEditData}
                    editMode={editMode}
                    setEditMode={setEditMode}
                    newElementName={newElementName}
                    setNewElementName={setNewElementName}
                    handleCRUD={handleCRUD}
                    toggleExpand={toggleExpand}
                    handleCRUDCancel={handleCRUDCancel}
                    handleKeyPress={handleKeyPress}
                    handleDelete={handleDelete}
                    handleSiteEdit={handleSiteEdit}
                    userId={userId}
                  />
                )}
              </li>
            );
          }
          return null;
        })}
      </ul>
    </>
  );
};

const DynamicTreeView = ({ WorkspaceHandle }) => {
  const styleClass = useStylesTree();
  const dispatch = useDispatch();
  const { listData, isLoading, error, selectedNodeId, expanded } = useSelector(
    (state) => state.audit
  );
  const { userId } = useSelector((state) => state.auth);
  const [nodeCount, setNodeCount] = useState(0);
  const [expandedInputId, setExpandedInputId] = useState(null);
  const [editData, setEditData] = useState(""); 
  const [editMode, setEditMode] = useState(0); 
  const [newElementName, setNewElementName] = useState("");
  const [openDelModal, setopenDelModal] = useState(false);
  const [deleteItem, setsDeleteItem] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [parent, setParent] = useState(null);  

  useEffect(() => {
    if (selectedNodeId) {
      const expandedNode = listData.find((item) => item.id === selectedNodeId);
      if (expandedNode) {
        let parentid = expandedNode.parentId;
        const parentids = [];
        while (parentid !== 0) {
          parentids.unshift(parentid);
          let parentNode = listData.find((item) => item.id === parentid);
          parentid = parentNode.parentId;
        }
        parentids.unshift(parentid);
        const nodeCount = findDepth(expandedNode, listData);
        WorkspaceHandle(expandedNode.id, nodeCount - 1);
      }
    }
  }, [listData, selectedNodeId]);

  useEffect(() => {
    dispatch(setSelectedNode());
  }, [selectedNodeId]);

  const findDepth = (item, items) => {
    if (item.parentId === 0) {
      return 1; 
    } else {
      const parentItem = items.find((parent) => parent.id === item.parentId);
      if (parentItem) {
        return 1 + findDepth(parentItem, items);
      } else {
        return 1;
      }
    }
  };

  // Function to calculate node depth based on parentId
    const calculateNodeDepth = (listData, parentId) => {
      let depth = 0;
      let currentNode = listData.find((node) => node.id === parentId);
  
      while (currentNode && currentNode.parentId !== 0) {
        depth++;
        currentNode = listData.find((node) => node.id === currentNode.parentId);
      }
  
      return depth + 1;
    };
  
    const handleCRUD = (event, parentId, item) => {
      event.preventDefault();
      let newItem = { ...item };
      const nodeCountValue = calculateNodeDepth(listData, parentId);
      if (nodeCountValue < 2) {
        setExpandedInputId(parentId);
      } else if (nodeCountValue === 2) {
        newItem.type = "Add";
        setParent(newItem);
        setOpenModal(true);
      } else {
        console.log("somethings wrong");
      }
    };

  const handleSiteEdit = (item) => {
    let newItem = { ...item };
    newItem.type = "edit";
    setParent(newItem);
    setOpenModal(true);
  };
  const handleCRUDAtParent = async (newItem, nodeData) => {
    try {
      // Check if newItem.name contains only whitespace
      if (newItem.name.trim() === "") {
        toast.error("Whitespace is not allowed.");
        return;
      }
      const payload = {
        id: 0,
        parent: newItem.parentId,
        name: newItem.name,
        isWorkspace: false,
        isProject: nodeData === 0,
        userId:userId
      };
      
      const response = await dispatch(AddUpdateWorkspace(payload)); 

      if (response && response.status === "fail") {
       // toast.error(response.message);
      } else {
        dispatch(AddWorkspace(response.Data));
        dispatch(setRootId(response.Data.id));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleCRUDNewItem = useCallback(
    (parentId, nodeData) => {
      if (nodeCount < 2) {
        setExpandedInputId(null);
        if (newElementName) {
          const newId = Math.max(...listData.map((item) => item.id)) + 1;
          const newItem = {
            name: newElementName,
            id: newId,
            parentId: parentId,
            new: "new",
          };
          handleCRUDAtParent(newItem, nodeData);
          dispatch(ExpandParent(parentId));
          setNewElementName("");
        }
      } else {
        alert("Maximum node limit reached.");
      }
    },
    [nodeCount, newElementName, listData, handleCRUDAtParent]
  );

  const handleNodeCount = (count) => {
    setNodeCount(count);
  };
  const handleKeyPressEdit = async (event, itemId, node) => {
    if (event.key === "Enter") {
      const itemToEdit = listData.find((item) => item.id === itemId);
      try {
        if (editData.trim() === "") {
          toast.error("Whitespace is not allowed.");
          return;
        }
        const payload = {
          id: itemToEdit.id,
          parent: itemToEdit.parentId,
          name: editData,
          isProject: itemToEdit.isProject,
          isWorkspace: itemToEdit.isWorkspace,
          userId:userId
        }
        const response = await dispatch(AddUpdateWorkspace(payload));
        if (response.status === "fail") {
         // toast.error(response.message);
        } else {
          setEditMode(0);
          const newData = listData.filter((item) => {
            if (item.id !== itemId) {
              return item;
            } else if (item.id === itemId) {
              item.name = editData;
              return item;
            }
          });
          dispatch(UpdateWorkspace(response.Data));
          setEditData("");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  const handleEdit = (itemId, name, mode = "edit") => {
    if (mode == "edit") {
      setEditMode(itemId);
      setEditData(name);
    } else if (mode == "cancel") {
      setEditData("");
      setEditMode(0);
    }
  };

  const handleEditChange = (itemId, name) => {
    setEditData(name);
  };

  const toggleExpand = (id) => {
    dispatch(setExpandedNodes(id));
  };
  const handleCRUDCancel = () => {
    setNewElementName("");
    setExpandedInputId(0);
  };

  const handleKeyPress = (event, parentId, nodeData) => {
    if (event.key === "Enter") {
      handleCRUDNewItem(parentId, nodeData);
    }
  };

  const handleDeleTeModal = (item) => {
    setsDeleteItem(item);
    setopenDelModal(true);
  };

  const handleDelete = async (itemId) => {
    const itemToDelete = listData.find((item) => item.id === itemId);
    try {
      const response = await dispatch(DeleteAuditData(itemToDelete.id));
      if (response?.status == "success") {
        setopenDelModal(false);
      }
      // dispatch(DeleteWorkspace(itemId));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setopenDelModal(false);
  };

const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <>
      <DeleteModal
        open={openDelModal}
        onClose={() => setopenDelModal(false)}
        deleteItem={deleteItem}
        handleDelete={handleDelete}
      />
      <AddSiteDetails open={openModal} onClose={handleClose} parent={parent} />

      <div className={styleClass.orgTree}>
        {isLoading ? (
          <Box style={{ textAlign: "center" }}>
            <CircularProgress
              style={{ color: "rgb(101, 77, 247)" }}
              size={25}
            />
          </Box>
        ) : error ? (
          <p>Error: {error?.message}</p>
        ) : listData.length === 0 ? (
          <Box style={{ textAlign: "center" }}>
            <p>No workspace available.</p>
          </Box>
        ) : (
          <Card
            handleEdit={handleEdit}
            handleKeyPressEdit={handleKeyPressEdit}
            handleEditChange={handleEditChange}
            editData={editData}
            setEditData={setEditData}
            editMode={editMode}
            setEditMode={setEditMode}
            data={listData}
            keyData={0}
            handleTask={WorkspaceHandle}
            nodeData={0}
            handleCRUDAtParent={handleCRUDAtParent}
            nodeCount={nodeCount}
            handleNodeCount={handleNodeCount}
            expandedInputId={expandedInputId}
            setExpandedInputId={setExpandedInputId}
            newElementName={newElementName}
            setNewElementName={setNewElementName}
            handleCRUD={handleCRUD}
            expanded={expanded}
            toggleExpand={toggleExpand}
            handleCRUDCancel={handleCRUDCancel}
            handleKeyPress={handleKeyPress}
            handleDelete={handleDeleTeModal}
            handleSiteEdit={handleSiteEdit}
            userId={userId}
          />
        )}
      </div>
    </>
  );
};

export default DynamicTreeView;