import { useParams } from "react-router-dom";
import {
  Grid,
  Typography,
  Box,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Breadcrumbs,
  TableContainer,
} from "@material-ui/core";
import { StyledTypography, useStyles } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import Donut from "./DonutChart";
import CustomVideoChell from "./modals/CustomVideoChell";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { CircularProgress } from "@material-ui/core";
import { StyledTableCell } from "./styles";
import CustomStatusCell from "./modals/CustomStatusCell";
import { getTestRunExecutionHistory } from "../../../../redux/actions/testlab/ResultAction";
import Rundetails from "./RundetailsTable";
import { useEffect, useState } from "react";
import { Menu, MenuItem } from "@mui/material";
import { handleExportOption } from "./exportsUtils"; 

export default function TestLabSuitsDetails() {
  const { testSuiteName, testRunId, rootId, startDate, testRunNameId} = useParams();
  const classess = useStyles();
  const dispatch = useDispatch();
  const { selectedNodeId } = useSelector((state) => state.testlab);
  const { testRunExecutionHistory, loading } = useSelector(
    (state) => state.testlabResult
  );
  const [selectedRun, setSelectedRun] = useState({});
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleExportClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExport = (type) => {
    handleExportOption(type, testRunExecutionHistory, testRunId, setAnchorEl);
  };

  const handleRowClick = (run) => {
    setSelectedRun(run);
  };
  useEffect(() => {
    if (testSuiteName && testRunId && selectedNodeId !== undefined && selectedNodeId !== null && startDate !== undefined) {
      const data = {
        testSuitName: testSuiteName,
        runId: testRunId,
        RootId: rootId,
        TestRunStartDate: startDate,
        moduleType: "GhostLab",
        TestRunNameId: testRunNameId
      };
      dispatch(getTestRunExecutionHistory(data));
    }
  }, [testSuiteName, testRunId, selectedNodeId, dispatch]);
  
  const calculateDonutHeight = () => {
    const parentContainer = document.getElementById("donut-container");
    const parentContainerHeight = parentContainer
      ? parentContainer.clientHeight
      : window.innerHeight;
    const desiredPercentage = 38;
    const calculatedHeight = `${
      (parentContainerHeight * desiredPercentage) / 100
    }px`;

    return calculatedHeight;
  };

  function formatTimeDifference(timeDifference) {
    const seconds = Math.floor(timeDifference / 1000);
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const padWithZero = (num) => (num < 10 ? `0${num}` : num);

    // Format the time components with leading zeros
    const formattedHours = padWithZero(hours);
    const formattedMinutes = padWithZero(minutes);
    const formattedSeconds = padWithZero(remainingSeconds);

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

  function extractDate(dateStr) {
    if (typeof dateStr !== "string") {
      return null; 
    }
    const [year, month, day] = dateStr?.split("-");

    const monthNames = {
      "01": "Jan",
      "02": "Feb",
      "03": "Mar",
      "04": "April",
      "05": "May",
      "06": "June",
      "07": "July",
      "08": "Aug",
      "09": "Sep",
      10: "Oct",
      11: "Nov",
      12: "Dec",
    };

    const formattedDate = `${monthNames[month]} ${parseInt(day, 10)}, ${year}`;

    return formattedDate;
  }

  const testData = [{ name: "Tester Name", value: testRunExecutionHistory?.UserName }];

  return (
    <>
      {" "}
      {!loading ? (
        <Grid className={classess.mainContainer}>
          {/* header button */}
          <Grid
            xs={12}
            container
            style={{ marginBottom: "15px" }}
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid>
              <Breadcrumbs separator="›" className={classess.breadCrumbHead}>
                <Box
                  onClick={() => {
                    navigate(-1);
                  }}
                  className={classess.breadCrumbStyle}
                >
                  {testSuiteName}
                </Box>
                <Box fontSize="14px">{testRunId}</Box>
              </Breadcrumbs>
            </Grid>
            <Grid>
              <Stack direction="row" spacing={2} justifyContent="flex-end" alignItems="flex-end">
                <div>
                  <Button
                    className={classess.backBtn}
                    onClick={handleExportClick}
                  >
                    Export
                  </Button>
                  <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <MenuItem onClick={() => handleExport("PDF")}>Export as PDF</MenuItem>
                    <MenuItem onClick={() => handleExport("XLS")}>Export as XLS</MenuItem>
                    <MenuItem onClick={() => handleExport("Word")}>Export as Word</MenuItem>

                  </Menu>
                </div>
                <Button
                  className={classess.backBtn}
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <ArrowBackIcon fontSize="small" />
                  Back
                </Button>
              </Stack>
            </Grid>

          </Grid>
          {/* main compoent */}
          <Grid container spacing={2}>
            {/* Left side content */}
            <Grid item xs={12} sm={7}>
              <Grid container spacing={2}>
                {/* Left part of the card */}
                <Grid item xs={12} sm={6}>
                  <Card
                    style={{
                      background: "#f1f1f1",
                      height: "40vh",
                      padding: "10px 15px",
                      flexDirection: "column",
                      justifyContent: "center", 
                      alignItems: "center",
                    }}
                  >
                    {testRunExecutionHistory?.PassedTestCases ||
                    testRunExecutionHistory?.FailedTestCases ? (
                      <Donut
                        series={[
                          testRunExecutionHistory?.PassedTestCases || 0,
                          testRunExecutionHistory?.FailedTestCases || 0,
                        ]}
                        labels={["Passed", "Failed"]}
                        height={calculateDonutHeight()}
                      />
                    ) : (
                      <div>No data available</div>
                    )}
                  </Card>
                </Grid>

                {/* Right part of the card */}
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={2}>
                    {/* Top-left part */}
                    <Grid item xs={12} sm={6}>
                      <Card
                        style={{
                          width: "100%",
                          height: "19vh",
                        }}
                      >
                        <CardContent className={classess.hederStyle}>
                          <Typography style={{ fontSize: "14px" }}>
                            Tests
                          </Typography>
                        </CardContent>

                        <CardContent>
                          {" "}
                          <Typography
                            variant="body1"
                            className={classess.tbodyFont}
                          >
                            {testRunExecutionHistory?.PassedTestCases} Tests Passed
                          </Typography>
                          <Typography
                            variant="body1"
                            className={classess.tbodyFont}
                          >
                            {testRunExecutionHistory?.FailedTestCases} Tests Failed
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>

                    {/* Top-right part */}
                    <Grid item xs={12} sm={6}>
                      <Card
                        style={{
                          width: "100%",
                          height: "19vh",
                        }}
                      >
                        <CardContent className={classess.hederStyle}>
                          <Typography style={{ fontSize: "14px" }}>
                            Start Date, Time
                          </Typography>
                        </CardContent>

                        <CardContent>
                          {" "}
                          <Typography
                            variant="body1"
                            className={classess.tbodyFont}
                          >
                            {`${extractDate(
                              testRunExecutionHistory?.TestRunStartDate
                            )} ${testRunExecutionHistory.TestRunStartTime}`}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>

                    {/* Bottom-left part */}
                    <Grid item xs={12} sm={6}>
                      <Card
                        style={{
                          width: "100%",
                          height: "19vh",
                        }}
                      >
                        <CardContent className={classess.hederStyle}>
                          <Typography style={{ fontSize: "14px" }}>
                            Tests
                          </Typography>
                        </CardContent>

                        <CardContent>
                          {" "}
                          <Typography
                            variant="body1"
                            className={classess.tbodyFont}
                          >
                            {testRunExecutionHistory?.TotalTestCases} Total Test Case
                          </Typography>
                          <Typography
                            variant="body1"
                            style={{ color: "#654DF7" }}
                            className={classess.tbodyFont}
                          >
                            {(testRunExecutionHistory.PassedTestCases /
                              testRunExecutionHistory.TotalTestCases) *
                              100}
                            %
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>

                    {/* Bottom-right part */}
                    <Grid item xs={12} sm={6}>
                      <Card
                        style={{
                          width: "100%",
                          height: "19vh",
                        }}
                      >
                        <CardContent className={classess.hederStyle}>
                          <Typography style={{ fontSize: "14px" }}>
                            Duration
                          </Typography>
                        </CardContent>

                        <CardContent>
                          {" "}
                          <Typography
                            variant="body1"
                            className={classess.tbodyFont}
                          >
                            {formatTimeDifference(
                              new Date(
                                `${testRunExecutionHistory.TestRunEndDate}T${testRunExecutionHistory.TestRunEndTime}`
                              ) -
                                new Date(
                                  `${testRunExecutionHistory.TestRunStartDate}T${testRunExecutionHistory.TestRunStartTime}`
                                )
                            )}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Card>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>Run Id </StyledTableCell>
                            <StyledTableCell>Test Case Name</StyledTableCell> 
                            <StyledTableCell>Status</StyledTableCell>
                            <StyledTableCell>Video</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {testRunExecutionHistory?.TestCaseDetailsList ? (
                            testRunExecutionHistory?.TestCaseDetailsList.map((row) => (
                              <TableRow
                                key={row.ExecutionId}
                                className={`${classess.tableRow} ${
                                  selectedRun.ExecutionId === row.ExecutionId
                                    ? classess.activeRow
                                    : ""
                                }`}
                                // spacing="1"
                                onClick={() => handleRowClick(row)}
                              >
                                <StyledTableCell
                                  sx={{
                                    color:
                                    selectedRun.ExecutionId === row.ExecutionId
                                        ? "white"
                                        : "#654DF7",
                                  }}
                                >
                                  {row.TestRunName}
                                </StyledTableCell>
                                 <StyledTableCell
                                  sx={{
                                    color:
                                    selectedRun.ExecutionId === row.ExecutionId
                                        ? "white"
                                        : "black",
                                  }}
                                >
                                  {row.TestCaseName}
                                </StyledTableCell>
                                <CustomStatusCell
                                  status={row.TestCaseStatus}
                                  selected={selectedRun.ExecutionId === row.ExecutionId}
                                />
                                <StyledTableCell sx={{cursor:'pointer'}}>
                                  <CustomVideoChell
                                    imgUrl={row.TestCaseVideoURL}
                                    isSelected={selectedRun.ExecutionId === row.ExecutionId}
                                  />
                                </StyledTableCell>
                              </TableRow>
                            ))
                          ) : (
                            <TableRow>
                              <StyledTableCell colSpan={5} align="center">
                                No execution history
                              </StyledTableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Card>
                </Grid>
              </Grid>
            </Grid>

            {/* Right side */}

            <Grid item xs={12} sm={5}>
              <Grid container spacing={2}>
                {/* Top part of the card */}
                <Grid item xs={12}>
                  <Card
                    style={{
                      height: "40vh",
                    }}
                  >
                    <CardContent className={classess.headrRightSite}>
                      <Typography className={classess.theadFont}>
                        Info...
                      </Typography>
                    </CardContent>

                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ fontWeight: "400" }}>
                            Name
                          </TableCell>
                          <TableCell style={{ fontWeight: "400" }}>
                            Value
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {testData.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell className={classess.tbodyFont}>
                              {row.name}
                            </TableCell>
                            <TableCell className={classess.tbodyFont}>
                              {row.value}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Card>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                {/* Left part of the card */}
                {selectedRun.ExecutionId && (
                  <Grid item xs={12}>
                    <Card>
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <StyledTableCell colSpan={4}>
                                <StyledTypography variant="h6" color="primary">
                                {`#${selectedRun.ExecutionId}-`}{selectedRun.TestRunName}  
                                </StyledTypography>
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <Rundetails data= {selectedRun}/>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Card>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={25} />
        </div>
      )}
    </>
  );
}
