import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import { useTableStyles, StyledTableCell } from "./styles";
import clsx from "clsx";

export function TestCaseTable_TestRun({
  rows,
  selectedRows,
  handleSelectAllChange,
  handleCheckboxChange,
  selectAll,
}) {
  const classes = useTableStyles();

  return (
    <TableContainer sx={{ marginBottom: "1vh" }}>
      <Table>
        <TableHead className={clsx(classes.firstColumn, classes.lastColumn)}>
          <TableRow>
            <StyledTableCell>
              <Checkbox
                checked={selectAll}
                onChange={handleSelectAllChange}
                color="primary"
                style={{
                  color: selectAll ? "rgb(101, 77, 247)" : undefined,
                }}
              />
              Test Case Name
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody className={clsx(classes.firstColumn, classes.lastColumn)}>
          {rows?.map((row, index) => (
            <TableRow
              key={row.TestCaseDetailsId}
              className={`${classes.tableRow} ${
                index === 0
                  ? classes.firstRow
                  : index === rows.length - 1
                  ? classes.lastRow
                  : ""
              }`}
              style={{ height: "10px" }}
            >
              <StyledTableCell component="th" scope="row">
                <Checkbox
                  onChange={(event) => handleCheckboxChange(event, row)}
                  checked={selectedRows?.some(
                    (selectedRow) =>
                      selectedRow.TestCaseDetailsId === row.TestCaseDetailsId
                  )}
                  style={{
                    color: selectedRows?.some(
                      (selectedRow) =>
                        selectedRow.TestCaseDetailsId === row.TestCaseDetailsId
                    )
                      ? "rgb(101, 77, 247)"
                      : undefined,
                  }}
                />
                {row.TestCaseName}
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
