import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useStyles, StyledTableCell, IOSSwitch } from "./style";
import { Delete as DeleteIcon } from "@material-ui/icons";
import Tooltip from "@mui/material/Tooltip";
import DeleteModal from "./DeleteModal";
import axios from "axios";
import { header } from "../../../../utils/authheader";
import { getBaseUrl } from "../../../../utils/configService";
import { DisableEnableUser, fetchUsers } from "../../../../redux/actions/userActions";
import { useDispatch, useSelector } from "react-redux";
// const BASE_URL = process.env.REACT_APP_BASE_URL || "api";

export function CustomTable({ users }) {
  const classes = useStyles();
  const dispatch = useDispatch()
  const [openDelModal, setOpenDelModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const userId = useSelector((state) => state.auth.userId);
  const isAdmin = useSelector((state) => state.auth.isAdmin);

  const handleSwitch = (e, row) => {
    let payload = {
      toDisableUserId: row.Id,
      requestingUser: userId,
      isDisabled: !e.target.checked,
    };
  dispatch(DisableEnableUser(payload))
  }

  const getName = (email) => {
    const i = email.indexOf("@");
    const name = email.substring(0, i);
    return name.charAt(0).toUpperCase() + name.slice(1);
  };

  const handleDelete = (row) => {
    setOpenDelModal(true);
    setSelectedUser(row);
  };

  return (
    <>
    <DeleteModal
      open={openDelModal}
      onClose={() => setOpenDelModal(false)}
      user={selectedUser}
    />
      <TableContainer sx={{ marginBottom: "8vh" }}>
        <Table>
          <TableHead>
            <TableRow>
              {/* <StyledTableCell>Project Name</StyledTableCell> */}
              <StyledTableCell>User Name</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>Enable</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users?.map((row) => (
              <TableRow
                key={row.Email}
                className={`${classes.tableRow}`}
                style={{ height: "10px" }}
                spacing="3"
              >
                <StyledTableCell sx={{ opacity: row.IsDisabled ? 0.5 : 1 }}>
                  {getName(row.Email)}
                </StyledTableCell>
                <StyledTableCell
                  component="th"
                  scope="row"
                  sx={{ opacity: row.IsDisabled ? 0.5 : 1 }}
                >
                  {row.Email}
                </StyledTableCell>
                <StyledTableCell>
                  <Tooltip title={isAdmin ? "Enable / Disable" : "You do not have permission to Enable or Disable"}>
                    <span>
                      <IOSSwitch
                        defaultChecked={!row.IsDisabled}
                        onChange={(e) => handleSwitch(e, row)}
                        disabled={!isAdmin || row.Id === userId}
                      />
                    </span>
                  </Tooltip>
                </StyledTableCell>
                <StyledTableCell>
                  {isAdmin ? (
                    <Tooltip title="Delete">
                      <DeleteIcon
                        onClick={row.Id === userId ? null : () => handleDelete(row)}
                        style={{
                          cursor: row.Id === userId ? "not-allowed" : "pointer",
                          color: row.Id === userId ? "rgb(246, 78, 78, 0.5)" : "#F64E4E",
                        }}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip title="You do not have permission to delete">
                      <span>
                        <DeleteIcon
                          style={{
                            color: "rgb(246, 78, 78, 0.5)",
                            cursor: "not-allowed",
                          }}
                        />
                      </span>
                    </Tooltip>
                  )}
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}