import React, { useState, useEffect } from "react";
import { Grid, Card } from "@material-ui/core";
import { useStyles } from "./styles";
import Button from "@mui/material/Button";
import TabsPanel from "./TabsPanel";
import AddNewProject from "./AddNewProject";
import { Add } from "@mui/icons-material/";
import DynamicTreeView from "./DynamicTreeView";
import axios from "axios";
import { toast } from "react-toastify";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  AddWorkspace,
  fetchWorkSpaces,
  JourneyDetails,
  setRootId,
} from "../../../redux/actions/AdvanceAutomation/JourneyAction";
import { header } from "./../../../utils/authheader";
import { getBaseUrl } from "./../../../utils/configService";
import { DrawerOpenIcon, DrawerClosedIcon } from "../../../comman/icons";
import JourneyList from "./Comman/JourneyList";
import clsx from "clsx";
import UpgradeModal from "../../../comman/Accordion/UpgradePlan/UpgradeModal"

export default function Journey() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { journeyDetails, selectedNodeId } = useSelector((state) => state.journey)
  const [addNewProject, setAddNewProject] = useState(false);
  const [depth, setdepth] = useState(0);
  const [formData, setFormData] = useState({ name: "" });
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchWorkSpaces());
  }, [dispatch]);

  useEffect(() => {
    dispatch(JourneyDetails(null))
  }, [depth, selectedNodeId]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!formData.name.trim()) {
      toast.error("Whitespace is not allowed.");
      return;
    }
    try {
      const BASE_URL = await getBaseUrl();
      const response = await axios.post(
        `${BASE_URL}/AdvanceAutomation/AddUpdateAdvanceAutomationRelation`,
        {
          rootId: 0,
          node: 0,
          parent: formData.parentId,
          name: formData.name,
        },
        header()
      );
      if (response.data.status === "fail") {
        if (response.data.message === "You’ve reached the maximum number of workspaces allowed on your current plan! Please upgrade to access more workspace slots.") {
          setUpgradeModalOpen(true); 
        } else {
         // toast.error(response.data.message); 
        }
      } else {
        dispatch(AddWorkspace(response.data.Data[0]));
        setFormData({ name: "" });
        setAddNewProject(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ name: value, id: Math.random(), parentId: 0 });
  };

  const handleJourneyList = (id, node) => {
    setdepth(node);
    setAddNewProject(false);
    dispatch(setRootId(id));
  };

  const handleCancel = () => {
    setAddNewProject(false);
    setFormData({ name: "" });
  };


  const treeStyle = drawerOpen ? {} : { display: "none" };
  return (
    <>
    <UpgradeModal open={upgradeModalOpen} onClose={() => setUpgradeModalOpen(false)} />
      <div className={classes.main}>
        <Grid container spacing={2}>
          <Box
            onClick={() => setDrawerOpen(!drawerOpen)}
            style={{ position: "absolute", cursor: "pointer" }}
          >
            {!drawerOpen && <DrawerClosedIcon />}
          </Box>

          <Grid item xs={12} md={3} xl={2} style={treeStyle}>
            <Card
              className={classes.card}
              style={{ paddingBottom: "30px", position: "relative" }}
            >
              <Grid
                container
                alignItems="center"
                className={classes.bodyHeader}
              >
                <Grid container xs={12} style={{padding: '0px 16px'}}>
                <Grid item xs={9}>
                  Workspaces
                </Grid>
                <Grid item xs={3} style={{ textAlign: "right" }}>
                  {addNewProject ? (
                    <Button
                      variant="contained"
                      onClick={handleCancel}
                      style={{
                        fontSize: 14,
                        backgroundColor: "rgb(101, 77, 247)",
                        color: "#ffffff",
                        cursor: "pointer",
                      }}
                    >
                      Cancel
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      onClick={() => setAddNewProject(true)}
                      style={{
                        fontSize: 14,
                        backgroundColor: "rgb(101, 77, 247)",
                        color: "#ffffff",
                        cursor: "pointer",
                      }}
                    >
                      <Add />
                    </Button>
                  )}
                </Grid>
                </Grid>
               
                <Grid item xs={12}>
                  {addNewProject && (
                    <AddNewProject
                      handleChange={handleChange}
                      handleSubmit={handleSubmit}
                      formData={formData}
                    />
                  )}
                </Grid>
              </Grid>
              <Grid className={clsx(classes.bodyTree, classes.bodyTreeheight)} >
                <DynamicTreeView JourneyHandle={handleJourneyList} />
              </Grid>
              <Grid item xs={12} style={{marginBottom:"10px"}}>
                <Box
                  sx={{
                    width: "100%",
                    height: "2px",
                    backgroundColor: "#DADADA",
                    padding: "0px 0px",
                  }}
                />
              </Grid>
              <Grid item xs={1} style={{ position: "absolute", right: "16px", bottom: "5px", }}>
                <Box
                  onClick={() => setDrawerOpen(!drawerOpen)}
                  sx={{ cursor: "pointer" }}
                >
                  {drawerOpen && <DrawerOpenIcon />}
                </Box>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} md={drawerOpen ? 9 : 12} xl={10}>
          {!journeyDetails && depth >= 2 && <JourneyList depth={depth} />}
           {journeyDetails && <TabsPanel />}
             {/* {depth >= 2 ? <TabsPanel /> : <Box />} */}
            {/* {depth >= 2 ? <TabsPanel /> : <Box />} */}
          </Grid>
        </Grid>
      </div>
    </>
  );
}
