import axios from "axios";
import { toast } from "react-toastify";
import { header } from "../../../utils/authheader";
import { getBaseUrl, getCoreEngineBaseUrl } from "../../../utils/configService";
import { executeTestCase } from "../../../redux/actions/testlab/designAction";
import React from "react";

export const AddTestCaseDetails = async (payload, actions, goBack) => {
    try {
        const BASE_URL = await getBaseUrl();
        const res = await axios.post(
            `${BASE_URL}/AddTestLab/AddTestCaseDetails`,
            payload,
            header()
        ); 
        const Data = res.data?.data;
        if (res.data?.status && res.data.status.trim().toLowerCase() === "success") {
            const stepDetails = {
                testCaseID: Data[0].id,
                actions: actions,
            };
            const resSteps = await axios.post(
                `${BASE_URL}/AddTestLab/AddTestStepsDetails`,
                stepDetails,
                header()
            ); // to add steps to testcase
            if (resSteps.data?.status && resSteps.data.status.trim().toLowerCase() === "success") {
                goBack();
                toast.info("Successfully saved", {
                    style: {
                        background: "rgb(101, 77, 247)",
                        color: "rgb(255, 255, 255)",
                    },
                });
            }
        } else {
            toast.error(res.data.message)
        }
    } catch (error) {
        console.log("error saving ", error);
       // toast.error(error.response?.data?.message || "NETWORK ERROR");
    }
};

export const UpdateTestStepsDetails = async (payload, savetoEdit) => {
    try {
        const BASE_URL = await getBaseUrl();
        const res = await axios.post(
            `${BASE_URL}/AddTestLab/AddTestStepsDetails`,
            payload,
            header()
        );
        if (res.data?.status && res.data.status.trim().toLowerCase() === "success") {
            savetoEdit();
        }
    } catch (error) {
        console.log("error saving ", error);
       // toast.error(error.response?.data?.message || "NETWORK ERROR");
    }
};

export const UpdateTestCaseDetail = async (payload) => {
    try {
        const BASE_URL = await getBaseUrl();
        const res = await axios.post(
            `${BASE_URL}/AddTestLab/UpdateTestCaseDetails`,
            payload,
            header()
        );
        if (res.data?.status && res.data.status.trim().toLowerCase() === "success") {
            toast.info(res.data?.message, {
                style: {
                    background: "rgb(101, 77, 247)",
                    color: "rgb(255, 255, 255)",
                },
            });
        }else{
            toast.error(res.data.message)
        }
    } catch (error) {
        console.log("error saving ", error);
       // toast.error(error.response?.data?.message || "NETWORK ERROR");
    }
};

export const updateTestDetails = async (testcase,payload, savetoEdit) => {
    try {
        const BASE_URL = await getBaseUrl();
        let res1, res2;
        res1 = await axios.post(
            `${BASE_URL}/AddTestLab/AddTestStepsDetails`,
            payload,
            header()
        );
        res2 = await axios.post(
            `${BASE_URL}/AddTestLab/UpdateTestCaseDetails`,
            testcase,
            header()
        );

        if (res2.data?.status && res2.data.status.trim().toLowerCase() === "success") {
            toast.info("Successfully updated testcase", {
                style: {
                    background: "rgb(101, 77, 247)",
                    color: "rgb(255, 255, 255)",
                },
            });
        } else {
            toast.error(res2.data.message);
        }
        if (res1.data.status === "success" && res2.data.status === "success") {
            savetoEdit();
        }
    } catch (error) {
        console.log("error saving ", error);
       // toast.error(error.response?.data?.message || "NETWORK ERROR");
    }
};

export const SaveAndExecute = async (data, payload, testId, handleExecuteLoading, dispatch) => {
    try {
        const BASE_URL = await getBaseUrl();
        const [updateRes, addStepsRes] = await Promise.all([
            axios.post(`${BASE_URL}/AddTestLab/UpdateTestCaseDetails`, data, header()),
            axios.post(`${BASE_URL}/AddTestLab/AddTestStepsDetails`, payload, header()),
        ]);
        const updateData = updateRes?.data;

        if (updateData?.status && updateData.status.trim().toLowerCase() === "success") {
            toast.info(updateData.message, {
                style: {
                    background: "rgb(101, 77, 247)",
                    color: "rgb(255, 255, 255)",
                },
            });

            const testCaseDetail = updateData?.data?.[0];
            if (testCaseDetail) {
                const selectedNodeId = testCaseDetail.rootId;
                const row = {
                    RootId: testCaseDetail.rootId,
                    TestCaseName: testCaseDetail.testCaseName,
                };
                let response;
                try {
                    response = await dispatch(executeTestCase(row, selectedNodeId));
                    if (response?.status === 200) {
                        if (typeof handleExecuteLoading === "function") {
                            handleExecuteLoading();
                        }
                    } else {
                        throw new Error("Execution did not return a valid response.");
                    }
                } catch (err) {
                    console.error("Error during dispatch execution:", err);
                    throw new Error("Failed to execute test case.");
                }
            } else {
                throw new Error("TestCaseDetail is missing in the response.");
            }
        } else {
            toast.error("Failed to save testcase");
        }
    } catch (error) {
        console.error("Error in SaveAndExecute:", error);
       // toast.error(error.response?.data?.message || "NETWORK ERROR");
        if (typeof handleExecuteLoading === "function") {
            handleExecuteLoading();
        }
    }
};

const getRunDetail = async (runId, delay, testCaseDetailId, handleExecuteLoading) => {
    try {
        const BASE_URL = await getBaseUrl();
        const CORE_BASE_URL = await getCoreEngineBaseUrl()
        const res = await axios.get(
            `${CORE_BASE_URL}/codeengine/api/test-suitesV2/${runId}/monitor_container_run/`
        );
        if (res.data.container_status === "exited") {
            handleExecuteLoading()
            const rundetails = res.data;
            const currentDate = new Date();
            const formattedEndDateTime = currentDate.toISOString();
            const formattedStartDateTime = currentDate.toISOString();
            let endDate = formattedEndDateTime;
            try {
                const res = await axios.post(`${BASE_URL}/AddTestLab/AddExecuteResult`,
                    {
                        testCaseDetailId: testCaseDetailId,
                        data: rundetails,
                        startDate: formattedStartDateTime,
                        endDate: endDate,
                    }, header())
                if (res.data?.status && res.data.status.trim().toLowerCase() === "success") {
                    toast.info("Successfully executed", {
                        style: {
                            background: "rgb(101, 77, 247)",
                            color: "rgb(255, 255, 255)",
                        },
                    });
                }
            } catch (error) {
                console.log('error', error)
               // toast.error(error.response?.data?.message || 'Error AddExecuteResult');
            }
            console.log("rundetails : ", rundetails);
        } else {
            setTimeout(() => {
                getRunDetail(runId, delay, testCaseDetailId, handleExecuteLoading);
            }, delay);
        }
    } catch (error) {
        console.error("Error getting run details:", error);
        //toast.error(error.response?.data?.message || "Network error");
    }
};