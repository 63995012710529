import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTableStyles, StyledTableCell } from "./../styles";
import { Delete as DeleteIcon } from "@material-ui/icons";
import { toast } from "react-toastify";

export function SiteTable({ rows }) {
  const classes = useTableStyles();
  const navigate = useNavigate();
  const handleNavigation = (id, status) => {
    if (status !== "In Progress") {
      navigate(`/audit/detail/${id}`);
    } else {
       toast.warn("This audit is still in progress and cannot be accessed.");
    }
  };

  return (
    <>
      <TableContainer
        sx={{ marginBottom: "8vh", height: `calc(100vh - 200px)` }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell isFirstColumn>Name</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Created On</StyledTableCell>
              <StyledTableCell isLastColumn>Pages</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.ApplicationName}
                className={`${classes.tableRow}`}
                style={{ height: "10px" }}
                spacing="3"
              >
              <StyledTableCell
                  isFirstColumn
                  style={{
                    cursor:
                      row.status !== "In Progress" ? "pointer" : "pointer",
                  }}
                  onClick={() => handleNavigation(row.id, row.status)}
                >
                  {row.status === "In Progress" ? (
                    <Tooltip title="Audit is still in progress" arrow>
                      {row.ApplicationName.length > 20 ? (
                        <span>
                          {row.ApplicationName.substring(0, 20) + "..."}
                        </span>
                      ) : (
                        row.ApplicationName
                      )}
                    </Tooltip>
                  ) : (
                    <>
                      {row.ApplicationName.length > 20 ? (
                        <Tooltip title={row.ApplicationName} arrow>
                          <span>
                            {row.ApplicationName.substring(0, 20) + "..."}
                          </span>
                        </Tooltip>
                      ) : (
                        row.ApplicationName
                      )}
                    </>
                  )}
                </StyledTableCell>
                <StyledTableCell>{row.status}</StyledTableCell>
                <StyledTableCell>{row.createdOn}</StyledTableCell>
                <StyledTableCell isLastColumn>{row.pages}</StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
