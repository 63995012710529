import { styled } from '@mui/material/styles';

export const AuditIcon = styled(({ className, isActive, color }) => {
	return (
		<div style={{ color: isActive ? color : 'inherit' }}>
            <svg width="40" height="40" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
			<ellipse cx="16.0001" cy="15.6818" rx="15.2995" ry="15.2995" fill={isActive ? "white" : "#7766DF"}/>
			<path d="M10.1522 20.3312C10.0681 20.3312 10 20.2631 10.0001 20.1791L10.0018 17.3061C10.0021 17.0962 10.1723 16.9261 10.3821 16.9259C11.6496 16.9259 12.9171 16.9259 14.1846 16.9259C14.5349 16.9259 14.5331 16.6151 14.5331 15.8753C14.5331 15.1355 12.6696 14.5124 12.6696 11.9115C12.6696 9.31062 14.6001 8.5459 16.2044 8.5459C17.8088 8.5459 19.5562 9.31062 19.5562 11.9115C19.5562 14.5124 17.7026 14.9259 17.7026 15.8753C17.7026 16.8247 17.7026 16.9259 17.9993 16.9259C19.2613 16.9259 20.5234 16.9259 21.7855 16.9259C21.9954 16.9259 22.1656 17.0961 22.1656 17.3061V20.1792C22.1656 20.2631 22.0976 20.3312 22.0136 20.3312H10.1522Z" fill={isActive ? "#7766DF" : "white"} stroke="#654DF7" stroke-width="0.304138" stroke-linejoin="round"/>
			<rect x="9.92409" y="21.776" width="12.3176" height="1.36862" rx="0.228103" fill={isActive ? "#7766DF" : "White"} stroke="#654DF7" stroke-width="0.152069"/>
			</svg>
		</div>
	);
})`
	display: flex;
`;