import { makeStyles } from "@material-ui/core";
import { Typography } from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";

const useStyle = makeStyles((theme) => ({
  main: {
    margin: "20px",
  },
  body: {
    display: "grid !important",
    gridTemplateRows: "auto",
    rowGap: "10px",
    padding: "0px 11px",
    gridTemplateColumns: "1fr",
  },
  input: {
    display: "flex",
    flexDirection: "column",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "right",
    alignItems: "center",
    padding: "10px",
    // borderTop: "1px solid #ccc",
    gap: "8px",
  },
  button: {
    height: "40px",
    minWidth: "110px",
    display: "flex",
    textTransform: "none !important",
  },
  radioGroup: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  radioLabel: {
    marginRight: theme.spacing(2),
    "& .Mui-checked": {
      color: "rgb(101, 77, 247)",
    },
    fontSize: "14px",
  },
  select: {
    "& .MuiOutlinedInput-input": {
      backgroundColor: "red", // Background color
    },
    "& .MuiSelect-outlined": {
      "&:hover": {
        borderColor: "red", // Hover border color
      },
      "&.Mui-focused": {
        borderColor: "green !important", // Active border color
      },
    },
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      borderColor: "transparent !important",
      "&:hover fieldset": {
        borderColor: "#654DF7",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#654DF7",
      },
    },
  },
  textarea: {
    border: "1px solid red",
    "&:hover, &:focus, &.Mui-focused": {
      border: "1px solid #654DF7",
    },
    minHeight: "12vh",
    padding: "10px",
    resize: "none",
  },
  customheight: {
    height: "40px",
  },
  customFontSize: {
    fontSize: "12px !important",
  },
  customBackgroung: {
    backgroundColor: "rgb(242, 242, 242)",
    border: "none",
  },
  radioButtonLabel: {
    fontSize: "12px !important",
    display: "inline-block",
  },
  sideBar: {
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "11px",
    padding: "8px 22px",
  },
  inputError: {
    color: "red",
    textAlign: "left",
    fontSize: "10px",
    fontFamily: "Lexend Deca",
  },
  testCaseErrorStyle: {
    color: "red",
    textAlign: "right",
    margin: "10px 30px 10px 0",
  },
  togglebox: {
    paddingTop: "5px",
    marginLeft: "auto",
  },
  root: {
    marginTop: theme.spacing(3),
    justifyContent: "center",
    padding: theme.spacing(2),
    // overflow: 'hidden', // Prevent horizontal scrollbars
    width: "100%",
    boxSizing: "border-box",
  },
  header: {
    marginBottom: theme.spacing(2),
    textAlign: 'left !important',
    paddingLeft: "35px",
    fontSize: "14px",
    lineHeight: "18px",
    letterSpacing: "bold",
    fontFamily: `"Lexend Deca"`,
  },
  leftContainer: {
    padding: theme.spacing(1.25),
  },
  buttonadd: {
    backgroundColor: "#654DF7 !important" ,
    color: "white !important",
    border: "1px solid #654DF7 !important",
    textTransform: "none !important",
    marginRight: theme.spacing(1.25) ,
    "&:hover": {
      backgroundColor: "#654DF7 !important",
      borderColor: "#654DF7 !important",
    },
  },
  cancelButton: {
    backgroundColor: "white !important",
    color: "#654DF7 !important",
    border: "1px solid #654DF7 !important",
    textTransform: "none !important",
    marginRight: theme.spacing(1.25),
    "&:hover": {
      backgroundColor: "white !important",
      borderColor: "#654DF7 !important",
    },
  },
  customInputStyle:{
    padding: "5px 20px",
    fontSize: "12px",
    lineHeight: "18px",
    letterSpacing: "normal",
    fontFamily: `"Lexend Deca"`,
  },
  testCase: {
    overflow: "auto !important",
    maxHeight: "calc(100vh - 330px) !important",
    margin: "0 auto !important",
  },
  errorText: {
   color:'red !important',
   fontSize:'10px !important'
  },
  testCaseStyle: {
    paddingBottom: "5px", paddingTop:"10px"
  },
  emailRecipient: {
    paddingLeft: "12px !important", 
  },
}));
export default useStyle;

export const useTableStyles = makeStyles((theme) => ({
    tableRow: {
      cursor: "pointer",
      "&:hover": {
        border: "2px solid #654DF7",
        backgroundColor: theme.palette.action.hover,
        cursor: "pointer",
      },
      "&.Mui-selected, &.Mui-selected:hover": {
        backgroundColor: theme.palette.action.selected,
      },
      height: "40px",
    },
    firstColumn: {
      borderLeft: "1px solid rgb(217, 217, 217)",
    },
    lastColumn: {
      borderRight: "1px solid rgb(217, 217, 217)",
    },
  }));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(242, 242, 242)",
    color: "#5c5c5c",
    padding: "0px 20px",
    fontFamily: "Lexend Deca",
    fontSize: "12px",
    borderTop: "1px solid rgb(217, 217, 217)",
    lineHeight: "12px",
  },
  [`&.${tableCellClasses.body}`]: {
    padding: "0px 20px",
    fontSize: "12px",
    lineHeight: "12px",
    letterSpacing: "normal",
    fontFamily: `"Lexend Deca"`,
  },
}));

export const StyledTypography_ = styled(Typography)(({ theme }) => ({
  fontFamily: "Lexend Deca",
  fontSize: "10px",
  color: "red",
}));
